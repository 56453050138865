import axios, { AxiosInstance } from "axios";

import { DefaultResponseI } from "@/api/glencoco";
import { TrainingModuleI } from "user/lib/api/routes/training";

export interface GetTrainingModulesResponseI extends DefaultResponseI {
  training_modules: Array<TrainingModuleI>;
}

export interface GetTrainingModuleResponseI extends DefaultResponseI {
  training_module: TrainingModuleI;
  training_module_presigned_url?: string;
  training_module_ai_generated_markdown_url?: string;
}

export interface GetTrainingModuleUploadURLResponseI {
  markdown_presigned_upload_url: string;
  training_module_presigned_upload_url: string;
}

export const TrainingAPIEndpoints = (api: AxiosInstance) => ({
  getTrainingModules: (campaignId: string) =>
    api.get<GetTrainingModulesResponseI>(
      `/v1/campaign/${campaignId}/training_modules`
    ),

  getTrainingModule: (campaignId: string, trainingModuleId: string) =>
    api.get<GetTrainingModuleResponseI>(
      `/v1/campaign/${campaignId}/training_module/${trainingModuleId}`
    ),

  submitTrainingModuleCompleted: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post(
      `v1/campaign/${campaignId}/training_module/${trainingModuleId}/complete`
    ),

  getAITrainingModuleUploadURL: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post<GetTrainingModuleUploadURLResponseI>(
      `/v1/campaign/${campaignId}/training_modules/${trainingModuleId}/submit_new_block_notes_url`
    ),

  uploadTrainingModuleContent: (preSignedUploadURL: string, document: Blob) =>
    axios.put(preSignedUploadURL, document, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
});
