import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";
import { ERROR_CODES } from "@/constants/errors";
import Router from "@/helpers/router";
import { checkIfClient } from "shared/lib/helpers";
import { useGlobalContext } from "@/hooks/use-global-context";
import { LoadingModule } from "@/components/modules/loading";

/**
 * Use in Axios error interceptors but logic should be stored
 * in context relevant environment
 *
 * @param e
 */
export const redirectOnAxiosErrorInterceptor = (
  e: AxiosError<any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  // Profile blocked
  if (
    e?.response?.data?.error_code === ERROR_CODES.PROFILE_BLOCKED &&
    !window.location.href.includes(Router.profileInactive())
  ) {
    window.location.href = Router.profileInactive();
  }

  // Unauthorized
  // if (
  //   e?.response?.data?.error_code === ERROR_CODES.UNAUTHORIZED &&
  //   !window.location.href.includes(Router.unauthorized())
  // ) {
  //   window.location.href = Router.unauthorized();
  // }
};

export const RedirectsProvider = ({ children }: { children?: any }) => {
  const { user: auth0User } = useAuth0();
  const { glencocoUser, isUserLoaded, userOnboardingStatus } =
    useGlobalContext();

  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
    if (checkIfClient()) {
      const url = new URL(window.location.href);

      //User is not Verified
      if (
        !auth0User?.email_verified &&
        !url.pathname.includes(Router.verify())
      ) {
        setIsRedirecting(true);

        const search = window.location.search
          ? `${window.location.search}`
          : "";

        window.location.href = `${Router.verify()}${search}`;
        return;
      }

      // If isUserLoaded is true and glencocoUser is undefined, this means the user is blocked from the platform.
      // Refer to /lib/api/glencoco.ts response interceptors to see how blocked users are handled.

      if (isUserLoaded && glencocoUser) {
        if (
          !userOnboardingStatus?.is_terms_accepted &&
          [Router.signup(), Router.verify()].every(
            (route) => !url.pathname.includes(route)
          )
        ) {
          setIsRedirecting(true);
          window.location.href = Router.signup();
          return;
        }

        //User is registered but trying to access signup page
        if (
          userOnboardingStatus?.is_terms_accepted &&
          url.pathname.includes(Router.signup())
        ) {
          setIsRedirecting(true);
          window.location.href = Router.home();
          return;
        }

        setIsRedirecting(false);
      }
    }
  }, [auth0User?.email_verified, isUserLoaded, userOnboardingStatus]);

  return isRedirecting ? <LoadingModule /> : children;
};
