import { FC, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { clsxMerge } from "shared/lib/helpers";
import { PrimaryNavigationItemI } from "@/components/primary-navigation/v3/interfaces";
import { NotificationLabel } from "@/components/primary-navigation/shared/notification-label";

interface NavDropdownMenuItemPropsI {
  subItem: PrimaryNavigationItemI;
  disabled?: boolean;
}

const COMMON_CLASSNAME = clsxMerge(
  "flex items-center justify-between gap-3",
  "brand-typography-body6 text-nowrap text-black",
  "my-0.5 w-full rounded-md p-2",
  "hover:bg-black/10"
);

export const NavDropdownMenuItem: FC<NavDropdownMenuItemPropsI> = ({
  subItem,
  disabled,
}) => {
  const router = useRouter();

  const itemContent = useMemo(
    () => (
      <>
        <span className="flex items-center gap-3">
          <span className="w-4">{subItem.icon}</span>
          <span>{subItem.text}</span>
        </span>

        <NotificationLabel id={subItem.id} />
      </>
    ),
    [subItem]
  );
  if ("handler" in subItem) {
    return (
      <button
        onClick={subItem.handler}
        className={clsxMerge(
          COMMON_CLASSNAME,
          (subItem.disabled || disabled) && "pointer-events-none"
        )}
      >
        {itemContent}
      </button>
    );
  } else if ("href" in subItem) {
    return (
      <Link
        href={subItem.href as string}
        className={clsxMerge(COMMON_CLASSNAME, {
          "bg-black text-white hover:bg-black": router.route === subItem.href,
          "pointer-events-none": subItem.disabled || disabled,
        })}
      >
        {itemContent}
      </Link>
    );
  }

  return null;
};
