import { FC } from "react";

import SidebarV2 from "shared/ui/sidebarV2";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import {
  CreateOrUpdateListForm,
  CreateOrUpdateListFormI,
} from "./create-list-form";
import {
  useCreateList,
  useUpdateList,
} from "@/modules/pipeline/lists/list/queries/mutations";

interface CreateListSidebarPropsI {
  isOpen: boolean;
  onClose?: () => void;
}

interface UpdateListSidebarPropsI extends CreateListSidebarPropsI {
  listId: string;
  name: string;
  description: string;
}

export const CreateListSidebar: FC<CreateListSidebarPropsI> = ({
  isOpen,
  onClose = () => {},
}) => {
  const { mutateAsync } = useCreateList();

  const submitFormData = async (data: CreateOrUpdateListFormI) => {
    await mutateAsync({
      name: data?.name,
      description: data?.description,
    });

    onClose();
  };

  return (
    <SidebarV2 isOpen={isOpen} onClose={onClose}>
      <SidebarSlide
        title="Create list"
        headerClassName="bg-white b-typography-h4 py-3"
      >
        <CreateOrUpdateListForm onSubmit={submitFormData} onCancel={onClose} />
      </SidebarSlide>
    </SidebarV2>
  );
};

export const UpdateListSidebar: FC<UpdateListSidebarPropsI> = ({
  isOpen,
  listId,
  name,
  description,
  onClose = () => {},
}) => {
  const { mutateAsync } = useUpdateList(listId);

  const submitFormData = async (data: CreateOrUpdateListFormI) => {
    await mutateAsync(data);

    onClose();
  };

  return (
    <SidebarV2 isOpen={isOpen} onClose={onClose}>
      <SidebarSlide
        title="Edit list"
        headerClassName="bg-white b-typography-h4 py-3"
      >
        <CreateOrUpdateListForm
          name={name}
          description={description}
          onSubmit={submitFormData}
          onCancel={onClose}
        />
      </SidebarSlide>
    </SidebarV2>
  );
};
