import { useRef } from "react";
import {
  ShowMore,
  ShowMoreRef,
  ShowMoreToggleLinesFn,
} from "@re-dev/react-truncate";

import { SearchContactsApiI } from "@/modules/pipeline/lists/list/queries/queries";
import { LeadListsWorkspaceHeaderPropsI } from "@/modules/pipeline/lists/list/workspace/header/interface";
import { clsxMerge } from "shared/lib/helpers";
import { setListTableHeaderOffsetTop } from "@/modules/pipeline/lists/list/workspace/table/utils";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { checkIfSystemList } from "@/modules/pipeline/lists/list/utils";

const getSearchResultsState = (searchApi: SearchContactsApiI) => {
  if (searchApi.isFetching) {
    return "Loading...";
  }

  const counter = searchApi.data?.pages
    ? searchApi.data.pages.reduce((counter, currentPage) => {
        return counter + currentPage.data.contacts.length;
      }, 0)
    : 0;

  return `Search results - ${counter}`;
};

export const LeadListsWorkspaceHeaderMainInfo = ({
  className,
  isNurtureMode,
}: LeadListsWorkspaceHeaderPropsI & { className?: string }) => {
  const {
    activeList,
    listDetails,
    isSearchMode,
    searchContactsAPI: searchApi,
  } = useListsContext();

  const description = listDetails?.description;

  const ref = useRef<ShowMoreRef>(null);

  // Custom buttons can be expanded and collapsed through this method.
  // This also triggers reposition of the list table header items.
  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    ref.current?.toggleLines(e);
    setListTableHeaderOffsetTop();
  };

  const title = isNurtureMode ? "Nurture leads" : "Cold Leads";

  const isShowTitle = !checkIfSystemList(activeList);

  return (
    <div className={clsxMerge("flex w-full flex-col", className)}>
      {isSearchMode ? (
        <h2 className="brand-typography-h5-bold pb-2">
          {searchApi ? getSearchResultsState(searchApi) : "Search results"}
        </h2>
      ) : (
        <>
          {isShowTitle && (
            <div
              title={title}
              className={clsxMerge(
                "line-clamp-2 max-w-[600px] typography-header-8-bold",
                isNurtureMode ? "text-[#E43508]" : "text-[#3843D1]"
              )}
            >
              {title}
            </div>
          )}

          <div className="max-w-[600px] text-[#666] typography-body-4">
            <ShowMore
              ref={ref}
              lines={2}
              more={
                <button
                  onClick={toggleLines}
                  className="brand-typography-body2 ae-link ml-2"
                >
                  ... Read more
                </button>
              }
              less={
                <button
                  onClick={toggleLines}
                  className="brand-typography-body2 ae-link ml-2"
                >
                  Read less
                </button>
              }
            >
              {description?.split("\n").map((line, idx) => (
                <p key={idx} className="last:inline">
                  {line.replace("\\n\\n", "")}
                </p>
              ))}
            </ShowMore>
          </div>
        </>
      )}
    </div>
  );
};
