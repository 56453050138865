import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const DEVICE_TYPES = {
  AUDIO_INPUT: "audioinput",
  AUDIO_OUTPUT: "audiooutput",
} as const;

/**
 * NOTE
 *
 * As a return values we mirror Twilio approach to audio devices
 *
 */
export const useAudioDevices = () => {
  const [inputDevices, setInputDevices] =
    useState<[string, MediaDeviceInfo][]>();
  const [outputDevices, setOutputDevices] =
    useState<[string, MediaDeviceInfo][]>();

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        setInputDevices(
          devices
            .filter((d) => d.kind === DEVICE_TYPES.AUDIO_INPUT)
            .map((d) => [d.deviceId, d])
        );

        setOutputDevices(
          devices
            .filter((d) => d.kind === DEVICE_TYPES.AUDIO_OUTPUT)
            .map((d) => [d.deviceId, d])
        );
      })
      .catch((err) => {
        toast.error(`Failed to get audio devices`, err);
      });
  }, []);

  return { inputDevices, outputDevices };
};
