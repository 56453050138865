import { MouseEvent } from "react";

import { MAXED_DIALER_CLOSE_BUTTON_ID } from "@/widgets/maxed-dialer/buttons/close-button";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { clsxMerge } from "shared/lib/helpers";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

const ACCOUNT_DETAILS_LAYOUT_ID = "account-details-layout";

/**
 * NOTE
 * To adjust the min-max size of columns
 * here you update grid-cols-[fit-content(400px)_minmax(260px,354px)_minmax(312px,874px)]
 *
 * To adjust sidebar wrapper
 * @/components/shared/widgets/maxed-dialer/index.tsx
 * update max-w-[calc(400px+354px+874px)]
 *
 * To adjust smooth animations for resources
 * @/components/modules/pipeline/account-details/sidebar/components/resources/index.tsx
 * update max-w-[400px]
 *
 */
const LayoutRoot = ({ children }: { children?: any }) => {
  const handlerContainerClick = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as Element)?.id === ACCOUNT_DETAILS_LAYOUT_ID) {
      event.preventDefault();

      /**
       * Looks bad
       * Very fast
       * Very efficient
       * https://www.dafont.com/forum/attach/orig/4/1/417453.jpg
       */
      (
        window.document.querySelector(
          `#${MAXED_DIALER_CLOSE_BUTTON_ID}`
        ) as HTMLElement
      )?.click();
    }
  };

  return (
    <div
      id={ACCOUNT_DETAILS_LAYOUT_ID}
      onClick={handlerContainerClick}
      className={clsxMerge(
        "h-full",
        "grid grid-cols-[fit-content(400px)_minmax(572px,1228px)] place-content-end",
        "[transition:grid-template_300ms]"
      )}
    >
      {children}
    </div>
  );
};
const LayoutSidebarExtension = ({ children }: { children?: any }) => (
  <div className="sticky top-0 h-screen overflow-y-auto">{children}</div>
);

const LayoutGridRows = ({ children }: { children?: any }) => (
  <div className="h-auto">{children}</div>
);
const LayoutGridColumns = ({ children }: { children?: any }) => (
  <div
    className={clsxMerge(
      "grid grid-cols-[minmax(260px,354px)_minmax(312px,874px)] ",
      "[transition:grid-template_300ms]"
    )}
  >
    {children}
  </div>
);

const LayoutTop = ({ children }: { children?: any }) => {
  return <div className={clsxMerge("z-10")}>{children}</div>;
};

const LayoutSidebar = ({ children }: { children?: any }) => {
  const { callStatus } = useAccountDetailsContext();

  return (
    <div className="bg-white">
      <div
        className={clsxMerge("sticky top-0 overflow-y-auto overflow-x-hidden", {
          "h-[calc(100vh-200px)]": callStatus !== DIALER_CALL_STATUS.BEFORE,
          "h-screen": callStatus === DIALER_CALL_STATUS.BEFORE,
        })}
      >
        {children}
      </div>
    </div>
  );
};

const LayoutPrimaryContent = ({ children }: { children?: any }) => {
  const { callStatus } = useAccountDetailsContext();

  return (
    <div
      className={clsxMerge("sticky top-0 overflow-y-auto bg-[#F2F2F2]", {
        "h-[calc(100vh-200px)]": callStatus !== DIALER_CALL_STATUS.BEFORE,
        "h-screen": callStatus === DIALER_CALL_STATUS.BEFORE,
      })}
    >
      {children}
    </div>
  );
};

export const AccountDetailsLayout = LayoutRoot as typeof LayoutRoot & {
  SidebarExtension: typeof LayoutSidebarExtension;
  GridRows: typeof LayoutGridRows;
  GridColumns: typeof LayoutGridColumns;
  Top: typeof LayoutTop;
  Sidebar: typeof LayoutSidebar;
  Content: typeof LayoutPrimaryContent;
};

AccountDetailsLayout.Sidebar = LayoutSidebar;
AccountDetailsLayout.SidebarExtension = LayoutSidebarExtension;
AccountDetailsLayout.GridRows = LayoutGridRows;
AccountDetailsLayout.GridColumns = LayoutGridColumns;
AccountDetailsLayout.Top = LayoutTop;
AccountDetailsLayout.Content = LayoutPrimaryContent;
