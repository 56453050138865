import {
  DIALER_LIST_DAILING_STATUS,
  DIALER_LIST_DAILING_STATUS_MAP,
} from "@/constants/dialer";
import { ValueOf } from "next/constants";
import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface DialingContactStatusPillI {
  status: ValueOf<typeof DIALER_LIST_DAILING_STATUS>;
  className?: string;
  extension?: any;
}

/**
 * Default CSS properties for the pill component.
 */
const DEFAULT_PILL_CSS_PROPERTIES = {
  color: "text-black",
  bg: "bg-[#ECECEC]",
};

/**
 * CSS properties for different dialing statuses.
 */
export const DIALER_LIST_DAILING_STATUS_PILL_CSS_PROPERTIES = {
  [DIALER_LIST_DAILING_STATUS.CONNECTED as string]: {
    color: "text-black/80",
    bg: "bg-[#6FDB7A]",
  },
  [DIALER_LIST_DAILING_STATUS.DIALING]: {
    color: "text-black/80",
    bg: "bg-[#F3AD25]",
  },
};

const DialingContactStatusPillToMemozie: FC<DialingContactStatusPillI> = ({
  status,
  className,
  extension,
}) => {
  return (
    <div
      className={clsxMerge(
        "ae-typography-detail1 w-fit rounded px-2 py-1 text-center",

        DIALER_LIST_DAILING_STATUS_PILL_CSS_PROPERTIES[status]?.bg ||
          DEFAULT_PILL_CSS_PROPERTIES.bg,
        DIALER_LIST_DAILING_STATUS_PILL_CSS_PROPERTIES[status]?.color ||
          DEFAULT_PILL_CSS_PROPERTIES.color,

        className
      )}
    >
      {(DIALER_LIST_DAILING_STATUS_MAP as any)[status as string] || "Unknown"}
      {extension ? extension : null}
    </div>
  );
};

/**
 * Functional component to display the status pill for dialing contacts.
 *
 * @param {DialingContactStatusPillI} props - The properties for the component.
 * @returns {JSX.Element | null} - The rendered component.
 */
export const DialingContactStatusPill = DialingContactStatusPillToMemozie;
