import { AccountUserListTypeI } from "shared/lib/interfaces/account";

export const INFO_MODAL_DIALING_LIST_COMPLETE_ID =
  "info_modal_dialing_list_complete";

/**
 * System lists are the lists that are created by the system and are not editable
 * by a user
 */
export const SYSTEM_LIST_TYPES = {
  CALLBACKS: "callbacks",
  MISSED_CALLS: "missed_calls",
  BOOKED_MEETINGS: "booked_meetings",
  EMAIL_ACTIONS: "email_actions",
  FEEDBACK_RECEIVED: "feedback_received",
  NO_SOWS_AND_CANCELS: "no_show_and_cancels",
} as const;

/**
 * This map covers all supported list types, including custom (created by user)
 * exclusive lists (they are emitted for top performing callers) and system lists
 */
export const LIST_TYPES = {
  CUSTOM: "custom",
  EXCLUSIVE: "top_caller_dedicated",
  TODO_LIST: "todo_list",
  ...SYSTEM_LIST_TYPES,
} as const;

/**
 * NOTE: User is disallowed to "remove from" these lists
 */
export const LISTS_WITH_PROTECTED_LEADS: AccountUserListTypeI[] = [
  SYSTEM_LIST_TYPES.BOOKED_MEETINGS,
];

export const SYSTEM_LIST_TITLES = {
  [SYSTEM_LIST_TYPES.BOOKED_MEETINGS]: "Booked Meetings",
  [SYSTEM_LIST_TYPES.CALLBACKS]: "Callbacks",
  [SYSTEM_LIST_TYPES.EMAIL_ACTIONS]: "Email Actions",
  [SYSTEM_LIST_TYPES.FEEDBACK_RECEIVED]: "Feedback Received",
  [SYSTEM_LIST_TYPES.MISSED_CALLS]: "Missed Calls",
};

export const LIST_TABLE_COLUMNS = {
  NAME: "contact_name",
  TITLE: "contact_title",
  NUMBER_OF_DIALS: "number_of_dials",
  ACCOUNT: "account_name",
  STATE: "account_state",
  STATUS: "status",
  LAST_CALLED: "last_called",
  NEXT_TOUCH_TIME: "callback_time",
  DIALS: "number_of_calls",
  LAST_DIALED: "last_called",
  LAST_CONVERSATION: "last_conversation",
  TIMEZONE: "timezone",
  ACCOUNT_CITY: "account_city",
  HEADCOUNT: "headcount",
  REVENUE: "revenue",
  NUMBER_OF_CALLS: "number_of_calls",
  NUMBER_OF_CONVERSATIONS: "number_of_conversations",
  CALLBACK_TIME: "callback_time",
  MEETING_TIME: "meeting_time",
} as const;

export const LIST_SUBSECTIONS_MAP = {
  NURTURE: "nurture_leads",
  COLD_CALL: "cold_leads",
} as const;

export const LIST_SUBSECTIONS_LABEL_MAP = {
  [LIST_SUBSECTIONS_MAP.NURTURE]: "Nurture",
  [LIST_SUBSECTIONS_MAP.COLD_CALL]: "Cold",
} as const;

export const LIST_SUBSECTION_DESCRIPTIONS_MAP = {
  [LIST_SUBSECTIONS_MAP.NURTURE]: "Being worked, touch points made",
  [LIST_SUBSECTIONS_MAP.COLD_CALL]: "No conversations yet",
} as const;

/**
 * System lists, which are considered nurture lists (former `Callbacks` and
 * `Missed Calls`).
 */
export const SYSTEM_NURTURE_LIST_TYPES = [
  LIST_TYPES.CALLBACKS,
  LIST_TYPES.MISSED_CALLS,
];

export const NURTURE_LIST_TYPES = [
  ...SYSTEM_NURTURE_LIST_TYPES,
  LIST_TYPES.TODO_LIST,
  LIST_TYPES.CUSTOM,
  LIST_TYPES.EXCLUSIVE,
];
