import {
  DATE_TIME_CALENDAR_MODAL_ID,
  DateTimeCalendarModal,
} from "@/modals/date-time-calendar-modal";
import { useState } from "react";
import { Portal } from "react-portal";
import { DATE_FORMAT_MONTH_DAY_YEAR_TIME_ZONE } from "shared/lib/constants/time";
import { convertTime12to24, DayJs } from "shared/lib/helpers/date";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { TextField } from "shared/ui";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { CalendarOutlineIcon } from "shared/ui/icons";
import { Field } from "formik";

const EnhancedTextField = TextField(Field);

const DISPO_SECTION_BOOK_MEETING_DATE_TIME_CALENDAR_MODAL_ID = `dispo-section-book-meeting-${DATE_TIME_CALENDAR_MODAL_ID}`;

/**
 *
 * @param props
 * @param props.name - formik field name
 */
export const DateTimeInput = ({
  name,
  errors,
  touched,
  onChange,
}: {
  name?: string;
  errors?: string;
  touched?: boolean;
  onChange?: (time?: string) => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<string>();

  const handleOnClick = () => {
    modalHelpers.open(DISPO_SECTION_BOOK_MEETING_DATE_TIME_CALENDAR_MODAL_ID);
  };

  const handleSelectDateTime = (
    date: string,
    time: string,
    timezone: string
  ) => {
    const { hours, minutes } = convertTime12to24(time);
    const dateTime = DayJs(date)
      .hour(hours)
      .minute(minutes)
      .tz(TIMEZONES.find((tz) => tz.fullZoneName === timezone)?.tzCode, true);

    setSelectedDate(dateTime.format(DATE_FORMAT_MONTH_DAY_YEAR_TIME_ZONE));
    onChange?.(dateTime.toISOString());

    modalHelpers.close(DISPO_SECTION_BOOK_MEETING_DATE_TIME_CALENDAR_MODAL_ID);
  };

  const handleOnClose = () => {};

  return (
    <>
      <div className="relative">
        <EnhancedTextField
          label="Meeting date & time"
          name={name}
          errors={errors}
          touched={touched}
          placeholder="Set date & time"
          className="relative mb-0 w-[250px] animate-fadein"
          labelContentClassName="brand-typography-body2"
          inputClassName="text-black typography-body-4 placeholder:text-black cursor-pointer"
          inputProps={{
            readOnly: true,
            onClick: handleOnClick,
            value: selectedDate,
          }}
        />
        <CalendarOutlineIcon className="absolute right-3 top-12 w-4" />
      </div>

      <Portal>
        <DateTimeCalendarModal
          modalId={DISPO_SECTION_BOOK_MEETING_DATE_TIME_CALENDAR_MODAL_ID}
          title="Set Date & Time"
          descritpion="Specify date and time when to schedule your meeting"
          onSuccess={handleSelectDateTime}
          onClose={handleOnClose}
        />
      </Portal>
    </>
  );
};
