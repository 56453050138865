import { useEffect, useMemo, useState } from "react";
import { VisibilityState } from "@tanstack/react-table";

import {
  selectListType,
  selectorListsTableColumns,
} from "@/modules/pipeline/lists/list/workspace/table/column-cell-definition/selectors";
import { GetListsRequestFilterParamsI, ListPillI } from "@/api/routes/list";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { setListTableHeaderOffsetTop } from "@/modules/pipeline/lists/list/workspace/table/utils";
import { LIST_TABLE_COLUMNS } from "@/modules/pipeline/lists/list/constants";
import { useLocalStorage } from "@/context/local-storage";
import { COLUMNS_VISIBLE_BY_DEFAULT } from "@/modules/pipeline/lists/list/workspace/table/constants";

interface UseDataTableColumnsParamsI {
  activeList: ListPillI | undefined;
  isSearchMode: boolean;
  isNurtureList: boolean | null;
  filters: GetListsRequestFilterParamsI | undefined;
}

export const useDataTableColumns = ({
  activeList,
  isSearchMode,
  isNurtureList,
  filters = {},
}: UseDataTableColumnsParamsI) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const localStorage = useLocalStorage();

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const columns = useMemo(
    () =>
      selectorListsTableColumns(
        selectListType({
          list: activeList,
          isSearchMode,
          isDialing: isGlobalDialer,
          isNurtureList,
          listSubsection: filters?.list_subsection,
        }),
        {
          list: activeList,
        }
      ),
    [
      activeList,
      isSearchMode,
      isGlobalDialer,
      isNurtureList,
      filters?.list_subsection,
    ]
  );

  const updateSavedColumnsVisibility = (
    newVisibilityMap: Record<string, boolean>
  ) => {
    // store visibility data of all possible table columns
    localStorage.listsTableColumnsVisibility = Object.values(
      LIST_TABLE_COLUMNS
    )?.reduce((map, colId) => {
      // If the column is not in the newVisibilityMap, just set it to true
      // This is to ensure that the column is visible if it is not explicitly set to hidden
      colId in newVisibilityMap
        ? (map[colId] = newVisibilityMap[colId])
        : (map[colId] = true);

      return map;
    }, {} as Record<string, boolean>);
  };

  // Set the columns visibility based on local storage value when the user first loads the page
  useEffect(() => {
    const savedColumnsVisibility =
      localStorage.listsTableColumnsVisibility as Record<string, boolean>;

    if (savedColumnsVisibility) {
      setColumnVisibility(savedColumnsVisibility);
    } else {
      const defaultData = COLUMNS_VISIBLE_BY_DEFAULT.reduce(
        (defaultVisibilityMap, colId) => {
          defaultVisibilityMap[colId] = COLUMNS_VISIBLE_BY_DEFAULT.includes(
            colId as any
          );

          return defaultVisibilityMap;
        },
        {} as Record<string, boolean>
      );

      setColumnVisibility(defaultData);
    }
  }, []);

  // Update the local storage value when the user changes the columns visibility
  useEffect(() => {
    updateSavedColumnsVisibility(columnVisibility);
    setListTableHeaderOffsetTop();
  }, [columnVisibility]);

  return {
    columns,
    columnVisibility,
    setColumnVisibility,
  };
};
