import { clsxMerge } from "shared/lib/helpers";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { LeadLists } from "@/modules/pipeline/lists/list";
import { LeadsPageHeader } from "@/modules/pipeline/shared/leads-page-header";
import { ListsContextProvider } from "./context";
import { useListTourContext } from "@/modules/tours/list/context";

const TakeListsTourButton = () => {
  const { openTour, isTourOpen, closeTour } = useListTourContext();

  return (
    <button
      className="btn-ae-default app-gradient w-[116px]"
      onClick={isTourOpen ? closeTour : openTour}
    >
      {isTourOpen ? "Exit tour" : "Take tour"}
    </button>
  );
};

export const ListsModule = () => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();

  return (
    <ListsContextProvider>
      <LeadsPageHeader className="sticky left-0 z-20 mb-4 w-full max-w-full px-6">
        <div
          className={clsxMerge(
            "relative flex gap-2",
            "transition-opacity duration-300",
            isGlobalDialer && "pointer-events-none opacity-50"
          )}
        />

        <TakeListsTourButton />
      </LeadsPageHeader>

      <LeadLists className="sticky left-0 mt-4 overflow-auto" />
    </ListsContextProvider>
  );
};
