import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

import { PerformanceFilterI } from "@/modules/performance/dashboard/interfaces";
import { preparePerformanceFiltersForAPICall } from "@/api/routes/performance/utils";
import { useApiClient } from "@/context/api-client";

const PERFORMANCE_DASHBOARD_QUERY_KEY = "PERFORMANCE_DASHBOARD_QUERY_KEY";

export const useFetchPerformanceDashboardMetrics = (
  filters: PerformanceFilterI | undefined,
  campaignId: string | undefined
) => {
  const api = useApiClient();

  const performanceDashboardApi = useQuery({
    enabled: !!filters && !!campaignId && !!filters.timePeriod,
    queryKey: [PERFORMANCE_DASHBOARD_QUERY_KEY, filters],
    queryFn: () => {
      const { startDate, endDate } = preparePerformanceFiltersForAPICall(
        // Because of the enabled prop, we can safely assert the filters as
        // PerformanceFilterI
        filters as PerformanceFilterI
      );

      return api.fetchPerformanceDashboard(
        campaignId as string,
        startDate,
        endDate
      );
    },
  });

  useEffect(() => {
    if (performanceDashboardApi.isError) {
      toast.error(
        "There was an error fetching your performance data. Please, reload the page."
      );
    }
  }, [performanceDashboardApi.isError]);

  return performanceDashboardApi;
};

const PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY =
  "PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY";

export const useFetchPerformanceCampaignStats = (
  campaignId: string | undefined
) => {
  const api = useApiClient();

  const performanceCampaignStatsApi = useQuery({
    enabled: !!campaignId,
    queryKey: [PERFORMANCE_CAMPAIGN_STATS_QUERY_KEY, campaignId],
    queryFn: () => api.fetchPerformanceCampaignStats(campaignId as string),
  });

  useEffect(() => {
    if (performanceCampaignStatsApi.isError) {
      toast.error(
        "Failed to fetch performance stats by campaign. Please try to reload the page."
      );
    }
  }, [performanceCampaignStatsApi.isError]);

  return performanceCampaignStatsApi;
};

const TODAY_PERFORMANCE_QUERY_KEY = "today_performance";

export const useFetchTodayPerformance = () => {
  const api = useApiClient();

  const todayPerformanceApi = useQuery({
    queryKey: [TODAY_PERFORMANCE_QUERY_KEY],
    queryFn: api.fetchTodayPerformance,
  });

  useEffect(() => {
    if (todayPerformanceApi.isError) {
      toast.error(
        "Failed to fetch today's performance data. Please try to re-open the widget or reload the page."
      );
    }
  }, [todayPerformanceApi.isError]);

  return todayPerformanceApi;
};

export const GOAL_TRACKER_PROGRESS_QUERY_KEY = "goal_tracker_progress";

export const useFetchGoalTrackerMetrics = () => {
  const api = useApiClient();

  const goalTrackerProgressApi = useQuery({
    queryKey: [GOAL_TRACKER_PROGRESS_QUERY_KEY],
    queryFn: api.fetchGoalTrackerMetrics,
  });

  useEffect(() => {
    if (goalTrackerProgressApi.isError) {
      toast.error(
        "Failed to fetch goal tracker metrics data. Please try to reload the page."
      );
    }
  }, [goalTrackerProgressApi.isError]);

  return goalTrackerProgressApi;
};
