import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { FC } from "react";

export const CONFIRM_DELETE_LIST = "confirm-delete-list";

interface ConfirmDeleteListPropsI {
  listTitle: string;
  onConfirm: () => void;
}

export const ConfirmDeleteList: FC<ConfirmDeleteListPropsI> = ({
  listTitle,
  onConfirm,
}) => (
  <ConfirmActionModal
    id={CONFIRM_DELETE_LIST}
    showCloseButton={false}
    title={`Are you sure you want to delete your ${listTitle} list?`}
    description="Once deleted, the list and all unique data pertaining to this list will be removed completely. This action can not be undone."
    confirmButtonText="I understand. Delete it."
    onConfirm={onConfirm}
  />
);
