import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MicrophoneIcon,
  PhoneArrowUpRightIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/outline";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { SelectField } from "shared/ui/ae-user-input";
import { useAudioDevices } from "@/hooks/use-audtio-devices";
import { useMicrophone } from "@/hooks/use-microphone";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useSpeakers } from "@/hooks/use-speakers";

interface CallSettingSectionPropsI
  extends PropsWithChildren,
    PropsWithClassNameI {
  label: string;
  icon: ReactNode;
}

const CallSettingSection: FC<CallSettingSectionPropsI> = ({
  label,
  icon,
  className,
  children,
}) => (
  <div className={clsxMerge("flex gap-2 py-2", className)}>
    <div className="flex w-[100px] items-center gap-2">
      {icon}
      <h6 className="typography-body-4-medium">{label}</h6>
    </div>

    {children}
  </div>
);

const MIN_RATE_OPTION = 1;
const MAX_RATE_OPTION = 3;

const DIAL_RATE_OPTIONS: string[] = new Array(MAX_RATE_OPTION)
  .fill(0)
  .map((_, i) => `${i + 1}`);

const isDialRateValid = (rateOption?: number) => {
  if (typeof rateOption !== "number") return false;

  return rateOption >= MIN_RATE_OPTION && rateOption <= MAX_RATE_OPTION;
};

export const DialRateSection = () => {
  const { maxLeadsDialing, setMaxLeadsDialing } = useDialerGlobalContext();

  const [dialRate, setDialRate] = useState(
    maxLeadsDialing?.toString() as string
  );

  const onDialRateChange = (value: string) => {
    setMaxLeadsDialing?.(parseInt(value[0]));
  };

  useEffect(() => {
    setDialRate(maxLeadsDialing?.toString() as string);

    if (!isDialRateValid(maxLeadsDialing)) {
      setMaxLeadsDialing?.(MIN_RATE_OPTION);
    }
  }, [maxLeadsDialing]);

  return (
    <CallSettingSection
      label="Dial Rate"
      icon={<PhoneArrowUpRightIcon className="h-4 w-4 text-black" />}
    >
      <div className="flex grow justify-end gap-2 pr-4">
        {DIAL_RATE_OPTIONS.map((rate) => (
          <label
            key={rate}
            className="flex items-center gap-2"
            onClick={() => onDialRateChange(rate)}
          >
            <input
              type="radio"
              className="radio radio-sm"
              checked={dialRate === rate}
            />
            <span className="text-black typography-body-4-medium">{rate}</span>
          </label>
        ))}
      </div>
    </CallSettingSection>
  );
};

const NO_DEVICE_LABEL = "Device name is unavailable";

export const MicrophoneSection = () => {
  const callingContext = useCallingContext();
  const audioDevices = useAudioDevices();

  const inputDevices = useMemo(
    () => callingContext?.inputDevices || audioDevices?.inputDevices,
    [audioDevices?.inputDevices, callingContext?.inputDevices]
  );

  const { selectedMicrophone, microphoneLabels, handleMicrophoneChange } =
    useMicrophone(inputDevices, callingContext?.device);

  const microphoneOptions = useMemo(
    () => microphoneLabels?.map((label) => label || NO_DEVICE_LABEL),
    [microphoneLabels]
  );

  return (
    <CallSettingSection
      label="Microphone"
      icon={<MicrophoneIcon className="h-4 w-4 text-black" />}
      className="mt-1"
    >
      <SelectField
        className="grow rounded-lg bg-white"
        selectedValue={selectedMicrophone?.label || NO_DEVICE_LABEL}
        options={microphoneOptions}
        onChange={handleMicrophoneChange}
        variation="small"
      />
    </CallSettingSection>
  );
};

export const SpeakerSection = () => {
  const callingContext = useCallingContext();
  //Use Audio devices only if Twilio device is not set
  const audioDevices = useAudioDevices();

  const outputDevices = useMemo(
    () => callingContext?.outputDevices || audioDevices?.outputDevices,
    [audioDevices?.outputDevices, callingContext?.outputDevices]
  );

  const { selectedSpeaker, speakerLabels, handleSpeakerChange } = useSpeakers(
    outputDevices,
    callingContext?.device
  );

  const speakerOptions = useMemo(
    () => speakerLabels?.map((label) => label || NO_DEVICE_LABEL),
    [speakerLabels]
  );

  return (
    <CallSettingSection
      label="Speakers"
      icon={<SpeakerWaveIcon className="h-4 w-4 text-black" />}
    >
      <SelectField
        className="grow rounded-lg bg-white"
        selectedValue={selectedSpeaker?.label || NO_DEVICE_LABEL}
        options={speakerOptions}
        onChange={handleSpeakerChange}
        variation="small"
      />
    </CallSettingSection>
  );
};
