import { FC } from "react";
import dayjs from "dayjs";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import { LabelValue } from "@/modules/pipeline/account-details/components/sidebar/components/label-value";
import { clsxMerge } from "shared/lib/helpers";
import { DISQUALIFY_DISPOSITION_LABELS } from "@/modules/pipeline/account-details";
import { SetNextTouchTimeDropdown } from "./next-touch-time-dropdown";
import { CampaignI } from "@/interfaces/campaign";
import { AccountDetailsStatusI, AccountI } from "shared/lib/interfaces/account";

interface AccountStatusDetailsPropsI {
  className?: string;
  accountStatus: AccountDetailsStatusI;
  campaign?: CampaignI;
  account?: AccountI;
}

const formatDateShort = (date: string | null | undefined) => {
  if (!date) {
    return "N/A";
  }

  return dayjs(date).format("M/D, hh:mm A");
};

const formatDateLong = (date: string | null | undefined) => {
  if (!date) {
    return "N/A";
  }

  return dayjs(date).format("MMM D, hh:mm A");
};

const {
  COLD_LEAD,
  NURTURE,
  MEETING_SCHEDULED,
  FEEDBACK_RECEIVED,
  DISQUALIFIED,
} = ACCOUNT_DISPOSITION_STATUSES;

export const AccountStatusDetails: FC<AccountStatusDetailsPropsI> = ({
  className,
  accountStatus,
  campaign,
  account,
}) => {
  const {
    status,
    primary_contact_name: prospectName,
    last_conversation_time: lastConversationTime,
    next_touch_time: nextTouchTime,
    ae_name: aeName,
    meeting_time: meetingTime,
    meeting_join_link: meetingJoinLink,
    original_meeting_time: originalMeetingTime,
    dq_reason: disqualificationReason,
  } = accountStatus || {};

  if (!status || status === COLD_LEAD) {
    return null;
  }

  return (
    <div className={clsxMerge("flex flex-col gap-4", className)}>
      {status !== DISQUALIFIED && (
        <LabelValue
          label="Prospect name"
          value={prospectName || "N/A"}
          valueClassName="typography-body-3-bold"
        />
      )}

      {status === NURTURE && (
        <div className="relative grid grid-cols-2">
          <LabelValue
            label="Last conversation time"
            value={formatDateShort(lastConversationTime)}
            valueClassName="typography-body-3-bold"
          />

          <LabelValue
            label="Next touch time"
            value={formatDateShort(nextTouchTime)}
            valueClassName="typography-body-3-bold"
          />

          <SetNextTouchTimeDropdown
            className="dropdown-end absolute -top-1 right-0"
            campaign={campaign}
            account={account}
          />
        </div>
      )}

      {status === MEETING_SCHEDULED && (
        <>
          <LabelValue
            label="Meeting Taker"
            value={aeName || "N/A"}
            valueClassName="typography-body-3-medium"
          />
          <LabelValue
            label="Meeting date"
            value={formatDateLong(meetingTime)}
            valueClassName="typography-body-3-medium"
          />

          {meetingJoinLink && (
            <a
              target="_blank"
              href={meetingJoinLink}
              rel="noreferrer"
              className={clsxMerge(
                "flex w-max items-center gap-2 rounded",
                "bg-[#E3EBFF] px-2 py-1 text-[#4474E3] typography-body-4-medium"
              )}
            >
              <CalendarDaysIcon className="h-4 w-4" />
              <span>Link to join meeting</span>
            </a>
          )}
        </>
      )}

      {status === FEEDBACK_RECEIVED && (
        <>
          <LabelValue
            label="Meeting Taker"
            value={aeName || "N/A"}
            valueClassName="typography-body-3-medium"
          />

          <div className="grid grid-cols-2">
            <LabelValue
              label="Meeting date"
              value={formatDateShort(meetingTime)}
              valueClassName="typography-body-3-medium"
            />

            {originalMeetingTime && (
              <LabelValue
                label="Original meeting date"
                labelClassName="text-[#666]"
                value={
                  <span className="text-[#666] line-through">
                    {formatDateShort(nextTouchTime)}
                  </span>
                }
              />
            )}
          </div>
        </>
      )}

      {status === DISQUALIFIED && disqualificationReason && (
        <LabelValue
          label="Reason"
          value={DISQUALIFY_DISPOSITION_LABELS[disqualificationReason]}
        />
      )}
    </div>
  );
};
