import { Field, FormikErrors } from "formik";

import {
  DISPOSITIONS_GLENX,
  DISPOSITIONS_GLENX_MAP,
} from "shared/lib/constants/dispositions";
import { clsxMerge } from "shared/lib/helpers";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { Dropdown } from "shared/ui";
import { DropdownOptionI } from "shared/ui/user-input/dropdown";
import { NextTouchTimeDropdown } from "./next-touch-time-dropdown";
import { DateTimeInput } from "./book-meeting-input/date-time-input";
import { DispositionMeetingTakerDropdown } from "./book-meeting-input/meeting-taker-dropdown";
import { FormValidationSchemaI } from "../interface";
import { SetStateAction } from "react";

export const isNextTouchTimeRequired = (
  disposition?: ValueOfObjectFields<typeof DISPOSITIONS_GLENX>
) =>
  ![
    DISPOSITIONS_GLENX.DO_NOT_CALL as string,
    DISPOSITIONS_GLENX.QC_NOT_MET_FOR_ACCOUNT,
    DISPOSITIONS_GLENX.OTHER_DO_NOT_FOLLOW_UP,
    DISPOSITIONS_GLENX.MEETING_BOOKED,
  ].includes(disposition as string);

const DISPOSITION_OPTIONS: DropdownOptionI[] = Object.values(
  DISPOSITIONS_GLENX
).map((dispoKey) => ({
  label: DISPOSITIONS_GLENX_MAP[dispoKey],
  value: dispoKey,
}));

const EnhancedDropdown = Dropdown(Field);

export const DispositionRow = ({
  names,
  disposition,
  errors,
  touched,
  className,
  customNextTouchTime,
  setValues,
}: {
  /**
   * Formik field names
   */
  names: {
    DISPOSITION?: string;
    NEXT_TOUCH_TIME?: string;
    MEETING_TIME?: string;
    MEETING_TAKER?: string;
  };
  disposition?: ValueOfObjectFields<typeof DISPOSITIONS_GLENX>;
  errors?: Record<string, string>;
  touched?: Record<string, boolean>;
  className?: string;
  customNextTouchTime?: string;
  setValues: (
    values: SetStateAction<FormValidationSchemaI>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValidationSchemaI>>;
}) => {
  const handleOnChangeMeetingTime = (time?: string) => {
    setValues((values) => ({
      ...values,
      [names.MEETING_TIME as string]: time,
    }));
  };

  const handleOnChangeMeetingTaker = (account_executive_id?: string) => {
    setValues((values) => ({
      ...values,
      [names.MEETING_TAKER as string]: account_executive_id,
    }));
  };

  return (
    <>
      <div
        className={clsxMerge(
          "flex w-full animate-fadein flex-wrap items-center gap-x-4",
          className,
          { "mb-4": DISPOSITIONS_GLENX.MEETING_BOOKED === disposition }
        )}
      >
        <EnhancedDropdown
          name={names.DISPOSITION}
          label="Disposition"
          placeholder="Select"
          options={DISPOSITION_OPTIONS}
          errors={errors?.[names.DISPOSITION as string]}
          touched={touched?.[names.DISPOSITION as string]}
          className="relative mb-0 w-full max-w-[240px] animate-fadein"
          labelContentClassName="brand-typography-body2"
          inputClassName="text-black"
          errorsClassName="absolute -bottom-[22px] whitespace-nowrap"
        />

        {isNextTouchTimeRequired(disposition) && (
          <NextTouchTimeDropdown
            name={names.NEXT_TOUCH_TIME as string}
            errors={errors?.[names.NEXT_TOUCH_TIME as string]}
            touched={touched?.[names.NEXT_TOUCH_TIME as string]}
            customNextTouchTime={customNextTouchTime}
          />
        )}

        {DISPOSITIONS_GLENX.MEETING_BOOKED === disposition && (
          <DateTimeInput
            name={names.MEETING_TIME}
            errors={errors?.[names.MEETING_TIME as string]}
            touched={touched?.[names.MEETING_TIME as string]}
            onChange={handleOnChangeMeetingTime}
          />
        )}
      </div>

      {DISPOSITIONS_GLENX.MEETING_BOOKED === disposition && (
        <div
          className={clsxMerge(
            "flex w-full animate-fadein flex-wrap items-center gap-x-4",
            className
          )}
        >
          <div className="relative mb-0 w-full max-w-[240px] animate-fadein typography-body-4">
            Who did you set the meeting for?
          </div>
          <DispositionMeetingTakerDropdown
            name={names.MEETING_TAKER}
            errors={errors?.[names.MEETING_TAKER as string]}
            touched={touched?.[names.MEETING_TAKER as string]}
            onChange={handleOnChangeMeetingTaker}
          />
        </div>
      )}
    </>
  );
};
