import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { Modal } from "shared/ui";
import { ModalPropsI } from "shared/ui/modal";

export const INFO_MODAL_ID = "info-modal";

type ConfirmActionModalPropsI = Pick<
  ModalPropsI,
  | "id"
  | "title"
  | "titleClassName"
  | "titleImage"
  | "description"
  | "descriptionClassName"
  | "onClose"
  | "unmountOnClose"
  | "showCloseButton"
> & {
  confirmButtonDisabled?: boolean;
  confirmButtonText?: string;
  onConfirm?: () => void;
};

export const InfoModal: FC<ConfirmActionModalPropsI> = ({
  id = INFO_MODAL_ID,
  confirmButtonDisabled,
  confirmButtonText,
  onConfirm,

  ...modalProps
}) => (
  <Modal
    {...modalProps}
    id={id}
    modalBoxClassName="w-[380px]"
    titleClassName={clsxMerge("text-center", modalProps.titleClassName)}
    descriptionClassName={clsxMerge(
      "text-center",
      modalProps.descriptionClassName
    )}
  >
    {({ handleClose }) => {
      const handleConfirm = () => {
        onConfirm?.();
        handleClose();
      };

      return (
        <div className="flex justify-center">
          <button
            className={clsxMerge("btn-ae-default min-w-[110px]", {
              disabled: confirmButtonDisabled,
            })}
            onClick={handleConfirm}
            disabled={confirmButtonDisabled}
          >
            {confirmButtonText || "Confirm"}
          </button>
        </div>
      );
    }}
  </Modal>
);
