import { FC } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";

import {
  ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME,
  AccountDetailsV2SidebarSection,
} from "@/modules/pipeline/account-details/components/sidebar/sections/section";
import { AccountDetailsV2ListItem } from "./list-item";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useAddUserToListSidebarContext } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";

interface ListsSectionPropsI {
  className?: string;
}

export const ListsSection: FC<ListsSectionPropsI> = ({ className }) => {
  const { accountDetailsApi, accountUserLists, account, campaign } =
    useAccountDetailsContext();
  const { onOpen: openAddUserToListSidebar } = useAddUserToListSidebarContext();

  return (
    <AccountDetailsV2SidebarSection
      title="Lists"
      className={className}
      actionsSection={
        <button
          className={ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME}
          onClick={openAddUserToListSidebar}
        >
          <PlusIcon className="h-4 w-4" />
          Add to list
        </button>
      }
    >
      <DataStatesWrapper
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[40px]" />}
      >
        <div className="mt-4 flex animate-fadein flex-wrap gap-2">
          {accountUserLists?.map((listItem) => (
            <AccountDetailsV2ListItem
              key={listItem.list_id}
              accountId={account?.id}
              campaignId={campaign?.id}
              listData={listItem}
              className="mr-2"
            />
          ))}
        </div>
      </DataStatesWrapper>
    </AccountDetailsV2SidebarSection>
  );
};
