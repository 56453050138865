import { FC, useMemo, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { useOnClickOutside } from "shared/lib/hooks/use-on-click-outside";
import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { ListPillI } from "@/api/routes/list";
import { clsxMerge } from "shared/lib/helpers";
import { ListSelectorMenu } from "./menu";
import { getListContactCountString } from "@/components/modules/pipeline/lists/list/primary-header/selector/utils";
import { getListName } from "@/components/modules/pipeline/utils";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface ListsSelectorPropsI extends PropsWithClassNameI {
  menuClassName?: string;
  items: ListPillI[] | undefined;
}

export const ListsSelector: FC<ListsSelectorPropsI> = ({
  className,
  menuClassName,
  items,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const { activeListId, activeList, setActiveListId } = useListsContext();

  const listPickerContainer = useRef<HTMLDivElement>(null);

  useOnClickOutside(listPickerContainer, onClose);

  const listName = useMemo(() => getListName(activeList), [activeList]);

  if (!activeList) {
    return null;
  }

  return (
    <div
      ref={listPickerContainer}
      className={clsxMerge("relative z-50 w-max", className)}
    >
      <div
        role="button"
        onClick={onOpen}
        className={clsxMerge("flex w-max cursor-pointer items-center gap-1.5")}
      >
        <div className="flex w-fit max-w-[462px] flex-col gap-1">
          <div
            className={clsxMerge(
              "flex items-center gap-3 rounded-lg border-2 border-transparent p-1.5",
              "hover:border-[#D9D9D960] hover:bg-black/1"
            )}
          >
            <span
              title={activeList?.name}
              className={clsxMerge(
                "truncate typography-header-5-semibold",
                activeList?.is_exclusive_list && "gradient-text"
              )}
            >
              {listName}
            </span>

            <ChevronDownIcon className="h-6 w-6" />
          </div>

          <span className="pl-2 text-black typography-body-5-medium">
            {getListContactCountString(activeList)}
          </span>
        </div>
      </div>

      {isOpen && (
        <ListSelectorMenu
          className={clsxMerge(menuClassName, "mt-1.5")}
          activeListId={activeListId}
          setActiveListId={setActiveListId}
          items={items}
          onClose={onClose}
        />
      )}
    </div>
  );
};
