import DataTable, {
  DataTablePropsI,
} from "@/modules/pipeline/lists/data-table";
import { InfiniteScrollWithObserver } from "shared/ui";
import React, { ForwardedRef, forwardRef } from "react";
import { Table } from "@tanstack/react-table";

interface InfiniteScrollingDataTablePropsI<T> extends DataTablePropsI<T> {
  listId?: string;

  isLoading: boolean;
  hasMoreRows: boolean;
  hasError?: boolean;
  isEmpty?: boolean;
  isDefaultMessageHidden?: boolean;
  fetchNextPage: () => void;
  reload?: () => void;
}

const InfiniteScrollingDataTable = <T,>(
  {
    listId,
    isLoading,
    hasMoreRows,
    isEmpty,
    fetchNextPage,
    reload,
    isDefaultMessageHidden,
    ...dataTableProps
  }: InfiniteScrollingDataTablePropsI<T>,
  tableRef?: ForwardedRef<Table<T>>
) => (
  <InfiniteScrollWithObserver
    isLoading={isLoading}
    isMore={hasMoreRows}
    isEmpty={isEmpty}
    onMore={fetchNextPage}
    isDefaultMessageHidden={isDefaultMessageHidden}
    hasError={dataTableProps.hasError}
    onReload={reload}
  >
    <DataTable listId={listId} {...dataTableProps} ref={tableRef} />
  </InfiniteScrollWithObserver>
);

export default forwardRef(InfiniteScrollingDataTable) as <T>(
  props: InfiniteScrollingDataTablePropsI<T> & {
    ref?: React.ForwardedRef<Table<T>>;
  }
) => ReturnType<typeof InfiniteScrollingDataTable>;
