import { LIST_TABLE_COLUMNS } from "@/modules/pipeline/lists/list/constants";

export const COLUMNS_VISIBLE_BY_DEFAULT = [
  LIST_TABLE_COLUMNS.NAME,
  LIST_TABLE_COLUMNS.TITLE,
  LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS,
  LIST_TABLE_COLUMNS.LAST_CONVERSATION,
  LIST_TABLE_COLUMNS.TIMEZONE,
  LIST_TABLE_COLUMNS.ACCOUNT,
  LIST_TABLE_COLUMNS.STATUS,
  LIST_TABLE_COLUMNS.NUMBER_OF_DIALS,
  LIST_TABLE_COLUMNS.NEXT_TOUCH_TIME,
  LIST_TABLE_COLUMNS.DIALS,
  LIST_TABLE_COLUMNS.LAST_DIALED,
  LIST_TABLE_COLUMNS.LAST_CALLED,
  LIST_TABLE_COLUMNS.MEETING_TIME,
];

export const DIALER_LIST_STATUS_COLUMN_INDEX = 0;

export const SYSTEM_LIST_STATUS_COLUMN_INDEX =
  COLUMNS_VISIBLE_BY_DEFAULT.findIndex(
    (column) => column === LIST_TABLE_COLUMNS.STATUS
  );

export const SYSTEM_LIST_NUMBER_OF_CONVERSATIONS_COLUMN_INDEX =
  COLUMNS_VISIBLE_BY_DEFAULT.findIndex(
    (column) => column === LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS
  );
