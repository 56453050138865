import { createContext, FC, PropsWithChildren, useContext } from "react";
import noop from "lodash/noop";

import { useDisclosure } from "shared/lib/hooks/use-disclosure";

interface ListTourContextI {
  isTourOpen: boolean;
  openTour: () => void;
  closeTour: () => void;
}

const ListTourContext = createContext<ListTourContextI>({
  isTourOpen: false,
  openTour: noop,
  closeTour: noop,
});

export const useListTourContext = () => {
  const context = useContext(ListTourContext);

  if (!context) {
    throw new Error(
      "useListTourContext must be used within a ListTourProvider"
    );
  }

  return context;
};

export const ListTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ListTourContext.Provider
      value={{
        isTourOpen: isOpen,
        openTour: onOpen,
        closeTour: onClose,
      }}
    >
      {children}
    </ListTourContext.Provider>
  );
};
