import { FC, useEffect } from "react";

import { FieldsMapperGlencocoFieldI } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/interfaces";
import { SearchSelectField } from "shared/ui/ae-user-input";
import { TextField } from "shared/ui/ae-user-input";
import { CustomSelectFieldOptionI } from "shared/ui/ae-user-input/search-select-field";
import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

const SimpleTextField = TextField();

interface FieldsMapperPropsI extends ControllableComponentPropsI<string> {
  gcField: FieldsMapperGlencocoFieldI;
  options?: Array<CustomSelectFieldOptionI>;
  autoMappedValue?: string;
  disabled?: boolean;
  isError?: boolean;
  onFocus: () => void;
}

const FieldsMapper: FC<FieldsMapperPropsI> = ({
  gcField,
  value,
  options = [],
  autoMappedValue,
  disabled,
  isError,
  onChange,
  onFocus = () => {},
}) => {
  useEffect(() => {
    if (autoMappedValue) {
      onChange(autoMappedValue);
    }
  }, [autoMappedValue]);

  return (
    <div className="mb-8 grid grid-cols-2 gap-x-8">
      <div>
        <SimpleTextField
          disabled
          className="mb-0"
          placeholder=""
          name={gcField.value}
          inputProps={{ value: gcField.label }}
          inputClassName={clsxMerge(isError && "!bg-error !text-error-content")}
        />
      </div>

      <div>
        <SearchSelectField
          isCaseInSensitive
          triggerClassName={clsxMerge(
            !!value &&
              autoMappedValue === value &&
              "border border-[rgb(204,57,151)]"
          )}
          placeholder="Select from dropdown"
          selectedValue={value || ""}
          options={options}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
        />
      </div>
    </div>
  );
};

export default FieldsMapper;
