import { FC } from "react";
import { Avatar } from "shared/ui";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface CampaignLogoPropsI extends PropsWithClassNameI {
  campaignId: string;
  defaultBgColor?: string;
  className?: string;
}

export const CampaignLogo: FC<CampaignLogoPropsI> = ({
  defaultBgColor = "bg-black",
  className,
}) => (
  <Avatar
    alt="Your campaign"
    className={clsxMerge(
      "mr-1 h-4 min-h-[16px] w-4 min-w-[16px]",
      defaultBgColor,
      className
    )}
  />
);
