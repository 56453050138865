import { useListsContext } from "@/modules/pipeline/lists/context";
import { useMemo } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { LIST_SUBSECTIONS_MAP } from "@/modules/pipeline/lists/list/constants";

export const PipelineListFooter = () => {
  const { activeList, filters, listDetails } = useListsContext();

  const {
    number_cold_contacts,
    number_available_contacts_to_call_cold,
    number_nurture_contacts,
    number_available_contacts_to_call_nurture,
    number_total_contacts,
  } = listDetails || {};

  const stats = useMemo(() => {
    if (listDetails) {
      const coldLeads = `Cold Leads ${number_available_contacts_to_call_cold}/${number_cold_contacts} `;
      const nurtureLeads = `Nurture Leads ${number_available_contacts_to_call_nurture}/${number_nurture_contacts} `;
      const totalLeads = `Total Leads ${number_total_contacts}`;

      if (filters?.list_subsection === LIST_SUBSECTIONS_MAP.COLD_CALL) {
        return coldLeads;
      }

      if (filters?.list_subsection === LIST_SUBSECTIONS_MAP.NURTURE) {
        return nurtureLeads;
      }

      return `${totalLeads}`;
    }

    return "";
  }, [activeList, filters, listDetails]);

  return (
    <div
      className={clsxMerge(
        "fixed bottom-0 h-[52px] w-full px-6",
        "bg-[#FAFAFA]",
        "flex items-center"
      )}
    >
      <span className="typography-body-4-bold">{stats}</span>
    </div>
  );
};
