import { FC, useState } from "react";
import clsx from "clsx";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { UploadLeadsSidebarContentChildI } from "components/modules/pipeline/shared/upload-leads-sidebar";
import { useFileUpload } from "./hooks/use-file-upload";
import { UploadFileI, UploadLeadsSidebarUploadButton } from "./upload-button";
import { useUploadLeadsSidebarContext } from "../../context";

import CircularLoading from "@/components/base/circular-loading";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { useFetchIngestionFields } from "../hooks/use-fetch-ingestion-fields";
import { useTimeout } from "shared/lib/hooks/use-timeout";

type UploadLeadsSidebarContentUploadCSVPropsI = UploadLeadsSidebarContentChildI;

interface IngestionFileUploadErrorPropsI {
  error: string | undefined;
}

const IngestionFileUploadError: FC<IngestionFileUploadErrorPropsI> = ({
  error,
}) =>
  error && (
    <div
      className={clsx(
        "flex w-full items-center gap-2",
        "b-typography-body3 text-error-content",
        "border-[rgba(255,94,58,0.2)] bg-[rgba(255,94,58,0.02)]",
        "rounded-lg border p-4 text-[rgba(255,94,58,0.8)]"
      )}
    >
      <ExclamationTriangleIcon className="h-5 w-5" />
      {error}
    </div>
  );

export const UploadLeadsSidebarContentUploadCSV = ({
  handleNextStep = () => {},
  handlePrevStep = () => {},
  onClose = () => {},
}: UploadLeadsSidebarContentUploadCSVPropsI) => {
  const {
    selectedCampaignId,
    ingestionId,
    setIngestionId,
    setIngestionFields,
    setIngestionAIMappings,
  } = useUploadLeadsSidebarContext();

  const [isVisible, setIsVisible] = useState(false);
  const [ingestionError, setIngestionError] = useState<string>();
  const [isLoadingIngestionFields, setIsLoadingIngestionFields] =
    useState(false);

  const {
    isLoading: isFileUploading,
    isUploadComplete,
    handleCSVUpload,
  } = useFileUpload();

  useFetchIngestionFields(
    selectedCampaignId,
    ingestionId as string,
    setIngestionFields,
    setIngestionAIMappings,
    setIngestionError,
    () => setIsLoadingIngestionFields(false)
  );

  const uploadCSV = async (data: UploadFileI) => {
    if (selectedCampaignId) {
      await handleCSVUpload(data, selectedCampaignId, onFileUploadSuccess);
    }
  };

  const onFileUploadSuccess = (ingestionId: string) => {
    setIngestionId(ingestionId);
    setIngestionError(undefined);

    // after the file is uploaded, the ingestionId is set and then useFetchIngestionFields starts
    setIsLoadingIngestionFields(true);
  };

  useTimeout(() => setIsVisible(true), 100);

  return (
    <SidebarSlide
      title=" "
      size={SIDEBAR_SIZE.MD}
      headerClassName="pb-0"
      contentClassName="pt-0"
      onClose={onClose}
    >
      <article
        className={clsx(
          "relative transition-opacity duration-300",
          isVisible ? "opacity-100" : "opacity-0"
        )}
      >
        <section className="mb-6">
          <p className="b-typography-h3 mb-1">Upload CSV</p>
          <p className="b-typography-body3">
            Select your CSV lead list to be added to your pipeline
          </p>
        </section>

        <UploadLeadsSidebarUploadButton
          isLoading={isFileUploading}
          handleCSVUpload={uploadCSV}
          disabled={!selectedCampaignId}
        />

        <IngestionFileUploadError error={ingestionError} />

        {isLoadingIngestionFields && (
          <div className="b-typography-body3 flex gap-2">
            <CircularLoading />
            Please wait while the leads data is being processed...
          </div>
        )}

        <section className="z-100 fixed bottom-0 left-0 flex w-full justify-between px-6 py-4">
          <button className="btn-transparent" onClick={onClose}>
            Cancel
          </button>

          <span>
            <button
              className="btn-nofill mr-2 w-[124px]"
              onClick={handlePrevStep}
            >
              Back
            </button>

            <button
              className={clsx("btn-ae-default w-[152px]", {
                disabled:
                  !isUploadComplete ||
                  ingestionError ||
                  isLoadingIngestionFields,
              })}
              onClick={handleNextStep}
            >
              Next
            </button>
          </span>
        </section>
      </article>
    </SidebarSlide>
  );
};
