import { useRef, FC } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { toast } from "react-hot-toast";

import { TextField } from "shared/ui/ae-user-input";
import { Dropdown } from "shared/ui";
import { USA_STATES } from "@/constants/states";
import { glencocoClientAPI } from "@/api/glencoco";
import { CampaignI } from "@/interfaces/campaign";
import { AccountI } from "shared/lib/interfaces/account";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";

interface EditAccountModalPropsI {
  modalId?: string;
  account?: AccountI;
  campaign?: CampaignI;
  onSubmitSuccess?: (data: AccountI) => void;
}

export interface EditAccountFormI {
  account_name: string;
  website?: string;
  industry?: string;
  revenue?: string | number;
  hq_state?: string;
  employee_count?: string;
}

const EnhancedTextField = TextField(Field);
const EnhancedDropdown = Dropdown(Field);

const softUrlRegExp =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const EditAccountFormValidation = Yup.object().shape({
  account_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(64, "Maximum 100 characters")
    .required("Required field"),
  website: Yup.string()
    .matches(softUrlRegExp, "Website should be a valid URL")
    .max(256, "Maximum 256 characters"),
  industry: Yup.string().max(1000, "Maximum 100 characters"),
  revenue: Yup.number()
    .typeError("Please provide a valid number")
    .min(2, "Minimum 2 characters")
    .max(1_000_000_000_000, "Maximum value 1,000,000,000,000"),
  hq_state: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters"),
  employee_count: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters"),
});

export const EDIT_ACCOUNT_MODAL_ID = "edit-account-modal";

const EditAccountModalContent: FC<
  EditAccountModalPropsI & ModalChildrenFnPropsI
> = ({ handleClose, account, campaign, onSubmitSuccess = () => {} }) => {
  const formikRef = useRef<FormikProps<EditAccountFormI>>(null);

  const formInitialValues: EditAccountFormI = {
    account_name: account?.name || "",
    website: account?.website || "",
    industry: account?.industry || "",
    revenue: account?.revenue?.toString() || "",
    hq_state: account?.hq_state || "",
    employee_count: account?.employee_count || "",
  };

  const handleSubmit = async (data: EditAccountFormI) => {
    if (campaign?.id && account?.id) {
      const API = glencocoClientAPI();

      const formattedData = {
        name: data.account_name,
        website: data.website,
        industry: data.industry,
        revenue: data.revenue as string,
        state: data.hq_state,
        headcount: data.employee_count,
      };

      const resp = await API.editAccountInfo(
        campaign.id,
        account.id,
        formattedData
      ).catch(() => ({ status: null, data: null }));

      if (resp.status === 200) {
        toast.success("Success");

        onSubmitSuccess({ ...account, ...data });
        handleClose();
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={EditAccountFormValidation}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {({ errors, touched, isSubmitting, isValid }) => (
        <Form>
          <EnhancedTextField
            name="account_name"
            label="Account"
            placeholder=""
            errors={errors.account_name}
            touched={touched.account_name}
          />

          <EnhancedTextField
            name="website"
            label="Website"
            placeholder=""
            errors={errors.website}
            touched={touched.website}
          />

          <EnhancedTextField
            name="industry"
            label="Industry"
            placeholder=""
            errors={errors.industry}
            touched={touched.industry}
          />

          <EnhancedTextField
            name="revenue"
            label="Revenue"
            placeholder=""
            errors={errors.revenue}
            touched={touched.revenue}
          />

          <EnhancedDropdown
            name="hq_state"
            label="HQ State"
            options={USA_STATES}
            placeholder="Select a state"
            errors={errors.hq_state}
            touched={touched.hq_state}
            labelContentClassName="ae-typography-detail1 text-base-content/60 font-[600]"
          />

          <EnhancedTextField
            name="employee_count"
            label="Number of Employees"
            placeholder=""
            errors={errors.employee_count}
            touched={touched.employee_count}
          />

          <div className="mt-8 flex justify-end">
            <button
              type="button"
              className="btn-ae-text mr-2"
              onClick={handleClose}
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className={clsx("btn-ae-default w-[140px]", {
                loading: isSubmitting,
              })}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const EditAccountModal: FC<EditAccountModalPropsI> = (props) => (
  <Modal
    id={props.modalId || EDIT_ACCOUNT_MODAL_ID}
    unmountOnClose
    title="Edit Account"
  >
    {(modalProps) => <EditAccountModalContent {...props} {...modalProps} />}
  </Modal>
);

export default EditAccountModal;
