export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const megaBytesToBytes = (mb: number) => mb * 1024 * 1024;

export const readFileAsDataURL = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    {
      const reader = new FileReader();

      reader.onload = function () {
        const dataURL = reader.result;
        resolve(dataURL as string);
      };

      reader.onerror = (error) => {
        console.error("Failed to read image file", error);
        reject(null);
      };

      reader.readAsDataURL(file);
    }
  });
