import { useEffect } from "react";
import { useQueryState } from "nuqs";
import wait from "wait";

import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "./constants";
import { WIDGETS } from "@/constants/widgets";
import { widgets } from ".";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";
import { DialerCallStatusI } from "@/interfaces/dialer/call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const useWidgetsSearchQueryParams = () => {
  const [widgetType] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE
  );
  const [campaignId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID
  );
  const [accountId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID
  );

  const [dialerTourCallStatus] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.DIALER_TOUR_CALL_STATUS
  );

  useEffect(() => {
    (async () => {
      await wait(300);

      switch (widgetType) {
        case WIDGETS.MAXED_DIALER:
          dd.rum.log(`${LOG_CATEGORIES.WIDGET} - triggered`, {
            data: {
              filePath: `/shared/widgets/use-widgets-search-query-params.ts`,
            },
          });

          widgets.open({
            id: WIDGETS.MAXED_DIALER,
            config: {
              campaignId: campaignId as string,
              accountId: accountId as string,
            },
          });
          break;
        case WIDGETS.MAXED_DIALER_TOUR:
          widgets.open({
            id: WIDGETS.MAXED_DIALER_TOUR,
            state: Object.values(DIALER_CALL_STATUS).includes(
              dialerTourCallStatus as DialerCallStatusI
            )
              ? {
                  callStatus: dialerTourCallStatus as DialerCallStatusI,
                }
              : {},
          });
      }
    })();
  }, [widgetType]);
};
