import { useEffect } from "react";

import { AccountHistorySection } from "./sections/account-history-section";

import { AccountSummarySection } from "./sections/account-summary-section";
import { DispositionSection } from "./sections/disposition-section";
import { AccountDetailsV2NoteEditor } from "./sections/note-editor-section";

import { useAccountDetailsContext } from "../../context";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { ADPrimaryContentProvider } from "./context";
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { ACCOUNT_DETAILS_CONTENT_ID } from "@/constants/element-ids";

export const PrimaryContent = () => {
  const { accountStatus, callStatus } = useAccountDetailsContext();
  const { clear: clearCallContext } = useInCallContext();

  /**
   * Note
   * Do not require disposition if meeting is already booked
   */
  useEffect(() => {
    if (
      callStatus === DIALER_CALL_STATUS.AFTER &&
      accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED
    ) {
      clearCallContext();
    }
  }, [callStatus, accountStatus]);

  return (
    <ADPrimaryContentProvider>
      <div id={ACCOUNT_DETAILS_CONTENT_ID} className="px-2 pb-40 xl:px-12">
        <div className="mx-auto max-w-[1100px]">
          <AccountSummarySection className="mt-3" />

          {DIALER_CALL_STATUS.AFTER === callStatus && <DispositionSection />}

          {DIALER_CALL_STATUS.AFTER !== callStatus && (
            <AccountDetailsV2NoteEditor className="mb-3 mt-5" />
          )}

          <AccountHistorySection />
        </div>
      </div>
    </ADPrimaryContentProvider>
  );
};
