import { FC } from "react";
import { useRouter } from "next/router";
import { useQueryClient } from "@tanstack/react-query";

import { useInterval } from "shared/lib/hooks/use-interval";
import { glencocoClientAPI } from "@/api/glencoco";
import { GetIngestionResponseI } from "@/api/routes/ingestions";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";
import { FieldsMappingProgressState } from "./progress";
import { useUploadLeadsSidebarContext } from "@/modules/pipeline/shared/upload-leads-sidebar/context";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { LISTS_QUERY_KEY } from "@/modules/pipeline/lists/list/queries/keys";
import Router from "@/helpers/router";
import { useLocalStorage } from "@/context/local-storage";

interface UploadLeadsSidebarContentStatesPropsI {
  onClose?: () => void;
}

/**
 * This component represents the final step of ingestion upload. It combines a
 * loading screen with a polling request to check for the actual ingestion
 * status.
 */
export const UploadLeadsSidebarContentStates: FC<
  UploadLeadsSidebarContentStatesPropsI
> = ({ onClose }) => {
  const { ingestionId, selectedListId } = useUploadLeadsSidebarContext();
  const queryClient = useQueryClient();
  const { push } = useRouter();
  const localStorage = useLocalStorage();

  useInterval(
    async () => {
      if (ingestionId) {
        const API = glencocoClientAPI();
        const latestIngestionIdResponse = await API.ingestions
          .getIngestion(ingestionId)
          .catch((e) => e);

        if (
          latestIngestionIdResponse.status === 200 &&
          (latestIngestionIdResponse?.data as GetIngestionResponseI)?.ingestion
            ?.ingestion_initial_sync_finished
        ) {
          if (selectedListId) {
            /**
             * Create an artificial delay of one second to make sure leads will
             * be displayed in the list. Needed because of asynchronous nature of
             * the ingestion process.
             */
            setTimeout(async () => {
              if (
                localStorage.activeListId &&
                selectedListId !== localStorage.activeListId
              ) {
                // Navigate to the list, which has been selected to add this lead to
                await push({
                  search: Router.searchQueryParams.listId(selectedListId),
                });
              }

              /**
               * Mark ALL lists-related rendered queries as stale to trigger a
               * backend refetch, so both counters of contacts and contacts in
               * an active list are up-to-date
               */
              await queryClient.invalidateQueries({
                queryKey: [LISTS_QUERY_KEY],
              });

              onClose?.();
            }, TIME_INTERVALS_MS.ONE_SECOND);
          } else {
            onClose?.();
          }
        }
      }
    },
    TIME_INTERVALS_MS.ONE_SECOND * 10,
    [ingestionId, selectedListId]
  );

  return (
    <SidebarSlide
      title=" "
      headerClassName="pb-0"
      contentClassName="pt-0"
      size={SIDEBAR_SIZE.LG}
      onClose={onClose}
    >
      <section>
        <p className="b-typography-h2 mb-1">Map Leads</p>
        <p className="b-typography-body3">
          Map your CSV columns to match the required Glencoco fields
        </p>
      </section>

      <section className="flex h-[calc(100%_-_140px)] items-center">
        <FieldsMappingProgressState />
      </section>
    </SidebarSlide>
  );
};
