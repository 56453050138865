import { FC, useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { useGlobalContext } from "@/hooks/use-global-context";
import { TextField } from "shared/ui/user-input";
import {
  clsxMerge,
  getFullEntityName,
  getInitialsFromUsername,
} from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { closeProfileSettings } from "lib/store/profile-settings";
import { useApiClient } from "@/context/api-client";
import { isPromiseFulfilled } from "shared/lib/helpers/utils";
import { UPDATE_PROFILE_MESSAGES } from "@/modules/profile-settings/form/constants";
import { useUploadProfileImage } from "@/components/shared/profile-image-selector/hooks";
import { ProfileImageSelector } from "@/components/shared/profile-image-selector";

interface ProfileDataI {
  email: string;
  first_name: string;
  last_name: string;
}

const Input = TextField(Field);

const ProfileDataSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(24, "Maximum 24 characters")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(24, "Maximum 24 characters")
    .required("Last name is required"),
});

export const ProfileSettingsForm: FC<PropsWithClassNameI> = ({ className }) => {
  const [selectedAvatarFile, setSelectedAvatarFile] = useState<
    File | undefined
  >();

  const { uploadProfileImage, isUploadingProfileImage } =
    useUploadProfileImage();

  const { glencocoUser, reloadUser } = useGlobalContext();
  const apiClient = useApiClient();

  const formValues = useMemo(
    () => ({
      email: glencocoUser?.email || "Your email is not available",
      first_name: glencocoUser?.first_name || "",
      last_name: glencocoUser?.last_name || "",
    }),
    [glencocoUser?.first_name, glencocoUser?.last_name]
  );

  const handleSaveProfile = async ({ first_name, last_name }: ProfileDataI) => {
    const updateProfilePromise = apiClient
      .submitUserOnboarding({ first_name, last_name })
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject();
        }

        return Promise.resolve();
      });

    const updateAvatarPromise = uploadProfileImage(selectedAvatarFile);

    const [savedProfileResult, savedAvatarResult] = await Promise.allSettled([
      updateProfilePromise,
      updateAvatarPromise,
    ]);

    const isProfileUpdated = isPromiseFulfilled(savedProfileResult);
    const isAvatarUpdated = isPromiseFulfilled(savedAvatarResult);

    reloadUser?.();

    if (isProfileUpdated && isAvatarUpdated) {
      toast.success(UPDATE_PROFILE_MESSAGES.SUCCESS.BOTH);
      closeProfileSettings();
    } else {
      if (isProfileUpdated) {
        toast.success(UPDATE_PROFILE_MESSAGES.SUCCESS.DATA);
      } else {
        toast.error(UPDATE_PROFILE_MESSAGES.ERROR.DATA);
      }

      if (isAvatarUpdated) {
        toast.success(UPDATE_PROFILE_MESSAGES.SUCCESS.PICTURE);
      } else {
        toast.error(UPDATE_PROFILE_MESSAGES.ERROR.PICTURE);
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={ProfileDataSchema}
      initialValues={formValues}
      onSubmit={handleSaveProfile}
    >
      {({ touched, errors, isValid, isSubmitting }) => (
        <Form
          className={clsxMerge(
            "flex h-full w-full flex-col justify-between",
            className
          )}
        >
          <div className="flex flex-col gap-2">
            <Input
              disabled
              name="email"
              label="Email"
              labelContentClassName="typography-body-4-medium"
              placeholder="You cannot change your email"
              errors={errors.first_name}
              touched={touched.first_name}
            />

            <Input
              name="first_name"
              label="First name"
              labelContentClassName="typography-body-4-medium"
              placeholder="Enter your first name"
              disabled={isSubmitting}
              errors={errors.first_name}
              touched={touched.first_name}
            />

            <Input
              name="last_name"
              label="Last name"
              labelContentClassName="typography-body-4-medium"
              placeholder="Enter your last name"
              disabled={isSubmitting}
              errors={errors.last_name}
              touched={touched.last_name}
            />

            <ProfileImageSelector
              fallbackText={getInitialsFromUsername(
                getFullEntityName(glencocoUser)
              )}
              savedPictureURL={glencocoUser?.profile_image_url}
              isUploadingProfilePicture={
                isUploadingProfileImage || isSubmitting
              }
              onChangeProfilePicture={setSelectedAvatarFile}
            />
          </div>

          <div className="mt-8 flex justify-end gap-1">
            <button
              type="button"
              className={clsxMerge("btn-ae-text", isSubmitting && "disabled")}
              onClick={closeProfileSettings}
            >
              Cancel
            </button>

            <button
              className={clsxMerge(
                "btn-ae-default w-[150px]",
                (!isValid || isSubmitting) && "disabled"
              )}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
