import { useCallback, useEffect, useRef, useState } from "react";
import { SortingState } from "@tanstack/react-table";
import isEqual from "lodash/isEqual";

import { SessionStorage } from "@/helpers/session-storage";

import { ListTableSortingStateI } from "@/modules/pipeline/lists/list/workspace/table/interfaces";
import { transformSortingStateToRequestParams } from "@/modules/pipeline/lists/data-table/utils";

export const useTableSorting = (listId: string) => {
  // Using ref here, otherwise it'll be re-created at least on each setSortParams
  const sessionStorageRef = useRef(new SessionStorage());

  const [sortParams, setSortParams] = useState<ListTableSortingStateI>();

  const syncSortParams = useCallback(() => {
    const newSortParams =
      sessionStorageRef.current.getListSorting(listId) || {};

    if (!isEqual(newSortParams, sortParams)) {
      setSortParams(newSortParams);
    }
  }, []);

  useEffect(() => {
    syncSortParams();
  }, [listId]);

  const onSort = useCallback(
    (onBefore?: () => void) => (sortingState: SortingState) => {
      onBefore?.();

      const newSortParams = transformSortingStateToRequestParams(sortingState);
      console.log(`save sort params`, newSortParams);
      sessionStorageRef.current.setListSorting(listId, newSortParams);
      setSortParams(newSortParams);
    },
    []
  );

  return { sortParams, setSortParams, onSort };
};
