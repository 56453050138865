import { AxiosInstance, AxiosResponse } from "axios";
import { UserI } from "@/interfaces/user";
import { UserOnboardingStatusI } from "@/interfaces/signup";
import { CampaignI } from "@/interfaces/campaign";

export type UserReferralSourceTypeI =
  | "LinkedInSignUpForm"
  | "LinkedInDirectMessage"
  | "GoogleSponsoredAd"
  | "GoogleSearch"
  | "Discord"
  | "Craigslist"
  | "TikTok"
  | "Twitter"
  | "Instagram"
  | "YouTubeShorts"
  | "Reddit"
  | "DirectReferral"
  | "Other";

export interface GetUserResponseI {
  user: UserI;
  intercom_payload?: any;
  user_onboarding_status: UserOnboardingStatusI;
  campaign?: CampaignI;
}

export interface UserUpdateResponseI {
  user: UserI;
}

export interface UserPatchParamsI {
  patch_user: {
    email?: string;
    first_name?: string;
    last_name?: string;

    // delete these later
    about?: string;
    linkedin_profile?: string;
    phone_number?: string;
    address?: string;
    state_of_residence?: string;
    general_knowledge?: string[];
    current_situation?: string;
    job_experience_description?: string;
    gig_work_experience?: string;
    sales_experience?: boolean;
    cold_call_experience?: boolean;
    available_10_hours?: boolean;
    full_time_consideration?: boolean;
    referral_source?: UserReferralSourceTypeI;
    referral_source_other?: string;
  };
  final_submit: boolean;
}

export interface UserApiI {
  getUser(): Promise<AxiosResponse<GetUserResponseI>>;

  setUser(
    params: UserPatchParamsI
  ): Promise<AxiosResponse<UserUpdateResponseI>>;
}

export const UserAPIEndpoints = (API: AxiosInstance): UserApiI => ({
  getUser: () => API.get("/x/v1/me"),
  setUser: (params) => API.patch("/x/v1/me", { ...params }),
});
