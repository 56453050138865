import { FC, HTMLProps, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { default as DropdownBase } from "shared/ui/user-input/dropdown";
import { useUploadLeadsSidebarContext } from "@/modules/pipeline/shared/upload-leads-sidebar/context";
import { glencocoClientAPI } from "@/api/glencoco";
import { useFetchUploadLeadsMeetingTakers } from "@/modules/pipeline/shared/upload-leads-sidebar/components/assign-ae/queries/queries";
import { clsxMerge, getFullEntityName } from "shared/lib/helpers";
import { UploadLeadsSidebarContentChildI } from "@/modules/pipeline/shared/upload-leads-sidebar";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";

const Dropdown = DropdownBase((props: HTMLProps<HTMLSelectElement>) => (
  <select {...props} />
));

type UploadLeadsSidebarContentAssignAEPropsI = UploadLeadsSidebarContentChildI;

const NO_AE_OPTION = {
  label: "No Account Executive",
  value: undefined,
};

export const UploadLeadsSidebarContentAssignAE: FC<
  UploadLeadsSidebarContentAssignAEPropsI
> = ({ handleNextStep, onClose }) => {
  const { ingestionRequest, selectedListId, selectedCampaignId, ingestionId } =
    useUploadLeadsSidebarContext();
  const [isSubmittingIngestion, setIsSubmittingIngestion] = useState(false);
  const [selectedMeetingTaker, setSelectedMeetingTaker] = useState<string>();

  const meetingTakersApi = useFetchUploadLeadsMeetingTakers();
  const { data: meetingTakers } = meetingTakersApi;

  const handleSaveIngestion = async () => {
    if (!selectedCampaignId || !ingestionId || !ingestionRequest) {
      return;
    }

    setIsSubmittingIngestion(true);

    const API = glencocoClientAPI();

    const submitIngestionResponse = await API.ingestions
      .submitIngestionFields(selectedCampaignId, ingestionId, {
        ...ingestionRequest,
        meeting_taker_id:
          selectedMeetingTaker === NO_AE_OPTION.label
            ? undefined
            : selectedMeetingTaker,
        list_id: selectedListId,
      })
      .catch((e) => e);

    if (submitIngestionResponse.status === 200) {
      handleNextStep?.();
    } else {
      toast.error(
        "Failed to submit mapping due to internal server error. Please contact Glencoco team."
      );

      setIsSubmittingIngestion(false);
    }
  };

  const aeMeetingTakerOptions = useMemo(() => {
    if (!meetingTakers) {
      return [NO_AE_OPTION];
    }

    const aeOptions = meetingTakers.map((meetingTaker) => ({
      label: getFullEntityName(meetingTaker),
      value: meetingTaker.id,
    }));

    return [...aeOptions, NO_AE_OPTION];
  }, [meetingTakers]);

  return (
    <SidebarSlide
      title="Assign meeting taker"
      headerClassName="pb-0"
      contentClassName="pt-0"
      size={SIDEBAR_SIZE.MD}
      onClose={onClose}
    >
      <article>
        <section className="mt-4">
          <DataStatesWrapper api={meetingTakersApi} viewName="meeting takers">
            <Dropdown
              label="Select meeting taker that owns these leads"
              placeholder="Select meeting taker"
              options={aeMeetingTakerOptions}
              inputProps={{
                value: selectedMeetingTaker,
                onChange: (e) => setSelectedMeetingTaker(e.target.value),
              }}
            />
          </DataStatesWrapper>
        </section>

        <section
          className={clsxMerge(
            "z-100 fixed bottom-0 left-0 flex w-full justify-end gap-3",
            "py-4 pr-[22px]"
          )}
        >
          <button className="btn-nofill border-none" onClick={onClose}>
            Cancel
          </button>

          <button
            className={clsxMerge("btn-ae-default w-[197px]", {
              disabled: isSubmittingIngestion || !selectedMeetingTaker,
              loading: isSubmittingIngestion,
            })}
            onClick={handleSaveIngestion}
          >
            Next
          </button>
        </section>
      </article>
    </SidebarSlide>
  );
};
