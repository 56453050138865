import { INGESTION_FIELDS, INGESTION_TYPES } from "@/constants/ingestion";
import { FieldsMapperGlencocoFieldI } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/interfaces";

export const INGESTION_TABLE_TYPES = {
  // TODO verify that make value with Capital Letter won't break anything
  ACCOUNT: "account",
  CONTACT: "contact",
  SUPPRESSION: "Suppression",
} as const;

export const GC_FIELDS_DEFAULT: Array<FieldsMapperGlencocoFieldI> = [
  {
    value: INGESTION_FIELDS.ACCOUNT.NAME,
    label: "Account Name *",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: true,
  },
  {
    value: INGESTION_FIELDS.ACCOUNT.WEBSITE,
    label: "Account Website",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.CONTACT.FIRST_NAME,
    label: "Prospect First Name *",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: true,
  },
  {
    value: INGESTION_FIELDS.CONTACT.LAST_NAME,
    label: "Prospect Last Name *",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: true,
  },
  {
    value: INGESTION_FIELDS.CONTACT.TITLE,
    label: "Prospect Title *",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: true,
  },
  {
    value: INGESTION_FIELDS.CONTACT.PHONE,
    label: "Prospect Phone Number *",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: true,
  },
  {
    value: INGESTION_FIELDS.CONTACT.EMAIL,
    label: "Prospect Email *",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: true,
  },
  {
    value: INGESTION_FIELDS.CONTACT.MOBILE,
    label: "Prospect Mobile Number",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.CONTACT.LINKEDIN_URL,
    label: "Prospect LinkedIn URL",
    type: INGESTION_TABLE_TYPES.CONTACT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.ACCOUNT.CITY,
    label: "Account City",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.ACCOUNT.STATE,
    label: "Account HQ State",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.ACCOUNT.INDUSTRY,
    label: "Account Industry",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.ACCOUNT.HEADCOUNT,
    label: "Account Employee Count",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: false,
  },
  {
    value: INGESTION_FIELDS.ACCOUNT.REVENUE,
    label: "Account Revenue",
    type: INGESTION_TABLE_TYPES.ACCOUNT,
    required: false,
  },
  // {
  //   value: INGESTION_FIELDS.ACCOUNT.CITY,
  //   label: "Account HQ City",
  //   type: INGESTION_TABLE_TYPES.ACCOUNT,
  //   required: false,
  // },
];

// Note One of the fields is required
const SUPPRESSION_GROUP = "suppression_group";
const GC_CSV_SUPRESSION_FIELDS: Array<FieldsMapperGlencocoFieldI> = [
  {
    value: INGESTION_FIELDS.SUPPRESSION.EMAIL_DOMAIN,
    label: "Prospect Email Domain",
    type: INGESTION_TABLE_TYPES.SUPPRESSION,
    required: false,
    group: SUPPRESSION_GROUP,
    isOneOfGroupRequired: true,
  },
  {
    value: INGESTION_FIELDS.SUPPRESSION.PHONE_NUMBER,
    label: "Prospect Phone Number",
    type: INGESTION_TABLE_TYPES.SUPPRESSION,
    required: false,
    group: SUPPRESSION_GROUP,
    isOneOfGroupRequired: true,
  },
];

export const GC_FIELDS = {
  [INGESTION_TYPES.MERGE]: GC_FIELDS_DEFAULT,
  [INGESTION_TYPES.CSV]: [...GC_FIELDS_DEFAULT],
  [INGESTION_TYPES.CSV_SUPPRESSION]: [...GC_CSV_SUPRESSION_FIELDS],
};
