import { FC, useMemo } from "react";

import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";
import { ListSubsectionI } from "@/modules/pipeline/lists/list/interfaces";
import { clsxMerge } from "shared/lib/helpers";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import {
  LIST_SUBSECTION_DESCRIPTIONS_MAP,
  LIST_SUBSECTIONS_LABEL_MAP,
} from "@/components/modules/pipeline/lists/list/constants";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { LIST_SUBSECTIONS_MAP } from "@/modules/pipeline/lists/list/constants";

const BG_CLASSNAMES = {
  [LIST_SUBSECTIONS_MAP.COLD_CALL]:
    "bg-gradient-to-r from-[#363FD0] to-[#99B2FF]",
  [LIST_SUBSECTIONS_MAP.NURTURE]:
    "bg-gradient-to-r from-[#FF7B1D] to-[#DA1C00]",
};

interface ListTypeButtonPropsI
  extends ControllableComponentPropsI<ListSubsectionI> {
  id?: string;
  subsection: ListSubsectionI;
  numerator?: number;
  denominator?: number;
  tooltip?: string;
  className?: string;
}

export const ListSubsectionButton: FC<ListTypeButtonPropsI> = ({
  id,
  value,
  onChange,
  subsection,
  numerator,
  denominator,
  tooltip,
  className,
}) => {
  const { isEnabled } = useDialerGlobalContext();
  const isActive = value === subsection;

  const leadsCount = useMemo(
    () =>
      numerator === denominator
        ? numerator
        : `${numerator || 0} / ${denominator || 0}`,
    [numerator, denominator]
  );

  const content = (
    <div
      id={id}
      className={clsxMerge(
        "cursor-pointer border-2 border-transparent",
        "w-[220px] rounded-xl p-3",
        "transition-all duration-300",
        "relative typography-body-4",
        {
          "border-[#4474E3] text-white": isActive,
          [BG_CLASSNAMES[subsection]]: isActive,
          "bg-white hover:scale-[0.99] hover:border-[#D9D9D960]": !isActive,
        },
        {
          "opacity-50": isEnabled,
        },
        className
      )}
      onClick={isEnabled ? () => {} : () => onChange(subsection)}
    >
      <div
        className={clsxMerge("mb-1", {
          "text-black/60": !isActive,
        })}
      >
        {LIST_SUBSECTIONS_LABEL_MAP[subsection]} leads
      </div>

      <div className="mb-1 typography-body-2">{leadsCount}</div>

      <div
        className={clsxMerge("typography-body-6", {
          "text-black/40": !isActive,
        })}
      >
        {LIST_SUBSECTION_DESCRIPTIONS_MAP[subsection]}
      </div>

      {isActive && (
        <div
          className={clsxMerge(
            "absolute right-2 top-2",
            "px-2 py-1",
            "rounded-md bg-black text-white/80 typography-body-5",
            "animate-fadein"
          )}
        >
          Selected
        </div>
      )}
    </div>
  );

  return tooltip ? (
    <Tooltip showDelay={50}>
      <TooltipTrigger className="text-start">{content}</TooltipTrigger>
      <TooltipContent className="max-w-[180px] px-6 text-center typography-body-5-medium">
        {tooltip}
      </TooltipContent>
    </Tooltip>
  ) : (
    content
  );
};
