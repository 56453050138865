import clsx from "clsx";
import prependHttp from "prepend-http";
import _truncate from "lodash/truncate";

import { CopyToClipboard, Label } from "shared/ui";
import { LogoLinkedInIcon } from "shared/ui/icons";
import { IngestionFieldsI } from "@/interfaces/ingestion";
import { MiscI } from "@/interfaces/ingestion";
import { INGESTION_TYPES } from "@/constants/ingestion";
import { PreviewIngestionSupressionCard } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/preview-lead-card/preview-ingestion-supression-card";
import LeadDescription from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/preview-lead-card/lead-description";
import { twMerge } from "tailwind-merge";
import { IngestionTypesI } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/interfaces";

const LinkedIn = ({ url }: { url?: string }) => {
  return url ? (
    <Label
      variation="accent"
      href={url}
      target="_blank"
      rel="noreferrer"
      className="ml-1 flex items-center"
    >
      <LogoLinkedInIcon className="h-3" />
    </Label>
  ) : null;
};

const MiscsBox = ({ data = [] }: { data?: Array<MiscI> }) => {
  const MAX_LABEL_LENGTH = 20;

  return data?.length && data.some((misc) => misc.label || misc.sample) ? (
    <div className="mb-6 flex flex-col gap-3 rounded-lg bg-black/5 p-3">
      {data?.map((misc, i) => {
        return misc.label || misc.sample ? (
          <div key={i}>
            <div className="flex items-center justify-start">
              {misc.label?.length > MAX_LABEL_LENGTH ? (
                <div className="tooltip" data-tip={misc.label}>
                  <span className="ae-typography-detail1 w-50 break-all text-base-content/40">
                    {_truncate(misc.label, {
                      omission: "...",
                    })}
                  </span>
                </div>
              ) : (
                <div>
                  <span className="ae-typography-detail1 w-50 break-all text-base-content/40">
                    {_truncate(misc.label, {
                      length: MAX_LABEL_LENGTH,
                      omission: "...",
                    })}
                  </span>
                </div>
              )}
              {/*  */}
            </div>

            <CopyToClipboard textToCopy={misc.sample}>
              <span className="ae-typography-body2 w-50 break-all text-base-content/60">
                {misc.sample}
              </span>
            </CopyToClipboard>
          </div>
        ) : null;
      })}
    </div>
  ) : null;
};

const convertIntoValidUrl = (url: string) => {
  if (!url) {
    return url;
  }

  return prependHttp(url);
};

//TODO - unite with customer platform duplicate component
const PreviewCard = ({
  first_name = "--",
  last_name = "--",
  title = "--",
  name = "--",
  website,
  linkedin_url,
  headcount = "-",
  industry = "-",
  revenue = "-",
  state = "-",
  phone = "-",
  email = "-",
  miscs,
  config,
}: IngestionFieldsI & {
  config?: {
    ingestionType?: IngestionTypesI;
    className?: string;
    cardClassName?: string;
    info?: string;
  };
}) => (
  <>
    {INGESTION_TYPES.CSV_SUPPRESSION === config?.ingestionType && (
      <PreviewIngestionSupressionCard email={email} phone={phone} />
    )}

    {!config?.ingestionType ||
      ([INGESTION_TYPES.CSV as string, INGESTION_TYPES.MERGE].includes(
        config?.ingestionType as string
      ) && (
        <div
          className={twMerge(
            clsx("sticky top-4 min-h-[280px] w-[380px]", config?.className)
          )}
        >
          <div
            className={twMerge(
              clsx(
                "card rounded-lg border border-black/5 p-4",
                config?.cardClassName
              )
            )}
          >
            <div className={clsx("rounded-lg bg-black/5 px-3 py-6")}>
              <div className="mb-8">
                <label className="ae-typography-h2 mb-1 flex items-center">
                  <span className="mr-2 flex items-center">
                    {first_name} {last_name} <LinkedIn url={linkedin_url} />
                  </span>
                </label>
                <p className="ae-typography-body3 text-black/60">
                  <span>{title}</span>

                  <span>
                    {" "}
                    @{" "}
                    {website ? (
                      <a
                        href={convertIntoValidUrl(website)}
                        target="_blank"
                        rel="noreferrer"
                        className="ae-link cursor-pointer"
                      >
                        {name}
                      </a>
                    ) : (
                      <span>{name}</span>
                    )}
                  </span>
                </p>
              </div>

              <MiscsBox data={miscs} />

              <LeadDescription
                name={name}
                headcount={headcount}
                industry={industry}
                revenue={revenue}
                state={state}
                phone={phone}
                email={email}
              />
            </div>
          </div>

          {!!config?.info && (
            <p className="ae-typography-detail1 mt-3 font-normal text-base-content/60">
              {config?.info}
            </p>
          )}
        </div>
      ))}
  </>
);

export default PreviewCard;
