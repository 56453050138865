import clsx from "clsx";

import { Cell } from "@/modules/pipeline/shared/upload-leads-sidebar/components/download-csv-example/past-uploads-table/cell";
import { IngestionI } from "@/interfaces/ingestion";
import { safeGetDate } from "shared/lib/helpers";
import { DATE_FORMAT_SHORT } from "shared/lib/constants/time";
import { FC } from "react";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

const TABLE_HEADERS = ["File Name", "# Leads", "Campaign", "Uploaded"];

const FileName = ({ filename }: { filename?: string }) => (
  <Cell>
    <span className="ae-typography-body2 truncate font-bold text-base-content/60">
      {filename}
    </span>
  </Cell>
);

const NumberOfLeads = ({ numLeads }: { numLeads?: number }) => (
  <Cell>
    <span className="ae-typography-body2 truncate text-base-content/60">
      {numLeads}
    </span>
  </Cell>
);

const CampaignName = ({ campaignName }: { campaignName?: string }) => (
  <Cell>
    <span className="ae-typography-body2 truncate text-base-content/60">
      {campaignName}
    </span>
  </Cell>
);

const UploadDate = ({ date }: { date?: string }) => (
  <Cell>
    <span className="ae-typography-body2 truncate text-base-content/60">
      {safeGetDate(date, DATE_FORMAT_SHORT)}
    </span>
  </Cell>
);

const TableRow = ({ data }: { data: IngestionI }) => (
  <>
    <FileName filename={data.name} />
    <NumberOfLeads numLeads={data.eligible_contacts_count} />
    <CampaignName campaignName={data.campaign_name} />
    <UploadDate date={data.created_at} />
  </>
);

const TableHeader = () => (
  <>
    {TABLE_HEADERS.map((header, idx) => (
      <div
        key={idx}
        className={clsx(
          "p-4",
          "text-left text-base-content/40",
          "border-b border-black/10",
          "ae-typography-body2"
        )}
      >
        {header}
      </div>
    ))}
  </>
);

interface UploadLeadsSidebarPastUploadsTableProps extends PropsWithClassNameI {
  ingestions?: IngestionI[];
}

export const UploadLeadsSidebarPastUploadsTable: FC<
  UploadLeadsSidebarPastUploadsTableProps
> = ({ className, ingestions }) => {
  if (!ingestions || !ingestions.length) {
    return null;
  }

  return (
    <div className={clsx("pb-20", className)}>
      <p className="b-typography-h5 mb-4">Past uploads</p>

      <section>
        <div
          className={clsx(
            "grid w-full grid-cols-[30%_17%_23%_30%]",
            "rounded-xl border-2",
            "overflow-auto",
            "max-h-[240px]"
          )}
        >
          <TableHeader />

          {ingestions?.map((ingestion, i) => (
            <TableRow key={i} data={ingestion} />
          ))}
        </div>
      </section>
    </div>
  );
};
