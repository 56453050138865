import { FC } from "react";

import { FilterTimezones } from "./timezones-filter";
import { FilterAvailableToCall } from "./available-to-call-filter";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

export const LeadListsTableFiltersMenu: FC<PropsWithClassNameI> = ({
  className,
}) => {
  const { filters, onChangeFilters } = useListsContext();

  const handleOnChangeAvailableToCall = (filterAvailableToCall: boolean) => {
    onChangeFilters?.((filters) => ({
      ...filters,
      filter_available_to_call: filterAvailableToCall,
    }));
  };

  const handleOnChangeTimezones = (filterTimezones: string[]) => {
    onChangeFilters?.((filters) => ({
      ...filters,
      timezone_filters: filterTimezones,
    }));
  };

  return (
    <div
      className={clsxMerge(
        "absolute right-2 top-[80%] z-50",
        "w-[400px]",
        "rounded-md border border-[#F1F5F9]",
        "animate-fadein bg-white shadow-[0px_0px_4px_2px_rgba(0,0,0,0.06)]",
        className
      )}
    >
      <div className="p-3 pb-1 typography-body-4-bold">Filter by</div>

      <div className="divider m-0" />

      <div className="flex flex-col gap-1 p-3 pt-0">
        <FilterAvailableToCall
          value={filters?.filter_available_to_call}
          onChange={handleOnChangeAvailableToCall}
        />

        <FilterTimezones
          value={filters?.timezone_filters}
          onChange={handleOnChangeTimezones}
        />
      </div>
    </div>
  );
};
