import { FC } from "react";

import { getLogoPath } from "./utils";
import { LogoPropsI } from "./interfaces";

export const Logo: FC<LogoPropsI> = ({
  variation,
  width,
  className,
  href,
  fallbackRoute,
}) => (
  <a href={href || fallbackRoute} className={className}>
    <img
      src={getLogoPath(variation)}
      alt="Glencoco Logo"
      width={width || "89px"}
    />
  </a>
);
