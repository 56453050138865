import { FC, useEffect, useState } from "react";
import { UserIcon } from "@heroicons/react/24/outline";

import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";
import { ToggleField } from "shared/ui/ae-user-input";
import { clsxMerge } from "shared/lib/helpers";

const SimpleToggle = ToggleField();

type FilterAvailableToCallPropsI = ControllableComponentPropsI<boolean>;

export const FilterAvailableToCall: FC<FilterAvailableToCallPropsI> = ({
  value,
  onChange,
}) => {
  const { isEnabled } = useDialerGlobalContext();
  const [selected, setSelected] = useState<boolean>(value || false);

  const handleChange = () => {
    onChange?.(!selected);

    setSelected(!selected);
  };

  useEffect(() => {
    setSelected(!!value);
  }, [value]);

  return (
    <div
      className={clsxMerge("flex items-center justify-between", {
        "pointer-events-none": isEnabled,
      })}
      onClick={handleChange}
    >
      <span className="flex gap-2">
        <UserIcon className="h-4 w-4" />
        <span className="typography-body-4">Available to call</span>
      </span>

      <SimpleToggle
        checked={selected}
        inputClassName="ml-2"
        inputProps={{ readOnly: true }}
      />
    </div>
  );
};
