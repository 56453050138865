import { FC, Fragment } from "react";

import { ControlledDropdown } from "shared/ui";
import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/primary-navigation/v3/interfaces";
import { clsxMerge } from "shared/lib/helpers";
import { NavButtonPrimary } from "@/components/primary-navigation/v3/nav-bar/nav-items-row/components/nav-button-primary";
import { NavDropdownMenuItem } from "@/components/primary-navigation/v3/nav-bar/nav-items-row/components/nav-dropdown-menu-item";
import { CALL_FORWARDING_ITEM_ID } from "@/components/primary-navigation/v3/constants";

interface NavDropdownPropsI {
  navItem: PrimaryNavigationItemCollapsableWithIconI;
  dropdownClassName?: string;
  disabled?: boolean;
  isBtnIconOnly?: boolean;
}

// dropdown menu items that should have the grey separator line below them
const DROPDOWN_ITEM_IDS_WITH_DIVIDER: string[] = [CALL_FORWARDING_ITEM_ID];

/**
 * Dropdown component for items in the nav bar that have a sub menu
 * @param navItem data for the nav item
 * @param dropdownClassName classNames for the dropdown sub menu component
 * @param disabled whether the dropdown is disabled
 * @param isBtnIconOnly whether the dropdown button should only show the icon
 * @returns
 */
export const NavDropdown: FC<NavDropdownPropsI> = ({
  navItem,
  dropdownClassName,
  disabled,
  isBtnIconOnly,
}) => (
  <ControlledDropdown
    className={clsxMerge(
      "dropdown-bottom flex h-full items-center",
      { "cursor-not-allowed opacity-60": disabled },
      dropdownClassName
    )}
    closeOnContentClick={true}
  >
    {() => (
      <>
        {/* DROPDOWN TRIGGER */}
        <div
          tabIndex={0}
          className={clsxMerge("flex h-full items-center", {
            "pointer-events-none": disabled,
          })}
        >
          <NavButtonPrimary navItem={navItem} isBtnIconOnly={isBtnIconOnly} />
        </div>

        {/* DROPDOWN CONTENT */}
        <div tabIndex={0} className="dropdown-content min-w-[220px]">
          {navItem?.children?.length && (
            <ul className="rounded-box menu-compact w-full bg-white p-2 shadow-lg">
              {navItem.children.map((subItem, idx) => (
                <Fragment key={idx}>
                  <li>
                    <NavDropdownMenuItem
                      subItem={subItem}
                      disabled={disabled}
                    />
                  </li>

                  {DROPDOWN_ITEM_IDS_WITH_DIVIDER.includes(
                    subItem.id as string
                  ) && <div className="divider my-0" />}
                </Fragment>
              ))}
            </ul>
          )}
        </div>
      </>
    )}
  </ControlledDropdown>
);
