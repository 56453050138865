import clsx from "clsx";

import LeadDescription from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/preview-lead-card/lead-description";
import { IngestionFieldsI } from "@/interfaces/ingestion";
import { INGESTION_TYPES } from "@/constants/ingestion";

export const PreviewIngestionSupressionCard = ({
  phone = "-",
  email = "-",
}: IngestionFieldsI & {
  config?: {
    ingestionType?: (typeof INGESTION_TYPES)[keyof typeof INGESTION_TYPES];
  };
}) => (
  <div className="sticky top-4 min-h-[280px] w-[400px]">
    <div className="card rounded-[8px] border border-black/5 p-4">
      <div className={clsx("rounded-lg bg-black/5 px-3 py-6")}>
        <LeadDescription phone={phone} email={email} hideAccount />
      </div>
    </div>
    <p className="ae-typography-detail1 mt-3 font-normal text-base-content/60">
      This is the lead information we will suppress from being contacted
    </p>
  </div>
);
