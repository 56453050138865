import { useEffect, useState } from "react";

import { CustomFieldI } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/interfaces";
import { SearchSelectField } from "shared/ui/ae-user-input";
import { TextField } from "shared/ui/ae-user-input";
import { CustomSelectFieldOptionI } from "shared/ui/ae-user-input/search-select-field";

import { CloseIcon } from "shared/ui/icons";

const SimpleTextField = TextField();

const CustomField = ({
  value,
  crmValue,
  options = [],
  onChange = () => {},
  onCrmValueChange = () => {},
  onRemove = () => {},
}: {
  value?: string;
  crmValue?: string;
  options?: Array<CustomSelectFieldOptionI>;
  onChange?: (v: string) => void;
  onCrmValueChange?: (v: string) => void;
  onRemove?: () => void;
}) => {
  return (
    <div className="relative mb-8 grid grid-cols-2 gap-x-8">
      <div>
        <SimpleTextField
          className="mb-0"
          inputProps={{
            value: value || "",
            onChange: (v: any) => onChange(v),
          }}
          placeholder="Define custom column name"
        />
      </div>
      <div>
        <SearchSelectField
          placeholder="Select from dropdown"
          selectedValue={crmValue || ""}
          options={options}
          onChange={(v) => onCrmValueChange(v)}
          isCaseInSensitive
        />
      </div>
      <div className="absolute -right-10 top-2">
        <div
          className="btn btn-circle btn-ghost btn-sm"
          onClick={() => onRemove()}
        >
          <CloseIcon className="h-5 w-5" />
        </div>
      </div>
    </div>
  );
};

const CustomFieldsManager = ({
  options,
  max,
  onChange = () => {},
}: {
  options?: Array<CustomSelectFieldOptionI>;
  max?: number;
  onChange?: (customFields: Array<CustomFieldI>) => void;
}) => {
  const [customFields, setCustomFields] = useState<Array<CustomFieldI>>([{}]);

  useEffect(() => {
    onChange(customFields);
  }, [customFields]);

  const handleGcFieldChange = (i: number) => (e: any) => {
    const updateCustomField = customFields[i];

    updateCustomField.gcFieldLabel = e?.target?.value;
    updateCustomField.gcFieldValue = updateCustomField.gcFieldLabel;

    customFields[i] = updateCustomField;

    setCustomFields([...customFields]);
  };

  const handleCrmFieldChange = (i: number) => (selectedValue: string) => {
    const updateCustomField = customFields[i];

    updateCustomField.crmFieldValue = selectedValue;

    setCustomFields([...customFields]);
  };

  const handleAdd = () => {
    setCustomFields([...customFields, {}]);
  };

  const handleRemove = (i: number) => () => {
    customFields.splice(i, 1);
    setCustomFields([...customFields]);
  };

  return (
    <div>
      <div>
        {customFields?.map((customField, i) => (
          <CustomField
            key={i}
            onChange={handleGcFieldChange(i)}
            value={customField.gcFieldLabel}
            options={options}
            crmValue={customField.crmFieldValue}
            onCrmValueChange={handleCrmFieldChange(i)}
            onRemove={handleRemove(i)}
          />
        ))}
      </div>

      <div>
        {(typeof max !== "number" || max > customFields.length) && (
          <button className="btn-nofill w-full" onClick={handleAdd}>
            + Map more useful signals to show on contact card
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomFieldsManager;
