/**
 * This represents a component that is used as a portal container for the list
 * actions. We "portal" actions to this container to avoid complexity in
 * refactoring the current lists structure and a complex dynamic logic to
 * calculate the top offset of the actions container depending on list description
 * content length.
 */
export const LIST_ACTIONS_SLOT_ID = "list-actions-slot";

export const LIST_HEADER_AND_ACTIONS_CONTAINER_ID =
  "list-header-and-actions-container";

export const LIST_TABLE_HEADER_CELL_CLASSNAME = "list-table-header-cell";
