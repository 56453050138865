import { useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { glencocoClientAPI } from "@/api/glencoco";
import { webAIClientStreamAPI } from "@/api/webai";
import {
  CreateEmailDraftRequestParamsI,
  CreateEmailTemplateRequestParamsI,
  GenerateAIEmailRequestParamsI,
  GenerateEmailExampleRequestParamsI,
  SendEmailRequestParamsI,
  UpdateEmailTemplateRequestParamsI,
} from "@/api/routes/email";
import { GenerateEmailStreamRequestParamsI } from "@/api/routes/email-webai";

export const EMAIL_TEMPLATES_LIST_QUERY_KEY = "email_templates_list";

// Get all lists
export const useFetchEmailTemplatesList = () => {
  const listApi = useQuery({
    queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
    queryFn: glencocoClientAPI().email.getEmailTemplatesList,
  });

  useEffect(() => {
    if (listApi.isError) {
      toast.error(
        "Failed to load list of templates, please try reloading the page."
      );
    }
  }, [listApi.isError]);

  return listApi;
};

export const useCheckCampaignHasEmailAutomation = (campaignId?: string) =>
  useQuery({
    queryKey: ["campaign_email_automation_check"],
    queryFn: () =>
      campaignId
        ? glencocoClientAPI().email.checkCampaignHasEmailAutomation(campaignId)
        : null,
  });

// generates a draft for the actual email to be sent (returns an id that is needed for submitting the email later)
export const useCreateEmailDraft = () => {
  return useMutation({
    mutationFn: ({
      parameters,
    }: {
      parameters: CreateEmailDraftRequestParamsI;
    }) => glencocoClientAPI().email.createEmailDraft(parameters),
    onError: () => {
      toast.error("Failed to generate email draft.");
    },
  });
};

// generates a draft for the actual email to be sent (returns an id that is needed for submitting the email later)
export const useGenerateAIEmail = () => {
  return useMutation({
    mutationFn: ({
      templateId,
      parameters,
    }: {
      templateId: string;
      parameters: GenerateAIEmailRequestParamsI;
    }) => glencocoClientAPI().email.generateAIEmail(templateId, parameters),
    onError: () => toast.error("Failed to generate AI email."),
  });
};

// generates an example output of the email based on the prompt provided (is just an example for the user to see and isn't maintained anywhere)
export const useGenerateEmailExample = () => {
  return useMutation({
    mutationFn: (parameters: GenerateEmailExampleRequestParamsI) =>
      glencocoClientAPI().email.generateEmailExample(parameters),
    onError: () => toast.error("Failed to generate example"),
  });
};

export const useGenerateAIEmailStreamBody = () => {
  return useMutation({
    mutationFn: ({
      parameters,
    }: {
      parameters: GenerateEmailStreamRequestParamsI;
    }) => webAIClientStreamAPI().streamEmailBody(parameters),
    onError: () => toast.error("Failed to generate AI email."),
  });
};

export const useGenerateAIEmailStreamSubject = () => {
  return useMutation({
    mutationFn: ({
      parameters,
    }: {
      parameters: GenerateEmailStreamRequestParamsI;
    }) => webAIClientStreamAPI().streamEmailSubject(parameters),
    onError: () => toast.error("Failed to generate AI email."),
  });
};

export const useGetEmailGenerationStatus = () => {
  return useMutation({
    mutationFn: (emailId: string) =>
      glencocoClientAPI().email.getEmailGenerationStatus(emailId),
    onError: () => toast.error("Failed to get email generation status."),
  });
};

export const useValidateEmails = () => {
  return useMutation({
    mutationFn: (emails: string[]) =>
      glencocoClientAPI().email.validateEmails({ emails }),
  });
};

export const useSendEmail = () => {
  return useMutation({
    mutationFn: ({
      emailId,
      parameters,
    }: {
      emailId: string;
      parameters: SendEmailRequestParamsI;
    }) => glencocoClientAPI().email.sendEmail(emailId, parameters),
    onError: () => {
      toast.error("Failed to send email");
    },
  });
};

// List CRUD
export const useCreateEmailTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (parameters: CreateEmailTemplateRequestParamsI) =>
      glencocoClientAPI().email.createEmailTemplate(parameters),
    onSuccess: (_, { name }) => {
      toast.success(`Template '${name}' has been created!`);

      queryClient.invalidateQueries({
        queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to create new template."),
  });
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      templateId,
      params,
    }: {
      templateId: string;
      params: UpdateEmailTemplateRequestParamsI;
    }) => glencocoClientAPI().email.updateEmailTemplate(templateId, params),
    onSuccess: () => {
      toast.success("List details have been updated!");

      queryClient.invalidateQueries({
        queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to update template."),
  });
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (templateId: string) =>
      glencocoClientAPI().email.deleteEmailTemplate(templateId),
    onSuccess: () => {
      toast.success("Template removed.");
      queryClient.invalidateQueries({
        queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to remove template."),
  });
};
