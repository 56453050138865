import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "@/components/shared/widgets/constants";
import { WIDGETS } from "@/constants/widgets";
import { GENERAL_FORUM_POST_TYPES } from "shared/lib/constants/forum";

export const QUERY_PARAMS = {
  LIST: "list",
  NEW_LEAD: "new_lead",
} as const;

const CUSTOMER_DOMAIN = process.env.NEXT_PUBLIC_CUSTOMER_DOMAIN;

const Router = {
  home: () => "/",
  dashboard: () => "/dashboard",
  verify: () => "/verify",
  unauthorized: () => "/unauthorized",
  coaching: () => "/coaching",
  meetings: () => "/meetings",
  callHistory: () => "/history",
  messages: () => "/messages",
  emailInbox: () => "/email-inbox",
  performanceDashboard: () => "/performance",
  leaderboard: () => "/leaderboard",
  trophies: () => "/trophies",
  // NOTE in stack overflow people ask how to auth0 logout without context
  //      help them and tell about the workaround
  profileInactive: () => "/profile-inactive",
  logout: () => "/logout",
  signup: () => "/signup",
  session: (campaignId = "") =>
    campaignId ? `/session/${campaignId}` : "/session",
  settingsProfile: () => "/settings/profile",
  settingsCallForwarding: () => "/settings/call-forwarding",
  publicProfile: () => "/settings/profile/public",
  settingsPayouts: () => "/settings/payouts",
  settingsAvailability: () => "/settings/availability",
  settingsReferrals: () => "/settings/referrals",
  marketplace: () => "/discover",
  marketplaceCampaign: (campaignId = "", query = "") =>
    encodeURI(`/discover/${campaignId}${query ? `?${query}` : ""}`),
  training: () => "/training",
  trainingModule: (trainingModuleId = "") => `/training/${trainingModuleId}`,

  lists: (listId?: string) =>
    `/lists${Router.searchQueryParams.listId(listId)}`,

  accountDetailsResources: (campaignId: string, accountId: string) =>
    `/accounts/${campaignId}/${accountId}?tab=resources`,

  accountDetailsMessages: (campaignId: string, accountId: string) =>
    `/accounts/${campaignId}/${accountId}?tab=messages`,

  bonuses: () =>
    `https://help.glencoco.com/en/articles/8876957-glencoco-top-callers-reward-program`,

  customerCampaignBillingSettings: (campaignID: string) =>
    `${CUSTOMER_DOMAIN}/campaign/${campaignID}/billing`,

  createNewLead: () => `${Router.lists()}?${QUERY_PARAMS.NEW_LEAD}=1`,
  beginAssessment: (campaignId = "") => `/assessment/${campaignId}`,
  salesFloor: () => `/sales-floor`,
  salesFloorGeneralByCategory: (category?: string) =>
    category ? `/sales-floor/general/${category}` : `/sales-floor`,
  salesFloorGeneralByCategoryPost: (category?: string, postId?: string) =>
    category
      ? `/sales-floor/general/${category}/${postId}`
      : `/sales-floor/general/${GENERAL_FORUM_POST_TYPES.DEFAULT}/${postId}`,
  salesFloorByCampaign: ({
    campaignId = "",
    category,
  }: {
    campaignId: string;
    category?: string;
  }) => `/sales-floor/campaign/${campaignId}${category ? `/${category}` : ""}`,

  salesFloorByCampaignPost: ({
    campaignId = "",
    postId = "",
    category,
  }: {
    campaignId: string;
    postId: string;
    category?: string;
  }) =>
    `/sales-floor/campaign/${campaignId}/${
      category ? `${category}/` : ""
    }${postId}`,

  searchQueryParams: {
    accountDetails: (campaignId: string, accountId: string) =>
      new URLSearchParams({
        [WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE]: WIDGETS.MAXED_DIALER,
        [WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID]: accountId,
        [WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID]: campaignId,
      }).toString(),
    listId: (listId?: string) =>
      listId ? `?${new URLSearchParams({ list: listId }).toString()}` : "",
  },
};

export default Router;
