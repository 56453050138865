import { SortingState } from "@tanstack/react-table";
import { SortRequestI } from "shared/lib/interfaces/api";

import isEmpty from "lodash/isEmpty";
import { ListTableColumnI } from "@/modules/pipeline/lists/list/workspace/table/interfaces";

export const transformSortingStateToRequestParams = <
  SortByStringUnion extends ListTableColumnI
>(
  sortingState: SortingState
) => {
  if (sortingState.length === 0) {
    return {};
  }

  // Tanstack Table supports multiple sorting columns, but for our real business
  // cases we only need to sort by one column at a time. We can extend it to
  // cover multiple columns if needed.
  const [sortConfig] = sortingState;

  return {
    sort_by: sortConfig.id,
    sort_order: sortConfig.desc ? "desc" : "asc",
  } as SortRequestI<SortByStringUnion>;
};

export const transformRequestParamsToSortingState = <
  SortByStringUnion extends string
>(
  requestParams: SortRequestI<SortByStringUnion>
): SortingState => {
  if (!requestParams || isEmpty(requestParams)) {
    return [];
  }

  return [
    {
      id: requestParams?.sort_by as string,
      desc: (requestParams?.sort_order as string) === "desc",
    },
  ];
};
