export const HOMEPAGE_LAYOUT_MAIN_CONTENT_CONTAINER_ID =
  "page-main-content-container";

export const PRIMARY_NAVIGATION_V3_CONTAINER_ID = "primary-navigation-v3";

export const PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID =
  "primary-navigation-content-container";

export const LISTS_ADD_LEAD_MENU_ID = "add-leads-button-menu";

export const LISTS_START_CALLING_BUTTON_ID = "start-calling-button";

export const LISTS_ACCOUNT_DETAILS_SUMMARY_ID = "account-details-summary";

export const LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID =
  "account-details-submit-disposition-buttons";

export const ACCOUNT_DETAILS_CONTENT_ID = "account-details-content";
