import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import {
  useFetchListDetails,
  useSearchContacts,
} from "@/modules/pipeline/lists/list/queries/queries";
import { useMemo } from "react";

export const useListWorkspace = ({
  listId,
  searchTerm,
}: {
  listId: string;
  searchTerm?: string;
}) => {
  const { isEnabled } = useDialerGlobalContext();

  const searchContactsAPI = useSearchContacts(searchTerm);

  const isSearchMode = useMemo(
    () =>
      !!searchTerm &&
      (searchContactsAPI.isSuccess || searchContactsAPI.isFetching),
    [searchTerm, searchContactsAPI]
  );

  const { data: response, isPending } = useFetchListDetails(listId as string, {
    isSearchMode,
    isPolling: isEnabled,
  });

  return useMemo(
    () => ({ isSearchMode, searchContactsAPI, response, isPending }),
    [isSearchMode, searchContactsAPI, response, isPending]
  );
};
