import { FC, useMemo } from "react";
import { useRouter } from "next/router";

import { clsxMerge } from "shared/lib/helpers";
import { CloseIcon } from "shared/ui/icons";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import Router from "@/helpers/router";

import { useLazyModal } from "shared/lib/hooks";
import { ConfirmRemoveContactsFromList } from "@/modals/lists/confirm-remove-contacts-from-list";
import { useRemoveListFromAccount } from "@/api/routes/account/mutations";
import { WIDGETS, widgets } from "@/widgets/index";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { getListName } from "@/modules/pipeline/utils";
import { getListIcon } from "@/modules/pipeline/lists/list/primary-header/selector/utils";
import { AccountUserListI } from "shared/lib/interfaces/account";
import { LIST_TYPES } from "@/modules/pipeline/lists/list/constants";

interface AccountDetailsV2ListItemPropsI {
  className?: string;
  listData: AccountUserListI;
  campaignId?: string;
  accountId?: string;
}

const CONFIRM_REMOVE_LIST_FROM_ACCOUNT = "confirm-remove-list-from-account";

export const AccountDetailsV2ListItem: FC<AccountDetailsV2ListItemPropsI> = ({
  className,
  listData,
  campaignId,
  accountId,
}) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const { callStatus } = useAccountDetailsContext();

  const router = useRouter();
  const { onAccountUpdate } = useAccountDetailsContext();

  const { mutateAsync: removeListFromAccount } = useRemoveListFromAccount();

  const { isOpen, onOpen, onClose } = useLazyModal(
    CONFIRM_REMOVE_LIST_FROM_ACCOUNT
  );

  const isExclusive = listData?.list_type === LIST_TYPES.EXCLUSIVE;

  const listName = useMemo(() => getListName(listData), [listData]);

  /**
   * NOTE
   * User is not allowed to close widget before submits disposition
   * OR
   * While dialing list session is active
   */
  const isAllowedToOpen = useMemo(
    () => !isGlobalDialer && callStatus === DIALER_CALL_STATUS.BEFORE,
    [isGlobalDialer, callStatus]
  );

  const handleOpenList = () => {
    if (isAllowedToOpen) {
      router.push(Router.lists(listData.list_id));
      widgets.close({ id: WIDGETS.MAXED_DIALER });
    }
  };

  const handleRemove = async () => {
    if (!campaignId || !accountId) {
      return;
    }

    await removeListFromAccount(
      {
        campaignId,
        accountId,
        listId: listData.list_id as string,
      },
      {
        onSuccess: () => {
          onClose();
          onAccountUpdate();
        },
      }
    );
  };

  const handleConfirmRemoval = () => {
    handleRemove();
  };

  return (
    <div
      title={listName}
      // An external wrapper serving to create a border gradient effect
      className={clsxMerge(
        "relative w-fit rounded-lg border-2",
        {
          "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]":
            isExclusive,

          // [DIALING_BADGE_CLASSES]: isDialing,
        },

        className
      )}
    >
      <div
        className={clsxMerge(
          "bg-white",
          "relative",
          "flex w-fit max-w-[260px] items-center",
          "cursor-pointer gap-2 transition-colors",
          "rounded-md p-2",
          "pr-8",
          {
            "hover:bg-[rgb(247,247,247)]": isAllowedToOpen,
            "bg-white": isExclusive,
          }
        )}
        onClick={handleOpenList}
      >
        <span className="text-[#F88167]">
          {listData.list_type &&
            getListIcon({
              list_type: listData.list_type,
              isExclusive,
            })}
        </span>

        <p
          className={clsxMerge("b-typography-detail2 truncate", {
            "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent":
              isExclusive,
          })}
        >
          {listName}
        </p>

        <button
          className={clsxMerge(
            "btn btn-circle btn-ghost btn-xs",
            "absolute right-1"
          )}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <CloseIcon className="w-4 min-w-4 cursor-pointer rounded-full transition-colors" />
        </button>
      </div>

      {isOpen && (
        <ConfirmRemoveContactsFromList
          id={CONFIRM_REMOVE_LIST_FROM_ACCOUNT}
          title={`Remove these leads from the list ${listName}?`}
          onConfirm={handleConfirmRemoval}
          onCancel={onClose}
          onClose={onClose}
        />
      )}
    </div>
  );
};
