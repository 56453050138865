import { Container } from "@/components/base/container";

export const FieldsMappingProgressState = () => (
  <div>
    <Container>
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mb-9">
          <h1 className="ae-typography-h2 mb-3">Mapping your fields...</h1>
          <p className="ae-typography-body1">
            Feel free to move away from the page, as we will notify you when
            this step is complete
          </p>
        </div>

        <div className="w-[500px]">
          <progress className="progress w-full after:!bg-[#014DFE]"></progress>
        </div>
      </div>
    </Container>
  </div>
);
