export const DIALER_STATES = {
  PRE: "pre",
  LEAD_SELECTION: "lead_selection",
  DIALING: "dialing",
  CALL: "call",
  DISPOSITION: "disposition",
  POST: "post",

  LOADING: "loading",
  UNAUTHORIZED: "unauthorized",
};

export const DIALER_CALL_STATUS = {
  BEFORE: "before",
  DURING: "during",
  AFTER: "after",
} as const;

export const DIALER_MODES = {
  /**
   * DEFAULT mode is a mode used in the v1 dialer context
   * and it means NOT A DEMO mode
   *
   * We will refactor this when we fully migrate to dialer v2
   */
  DEFAULT: "default",
  /**
   * DEMO mode is a mode used in the v1 dialer context
   * and it is used in the embed page that is on Landing portal.
   * This is basically limited funcionality of the dialer with guides
   *
   * We will refactor this when we fully migrate to dialer v2
   */
  DEMO: "demo",
  /**
   * AUTODIAL mode is when caller continuosly get new leads as soon as
   * previous call is dispositioned
   *
   */
  AUTODIAL: "auto-dial",
  /**
   * TARGETED mode is when caller is able to trigger every call manualy
   * after reviewing prospect info
   *
   */
  TARGETED: "targeted",
  /**
   * WIDGET_MINI_DIALER mode is embeddable version of the dialer
   * primarily used to do direct calls on the pipeline view
   *
   */
  WIDGET_MINI_DIALER: "widget_mini-dialer",

  /**
   * WIDGET_MAXED_DIALER mode is a sidebar with account details
   * where user is able to start direct calls or iterate over
   * dialing list
   */
  WIDGET_MAXED_DIALER: "widget_maxed_dialer",
} as const;

export const DIALER_LIVE_TRANSFER_STATUSES = {
  CALENDLY_BOOKING_COMPLETE: "calendly_booking_complete",
  LIVE_TRANSFER_COMPLETE: "live_transfer_complete",
} as const;

export const DIALER_VERSIONS = {
  V1: "v1",
  V2: "v2",
} as const;

export const DIALER_WIDGET_VARIATIONS = {
  DEFAULT: "default",
  SIDEBAR: "sidebar",
} as const;

/**
 * Used in pipeline lists dialer mode for pills
 */
export const DIALER_LIST_DAILING_STATUS = {
  //Call in progress
  DIALING: "dialing",
  CONNECTED: "connected",

  //Successfull state after the call
  HAD_CONVERSATION: "had_conversation",
  CALLBACK_BOOKED: "callback_booked",
  MEETING_BOOKED: "booked_meeting",

  //Didn't connect state
  VM_DETECTED: "voicemail_detected",
  BAD_NUMBER: "bad_number",
  NO_ANSWER: "no_answer",
  BUSY: "busy",
  CANCELLED: "cancelled",
  IGNORED: "ignored",

  //No UI statuses
  QUEUED: "queued",
  NO_STATUS: "no_status",

  //UI only
  TIMEOUT: "timeout",
} as const;

/**
 * Used in pipeline lists dialer mode for pills
 */
export const DIALER_LIST_DAILING_STATUS_MAP = {
  [DIALER_LIST_DAILING_STATUS.DIALING]: "Dialing",
  [DIALER_LIST_DAILING_STATUS.CONNECTED]: "Connected",

  [DIALER_LIST_DAILING_STATUS.HAD_CONVERSATION]: "Was Connected",
  [DIALER_LIST_DAILING_STATUS.CALLBACK_BOOKED]: "Callback set",
  [DIALER_LIST_DAILING_STATUS.MEETING_BOOKED]: "Meeting booked",

  [DIALER_LIST_DAILING_STATUS.VM_DETECTED]: "VM detected",
  [DIALER_LIST_DAILING_STATUS.BAD_NUMBER]: "Bad number",
  [DIALER_LIST_DAILING_STATUS.BUSY]: "Busy",
  [DIALER_LIST_DAILING_STATUS.NO_ANSWER]: "No answer",
  [DIALER_LIST_DAILING_STATUS.CANCELLED]: "Cancelled",
  [DIALER_LIST_DAILING_STATUS.IGNORED]: "Cancelled",
  [DIALER_LIST_DAILING_STATUS.TIMEOUT]: "Timed out",
} as const;
