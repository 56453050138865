import { clsxMerge } from "shared/lib/helpers";
import { Logo } from "shared/ui/logo";
import { LOGO_VARIATION } from "shared/ui/logo/constants";

import { PRIMARY_NAVIGATION_V3_CONTAINER_ID } from "@/constants/element-ids";
import { NavItemsRow } from "@/components/primary-navigation/v3/nav-bar/nav-items-row";
import {
  NAV_V3_ITEMS_PRIMARY,
  NAV_V3_ITEMS,
  NAV_GROUP_NAMES,
} from "@/components/primary-navigation/v3/constants";
import { NotificationsButton } from "@/components/primary-navigation/shared/notifications-button";
import { TodayPerformanceButton } from "@/components/primary-navigation/shared/today-performance-button";
import { NavButtonPrimary } from "./nav-items-row/components/nav-button-primary";
import Router from "@/helpers/router";

/**
 * @returns the black navbar that goes along the top of the app UI.
 */
export const PrimaryNavigationV3NavBar = () => (
  <div
    id={PRIMARY_NAVIGATION_V3_CONTAINER_ID}
    className={clsxMerge(
      "navbar z-30 justify-between bg-black p-0",
      "w-screen min-w-[800px]"
    )}
  >
    <div className="navbar-start h-full">
      <div className="shrink-0">
        <Logo
          className="block px-10"
          variation={LOGO_VARIATION.GLENX}
          width="68px"
          href={Router.home()}
        />
      </div>

      <NavButtonPrimary
        navItem={NAV_V3_ITEMS[NAV_GROUP_NAMES.LEADS]}
        containerClassName="mr-1 xl:mr-2"
      />

      <NavButtonPrimary
        navItem={NAV_V3_ITEMS[NAV_GROUP_NAMES.EMAILS]}
        containerClassName="mr-1 xl:mr-2"
      />

      <NavItemsRow data={NAV_V3_ITEMS_PRIMARY} />
    </div>

    <div className="navbar-end sticky right-0 z-20 h-full w-fit">
      <div className="flex h-full items-center gap-1 xl:gap-2">
        <TodayPerformanceButton />
        <NotificationsButton />
      </div>

      <NavItemsRow
        data={[NAV_V3_ITEMS[NAV_GROUP_NAMES.SETTINGS]]}
        className="xl:mx-1"
        dropdownClassName="dropdown-end"
      />
    </div>
  </div>
);
