import { FC } from "react";

import Modal from "shared/ui/modal";
import { clsxMerge } from "shared/lib/helpers";
import { Logo } from "shared/ui/logo";
import { LOGO_VARIATION } from "shared/ui/logo/constants";

export const LIST_TOUR_MODAL_ID = "list-tour-modal";

interface ListTourModalPropsI {
  onEnterTour: () => void;
}

const INFO_SECTIONS = [
  {
    title: "Lead lists",
    description:
      "Import and manage your leads with intuitive disposition features",
  },
  {
    title: "Dialer",
    description: "Leverage both manual and auto-dialer options",
  },
  {
    title: "Cold & nurture leads",
    description:
      "How you disposition your calls places your leads into simple to track buckets for future outerach",
  },
];

export const ListTourModal: FC<ListTourModalPropsI> = ({ onEnterTour }) => (
  <Modal
    id={LIST_TOUR_MODAL_ID}
    modalBoxClassName="w-[500px] rounded-md"
    closeIconClassName="text-black/40"
  >
    {({ handleClose }) => {
      const handleEnterTour = () => {
        handleClose();
        onEnterTour();
      };

      return (
        <div className="grid grid-cols-7 gap-3">
          <div
            className={clsxMerge(
              "col-span-3 flex h-[330px] flex-col-reverse",
              "rounded-xl bg-[linear-gradient(180deg,#CC3997_43.09%,#4B61AB_95.75%)] px-6 pb-16"
            )}
          >
            <div className="flex flex-col gap-2">
              <Logo variation={LOGO_VARIATION.GLENX_FULL_WHITE} />
              <p className="text-[#E2E8F0] typography-body-4">
                Get to know glenX, the world's first AI native sales cockpit
              </p>
            </div>
          </div>

          <div className="col-span-4 flex flex-col gap-3">
            {INFO_SECTIONS.map(({ title, description }, index) => (
              <div
                key={index}
                className={clsxMerge(
                  "flex flex-col p-3",
                  "animate-enter-from-bottom fill-mode-backwards",
                  `delay-${index * 100}`
                )}
              >
                <h5 className="text-black typography-body-4-medium">{title}</h5>
                <p className="text-[#64748B] typography-body-4">
                  {description}
                </p>
              </div>
            ))}

            <button
              className="btn-ae-default mt-4 self-end"
              onClick={handleEnterTour}
            >
              Take tour
            </button>
          </div>
        </div>
      );
    }}
  </Modal>
);
