import { SYSTEM_LIST_TYPES } from "@/modules/pipeline/lists/list/constants";
import {
  CalendarIcon,
  CheckIcon,
  EnvelopeIcon,
  PhoneArrowDownLeftIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";

export const LIST_STATUS_PILL_VARIANTS = {
  [SYSTEM_LIST_TYPES.CALLBACKS]: {
    icon: PhoneArrowDownLeftIcon,
    color: "text-white",
    bg: "bg-[#F8A467]",
  },
  [SYSTEM_LIST_TYPES.MISSED_CALLS]: {
    icon: XMarkIcon,
    color: "text-[#B90000]",
    bg: "bg-[#FEDFDF]",
  },
  [SYSTEM_LIST_TYPES.BOOKED_MEETINGS]: {
    icon: CalendarIcon,
    color: "text-white",
    bg: "bg-[#F86767]",
  },
  [SYSTEM_LIST_TYPES.EMAIL_ACTIONS]: {
    icon: EnvelopeIcon,
    color: "text-[#3B6E29]",
    bg: "bg-[#EDFFDF]",
  },
  [SYSTEM_LIST_TYPES.FEEDBACK_RECEIVED]: {
    icon: CheckIcon,
    color: "text-[#124011]",
    bg: "bg-[#C3FBB5]",
  },
} as const;
