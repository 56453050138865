import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

import {
  IngestionFieldAIMapping,
  IngestionFieldMapItemI,
} from "@/interfaces/ingestion";
import { useGlobalContext } from "@/hooks/use-global-context";
import { SubmitIngestionFieldsRequestI } from "@/api/routes/ingestions";

interface UploadLeadsSidebarContextI {
  ingestionId: string | null;
  setIngestionId: Dispatch<SetStateAction<string | null>>;

  selectedCampaignId: string | null | undefined;

  selectedListId: string | null;
  setSelectedListId: Dispatch<SetStateAction<string | null>>;

  ingestionFields: IngestionFieldMapItemI[];
  setIngestionFields: Dispatch<SetStateAction<IngestionFieldMapItemI[]>>;

  ingestionAIMappings: IngestionFieldAIMapping[];
  setIngestionAIMappings: Dispatch<SetStateAction<IngestionFieldAIMapping[]>>;

  ingestionRequest: SubmitIngestionFieldsRequestI | null;
  setIngestionRequest: (
    ingestionRequest: SubmitIngestionFieldsRequestI
  ) => void;

  resetContextData: () => void;
}

type UploadLeadsSidebarProviderPropsI = PropsWithChildren;

const UploadLeadsSidebarContext = createContext<UploadLeadsSidebarContextI>({
  selectedCampaignId: null,

  selectedListId: null,
  setSelectedListId: () => {},

  ingestionId: null,
  setIngestionId: () => {},

  ingestionFields: [],
  setIngestionFields: () => {},

  ingestionAIMappings: [],
  setIngestionAIMappings: () => {},

  ingestionRequest: null,
  setIngestionRequest: () => {},

  resetContextData: () => {},
});

export const useUploadLeadsSidebarContext = () =>
  useContext(UploadLeadsSidebarContext);

export const UploadLeadsSidebarProvider: FC<
  UploadLeadsSidebarProviderPropsI
> = ({ children }) => {
  const { currentCampaign } = useGlobalContext();

  const [selectedListId, setSelectedListId] = useState<string | null>(null);

  const [ingestionId, setIngestionId] = useState<string | null>(null);

  const [ingestionFields, setIngestionFields] = useState<
    IngestionFieldMapItemI[]
  >([]);

  const [ingestionAIMappings, setIngestionAIMappings] = useState<
    IngestionFieldAIMapping[]
  >([]);

  const [ingestionRequest, setIngestionRequest] =
    useState<SubmitIngestionFieldsRequestI | null>(null);

  const resetContextData = () => {
    setSelectedListId(null);
    setIngestionId(null);
    setIngestionFields([]);
    setIngestionAIMappings([]);
    setIngestionRequest(null);
  };

  return (
    <UploadLeadsSidebarContext.Provider
      value={{
        selectedCampaignId: currentCampaign?.id,

        selectedListId,
        setSelectedListId,

        ingestionId,
        setIngestionId,

        ingestionFields,
        setIngestionFields,

        ingestionAIMappings,
        setIngestionAIMappings,

        ingestionRequest,
        setIngestionRequest,

        resetContextData,
      }}
    >
      {children}
    </UploadLeadsSidebarContext.Provider>
  );
};
