import { useMemo } from "react";
import { useRouter } from "next/router";

import {
  FeatureIntroTooltipButtonI,
  FeatureIntroTooltipGuideStepsI,
} from "shared/ui/feature-intro/tooltip-guide";
import {
  LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID,
  LISTS_ACCOUNT_DETAILS_SUMMARY_ID,
  LISTS_ADD_LEAD_MENU_ID,
  LISTS_START_CALLING_BUTTON_ID,
} from "@/constants/element-ids";
import { WIDGETS, widgets } from "@/widgets/index";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { NURTURE_LEADS_BUTTON_ID } from "@/modules/pipeline/lists/list/primary-header/list-subsection-switch/constants";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

const closeTourWidget = () => {
  widgets.close({ id: WIDGETS.MAXED_DIALER_TOUR });
};

export const useListTourSteps = (
  exitTour: () => void
): FeatureIntroTooltipGuideStepsI => {
  const { replace } = useRouter();

  const exitTourButton: FeatureIntroTooltipButtonI = {
    text: "Exit tour",
    actionHandler: (onClose) => {
      onClose();
      exitTour();
    },
  };

  return useMemo(
    () => ({
      [LISTS_ADD_LEAD_MENU_ID]: {
        title: "Add leads manually or via csv upload",
        description:
          "Your leads will be added to the list you specify in your upload.",
        placement: "bottom",
        buttons: {
          primary: {
            text: "Next",
          },
          secondary: exitTourButton,
        },
      },
      [LISTS_START_CALLING_BUTTON_ID]: {
        tooltipContentClassName: "w-[320px]",
        title:
          "Select a lead to call directly or choose ‘Start calling’ to trigger the auto-dialer",
        description:
          "Click on a prospect to open their account details or let the auto-dialer work through your call list",
        placement: "bottom",
        buttons: {
          primary: {
            text: "Next",
            actionHandler: () => {
              widgets.trigger({
                id: WIDGETS.MAXED_DIALER_TOUR,
                state: {
                  callStatus: DIALER_CALL_STATUS.DURING,
                },
              });
            },
          },
          secondary: exitTourButton,
        },
      },
      [LISTS_ACCOUNT_DETAILS_SUMMARY_ID]: {
        tooltipContentClassName: "z-50",
        shouldOutlineReference: false,
        delayMs: 200,
        title: "View detailed account information on calls",
        description:
          "Use your AI call summary and account details to stay up to date with your prospect.",
        placement: "bottom",
        buttons: {
          primary: {
            text: "Next",
            actionHandler: async () => {
              await replace({
                search: window.location.search.replace(
                  DIALER_CALL_STATUS.DURING,
                  DIALER_CALL_STATUS.AFTER
                ),
              });

              setTimeout(() => {
                if (window.innerHeight <= 800) {
                  document
                    .getElementById(
                      LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID
                    )
                    ?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                }
              }, TIME_INTERVALS_MS.ONE_SECOND / 4);
            },
          },
          secondary: {
            ...exitTourButton,
            actionHandler: (onClose) => {
              closeTourWidget();
              exitTourButton.actionHandler?.(onClose);
            },
          },
        },
      },
      [LISTS_ACCOUNT_DETAILS_SUBMIT_DISPOSITION_BUTTONS_ID]: {
        tooltipContentClassName: "z-50",
        delayMs: 100,
        shouldOutlineReference: false,
        title: "Disposition your calls",
        description:
          "Based on the disposition type, the lead will automatically be bucketed into a cold or nurture list.",
        buttons: {
          primary: {
            text: "Next",
            actionHandler: closeTourWidget,
          },
          secondary: {
            ...exitTourButton,
            actionHandler: (onClose) => {
              closeTourWidget();
              exitTourButton.actionHandler?.(onClose);
            },
          },
        },
      },
      [NURTURE_LEADS_BUTTON_ID]: {
        tooltipContentClassName: "w-[310px]",
        shouldOutlineReference: false,
        title: "Nurture and cold leads",
        description:
          "If you had a conversation with a lead, they will be added to your nurture list. If they did not pick up they will stay in your cold leads. We will track all of your calling activity for easy engagement.",
        placement: "bottom",
        buttons: {
          primary: {
            text: "Got it",
            className: "w-[125px]",
            actionHandler: () => {
              closeTourWidget();
              exitTour();
            },
          },
        },
      },
    }),
    [exitTourButton, replace]
  );
};
