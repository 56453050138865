import { FC } from "react";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { DIALER_STATES } from "@/constants/dialer";

import { StartCallButton } from "./start-call-button";
import { EndCallButton } from "./end-call-button";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface CallButtonPropsI extends PropsWithClassNameI {
  isDisabled?: boolean;
  onStartCall?: () => Promise<void>;
  callingState?: string;
}

export const CallButton: FC<CallButtonPropsI> = ({
  isDisabled,
  className,
  onStartCall,
  callingState: callingStateProp,
}) => {
  const { callingState: callingStateFromContext } = useCallingContext();
  const callingState = callingStateProp || callingStateFromContext;

  return (
    <>
      {callingState === DIALER_STATES.CALL && (
        <EndCallButton isDisabled={isDisabled} className={className} />
      )}

      {callingState !== DIALER_STATES.CALL && (
        <StartCallButton
          isDisabled={isDisabled}
          className={className}
          onClick={onStartCall}
        />
      )}
    </>
  );
};
