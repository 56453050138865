import { AxiosInstance, AxiosResponse } from "axios";

import { CallAccountI, CallContactI, LeadInfoI } from "@/interfaces/dialer";
import { ActivityI } from "@/interfaces/dialer/activity";
import { InCallContextI } from "@/interfaces/contexts/calling";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import {
  DISPOSITIONS_V3,
  PROSPECT_DISPOSITION_TYPES,
} from "shared/lib/constants/dispositions";

export interface GetDialerLeadResponseI {
  contact: CallContactI;
  account: CallAccountI;
  activity_log: Array<ActivityI>;
  calendly_uri: string;
  caller_phone: string;
  skip_cooldown_counter: number;
  is_live_transfer?: boolean;
  contact_local_time_zone?: string;
  last_called_time?: string;
  previous_attempts?: number;
  error_code?: number;
}

export interface SaveContactCallbackRequestI {
  contact_id: string;
  notes: string;
  follow_up_time: string;
}
export interface SaveContactCallbackResponseI {
  save_contact_activity_log_id: string;
}

export interface AddNextLeadRequestParamsI {
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  email: string;
}

export interface AddNextLeadResponseI {
  contact: LeadInfoI;
}

export type MarkTodoDoneTypeI =
  | "MARK_DONE"
  | "SEND_FOLLOWUP_EMAIL"
  | "DISQUALIFY_LEAD";

export interface MarkTodoDoneRequestI {
  type: MarkTodoDoneTypeI;
  note?: string;
  email_request?: {
    notes: string;
    emails: string[];
  };
}

export interface AddAccountAndContactRequestParamsI {
  company_name: string;
  company_state: string;
  company_url: string;
  company_industry: string;

  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  email: string;
}

export interface AddAccountAndContactResponseI {
  contact: LeadInfoI;
}

export interface GetCallContextResponseI extends InCallContextI {}

export interface BookMeetingResponseI {
  event: CalendlyEventI;
}

export interface AddCallDispositionV2ParamsI {
  had_conversation_with_prospect?: boolean;
  prospect_disposition_type?: ValueOfObjectFields<
    typeof PROSPECT_DISPOSITION_TYPES
  >;
  disposition?: ValueOfObjectFields<typeof DISPOSITIONS_V3>;
  next_touch_time?: string;
  notes?: string;

  /**
   * GlenX fields to book a meeting
   */
  x_book_meeting?: {
    meeting_time: string;
    account_executive_id: string;
  };
}

export interface ShortCampaignI {
  campaign_id: string;
  campaign_logo_url: string;
  campaign_name: string;
}

export interface ShortListI {
  campaign_id: string;
  campaign_logo_url: string;
  campaign_name: string;
  list_id: string;
  cold_count: number;
  nurture_count: number;
}

export interface GetStartCallingModalDataResponseI {
  list_dialing_campaigns?: ShortListI[];
  general_dialing_campaigns?: ShortCampaignI[];
}

export interface GetCallerPhoneNumberByCallIdRsponseI {
  caller_phone: string;
  contact_phone: string;
  contact_id: string;
  account_id: string;
}

export interface DialerApiI {
  getStartCallingModalData: () => Promise<
    AxiosResponse<GetStartCallingModalDataResponseI>
  >;
  sendTodoToBack: (todoId: string) => Promise<AxiosResponse>;
  markDone: (
    todoId: string,
    request: MarkTodoDoneRequestI
  ) => Promise<AxiosResponse>;

  saveContactCallback(
    campaignId: string,
    callId: string,
    data: SaveContactCallbackRequestI
  ): Promise<AxiosResponse<SaveContactCallbackResponseI>>;

  addNextLead(
    campaignId: string,
    callId: string,
    params: AddNextLeadRequestParamsI
  ): Promise<AxiosResponse<AddNextLeadResponseI>>;

  addAccountAndContact(
    campaignId: string,
    params: AddAccountAndContactRequestParamsI
  ): Promise<AxiosResponse<AddAccountAndContactResponseI>>;

  editNextLead(
    campaignId: string,
    callId: string,
    params: AddNextLeadRequestParamsI
  ): Promise<AxiosResponse<AddNextLeadResponseI>>;

  deleteLead(
    campaignId: string,
    callId: string,
    contactId: string
  ): Promise<AxiosResponse>;

  getCallContext(
    campaignId: string,
    callId: string
  ): Promise<AxiosResponse<GetCallContextResponseI>>;

  /**
   * TODO remove completely
   */
  deprecated_BookMeeting(
    campaignId: string,
    callId: string,
    eventUri: string
  ): Promise<AxiosResponse<BookMeetingResponseI>>;

  /**
   * GlenX
   *
   * This is used to book a meeting off a call
   *
   * @param campaignId
   * @param accountId
   * @param contactId
   *
   * @param params
   * @param params.account_executive_id
   * @param params.meeting_time
   * @param params.notes
   */
  bookMeeting(
    campaignId: string,
    accountId: string,
    contactId: string,
    params: {
      account_executive_id: string;
      meeting_time: string;
      notes?: string;
    }
  ): Promise<AxiosResponse>;

  updateBookedMeeting(
    campaignId: string,
    callId: string,
    bookedMeetingId: string,
    params: {
      qualification_criteria: SelectedQualificationCriteriaQuestionI[];
      notes: string;
    }
  ): Promise<AxiosResponse>;

  addCallDisposition(
    campaignId: string,
    callId: string,
    params: {
      call_disposition: string;
      notes: string;
      is_not_a_direct_line?: boolean;
      has_technical_issues?: boolean;
    }
  ): Promise<AxiosResponse>;

  addCallDispositionV2(
    campaignId: string,
    callId: string,
    params: AddCallDispositionV2ParamsI
  ): Promise<AxiosResponse>;

  getDialerCurrentLead(
    campaignId: string
  ): Promise<AxiosResponse<GetDialerLeadResponseI>>;

  dialerMoveToNextLead(campaignId: string): Promise<AxiosResponse>;

  dialerSkipCurrentLead(
    campaignId: string,
    params: {
      reason?: string;
      should_disqualify?: boolean;
    }
  ): Promise<AxiosResponse>;

  getCallerPhoneNumberByCallId(
    callId: string
  ): Promise<AxiosResponse<GetCallerPhoneNumberByCallIdRsponseI>>;
}

export const DialerAPIEndpoints = (API: AxiosInstance): DialerApiI => ({
  getStartCallingModalData: () => API.get(`/v1/start_calling`),
  sendTodoToBack: (todoId) => API.post(`/v1/todo/${todoId}/back_of_the_list`),
  markDone: (todoId, request) => API.post(`/v1/todo/${todoId}/done`, request),

  saveContactCallback: (campaignId, callId, data) =>
    API.post(`/v1/campaign/${campaignId}/call/${callId}/contact/save`, {
      ...data,
    }),

  addNextLead: (campaignId, callId, params) =>
    API.put(`/v1/campaign/${campaignId}/call/${callId}/contact`, {
      ...params,
    }),

  addAccountAndContact: (campaignId, params) =>
    API.post(`/v1/campaign/${campaignId}/account_and_contact`, {
      ...params,
    }),
  editNextLead: (campaignId, callId, params) =>
    API.patch(`/v1/campaign/${campaignId}/call/${callId}/contact`, {
      ...params,
    }),
  deleteLead: (campaignId, callId, contactId) =>
    API.delete(
      `/v1/campaign/${campaignId}/call/${callId}/contact/${contactId}`
    ),

  getCallContext: (campaignId, callId) =>
    API.get(`/v3/campaign/${campaignId}/call/${callId}`),

  deprecated_BookMeeting: (campaignId, callId, eventUri) =>
    API.post(`/v1/campaign/${campaignId}/call/${callId}/booked_meeting`, {
      event_uri: eventUri,
    }),

  bookMeeting: (campaignId, accountId, contactId, params) =>
    API.post(
      `/x/v1/campaign/${campaignId}/account/${accountId}/contact/${contactId}/book_meeting`,
      { ...params }
    ),

  updateBookedMeeting: (campaignId, callId, bookedMeetingId, params) =>
    API.patch(
      `v1/campaign/${campaignId}/call/${callId}/booked_meeting/${bookedMeetingId}`,
      {
        ...params,
      }
    ),

  addCallDisposition: (campaignId, callId, params) =>
    API.post(`v1/campaign/${campaignId}/call/${callId}/call_disposition`, {
      ...params,
    }),

  addCallDispositionV2: (campaignId, callId, params) =>
    API.post(`/v2/campaign/${campaignId}/call/${callId}/call_disposition`, {
      ...params,
    }),

  getDialerCurrentLead: (campaignId) =>
    API.post(`/v3/campaign/${campaignId}/get_working_contact`),

  dialerMoveToNextLead: (campaignId) =>
    API.post(`/v3/campaign/${campaignId}/get_next_contact`),

  dialerSkipCurrentLead: (campaignId, params) =>
    API.post(`/v1/campaign/${campaignId}/skip_current_contact`, { ...params }),

  getCallerPhoneNumberByCallId: (callId) =>
    API.get(`/v1/call/${callId}/call_phone_number`),
});
