import { useProfileSettingsStore } from "lib/store/profile-settings";
import { Sidebar } from "shared/ui";
import { ProfileSettingsForm } from "@/modules/profile-settings/form";

export const ProfileSettingsModule = () => {
  const {
    isProfileSettingsOpen,
    actions: { closeProfileSettings },
  } = useProfileSettingsStore();

  return (
    isProfileSettingsOpen && (
      <Sidebar
        title="My profile"
        containerClassName="w-[524px]"
        backgroundBlurClassName="bg-white"
        handleClose={closeProfileSettings}
      >
        <ProfileSettingsForm />
      </Sidebar>
    )
  );
};
