import { Cog8ToothIcon } from "@heroicons/react/24/outline";

import { ActionMenu } from "shared/ui/action-menu";
import {
  DialRateSection,
  MicrophoneSection,
  SpeakerSection,
} from "@/modules/pipeline/lists/list/workspace/table/actions/call-settings-dropdown/sections";

const DropdownContent = () => (
  <div className="flex flex-col px-3 py-2">
    <DialRateSection />
    <MicrophoneSection />
    <SpeakerSection />
  </div>
);

export const CallSettingsDropdown = () => {
  return (
    <ActionMenu
      menuClassName="p-0 w-[400px] text-[#334155]"
      trigger={<Cog8ToothIcon className="w-8 cursor-pointer p-1" />}
      actions={[
        {
          content: (
            <div className="border-b p-3 typography-body-4-bold">
              Call Settings
            </div>
          ),
        },
        {
          content: <DropdownContent />,
        },
      ]}
    />
  );
};
