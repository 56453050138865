import { AddOrImportLeadsButtons } from "@/modules/pipeline/shared/new-lead-buttons";
import PipelineSearch from "@/modules/pipeline/lists/list/workspace/header/search";
import { useDisclosure } from "shared/lib/hooks/use-disclosure";

export const LeadListsWorkspaceHeaderActions = ({
  setSearchTerm,
}: {
  setSearchTerm: (searchTerm?: string) => void;
}) => {
  const {
    isOpen: isUploadLeadsSidebarOpen,
    onOpen: onOpenUploadLeadsSidebar,
    onClose: onCloseUploadLeadsSidebar,
  } = useDisclosure();

  return (
    <div className="relative flex gap-3">
      <PipelineSearch onSearch={setSearchTerm} />

      <AddOrImportLeadsButtons
        isUploadLeadsSidebarOpen={isUploadLeadsSidebarOpen}
        onOpenUploadLeadsSidebar={onOpenUploadLeadsSidebar}
        onCloseUploadLeadsSidebar={onCloseUploadLeadsSidebar}
      />
    </div>
  );
};
