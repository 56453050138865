import { FC, ReactNode, useMemo, useState } from "react";
import Link from "next/link";

import { clsxMerge } from "shared/lib/helpers";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

interface LeadDescriptionInfoPropsI {
  className?: string;
  contentClassName?: string;
  children?: ReactNode;
}

interface LeadDescriptionContactItemPropsI {
  isLink?: boolean;
  url?: string;
  icon: ReactNode;
  content: string;
  className?: string;
  theme?: "dark" | "light";
}

interface LeadDescriptionInfoItemPropsI {
  isLink?: boolean;
  url?: string;
  title: string;
  subtitle: string;
}

const useLeadCardV2InfoLink = (url: string) => {
  return url || "#";
};

// A fixed width of the LeadDescriptionInfoItemLarge
const LEAD_DESCRIPTION_ROW_PARENT_WIDTH = 138;

const LeadCardDetailContentWithTooltip: FC<
  Omit<LeadDescriptionInfoItemPropsI, "subtitle"> & { className?: string }
> = ({ className, isLink, url, title }) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const linkURL = useLeadCardV2InfoLink(url || "");

  const contentRefInit = (
    textElement: HTMLSpanElement | HTMLAnchorElement | null
  ) => {
    if (!textElement) {
      return;
    }

    if (textElement.offsetWidth > LEAD_DESCRIPTION_ROW_PARENT_WIDTH) {
      setShouldShowTooltip(true);
    }
  };

  const content = useMemo(
    () =>
      !!title && (
        <div className={clsxMerge("truncate", className)}>
          {isLink ? (
            <Link
              ref={contentRefInit}
              target="_black"
              href={linkURL}
              className="ae-typography-link"
            >
              {title || "N/A"}
            </Link>
          ) : (
            <span ref={contentRefInit} className="text-black">
              {title || "N/A"}
            </span>
          )}
        </div>
      ),
    [isLink, title, linkURL]
  );

  return (
    <div className="flex flex-col gap-1.5">
      {shouldShowTooltip ? (
        <Tooltip showDelay={0}>
          <TooltipTrigger>{content}</TooltipTrigger>
          <TooltipContent className="z-20 text-white">{title}</TooltipContent>
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};

export const LeadDescriptionInfo: FC<LeadDescriptionInfoPropsI> = ({
  className,
  contentClassName,
  children,
}) => (
  <div className={clsxMerge("flex gap-[10px]", className)}>
    <div className="w-[2px] rounded-xl bg-white" />

    <div className={clsxMerge("flex w-[90%] flex-col", contentClassName)}>
      {children}
    </div>
  </div>
);

export const LeadDescriptionContactItem: FC<
  LeadDescriptionContactItemPropsI
> = ({ isLink = false, url, icon, content, theme, className }) => {
  const linkURL = useLeadCardV2InfoLink(url || "");

  const isDarkTheme = !theme || theme === "dark";
  const isLightTheme = theme === "light";

  return (
    <div
      className={clsxMerge(
        "flex items-center gap-1",
        { "brand-typography-detail2": !className?.includes("typography") },
        className
      )}
    >
      <div
        className={clsxMerge("rounded px-2 py-1", {
          "bg-[rgba(1,77,254,0.10)] text-[#014DFE]": isDarkTheme && isLink,
          "bg-black/10 text-[#333]": isDarkTheme && !isLink,

          "bg-white/10": isLightTheme,
        })}
      >
        {icon}
      </div>

      <div className="truncate">
        {isLink ? (
          <Link
            target="_blank"
            href={linkURL}
            className={clsxMerge({ "text-[#014DFE]": isDarkTheme })}
          >
            {content || "N/A"}
          </Link>
        ) : (
          <span className={clsxMerge({ "text-black": isDarkTheme })}>
            {content || "N/A"}
          </span>
        )}
      </div>
    </div>
  );
};

export const LeadDescriptionInfoItemLarge: FC<
  LeadDescriptionInfoItemPropsI
> = ({ isLink, url, title, subtitle }) => (
  <div className="flex flex-col gap-1.5">
    <LeadCardDetailContentWithTooltip
      className="brand-typography-h5-black"
      title={title}
      url={url}
      isLink={isLink}
    />

    <span className="brand-typography-detail2 text-black">{subtitle}</span>
  </div>
);

export const LeadDescriptionInfoItemSmall: FC<
  LeadDescriptionInfoItemPropsI
> = ({ isLink, url, title, subtitle }) => (
  <div className="flex w-full flex-col gap-1.5">
    <LeadCardDetailContentWithTooltip
      className="brand-typography-detail1-black"
      title={title}
      url={url}
      isLink={isLink}
    />

    <span className="brand-typography-detail2 text-black">{subtitle}</span>
  </div>
);
