import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { produce } from "immer";
import { AxiosResponse } from "axios";

import { useApiClient } from "@/context/api-client";
import {
  BulkDisqualifyContactsFromListRequestI,
  BulkRemoveContactsFromListRequestI,
  ContactToListI,
  CreateOrUpdateListRequestParamsI,
  GetListResponseI,
  GetListsRequestFilterParamsI,
} from "@/api/routes/list";
import { ListTableSortingStateI } from "@/modules/pipeline/lists/list/workspace/table/interfaces";
import { ListSubsectionI } from "@/modules/pipeline/lists/list/interfaces";
import {
  selectorNormalizedFilters,
  selectorSyncContactsByAccountId,
} from "@/modules/pipeline/lists/list/selectors";
import { pluralizeNoun } from "shared/lib/helpers";

import {
  getListContactsQueryKey,
  LISTS_QUERY_KEY,
} from "@/modules/pipeline/lists/list/queries/keys";

export const useCreateList = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      api.createList(parameters),
    onSuccess: (_, { name }) => {
      toast.success(`List '${name}' has been created!`);

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to create new list."),
  });
};

export const useUpdateList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      api.updateList(listId, parameters),
    onSuccess: () => {
      toast.success("List details have been updated!");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to update this list."),
  });
};

export const useDeleteList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: () => api.deleteList(listId),
    onSuccess: () => {
      toast.success("List has been removed.");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to remove this list."),
  });
};

export interface FetchListContactsParamsI {
  listId: string;
  sortParams: ListTableSortingStateI | undefined;
  filters: GetListsRequestFilterParamsI | undefined;
  isNurtureList?: boolean;
  isSystemList?: boolean;
}

/**
 * Custom hook to update contacts by account ID.
 *
 * This hook provides a mutation that fetches contacts for a specific account
 * from a campaign list and updates the local React Query cache with the new
 * or modified contact data. The mutation uses `useApiClient` to make the API call
 * and `useQueryClient` to update the cached data.
 *
 * @returns {UseMutationResult} - A mutation result object from `useMutation`.
 */
export const useUpdateContactsByAccountId = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      /**
       * We need to use keys to update cached list contacts
       */
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      reactQueryKeys,
      request: { campaignId, listId, accountId, searchQueryParams },
    }: {
      reactQueryKeys: FetchListContactsParamsI;
      request: {
        campaignId: string;
        listId: string;
        accountId: string;
        searchQueryParams: {
          list_subsection: ListSubsectionI;
        };
      };
    }) =>
      api.getListContactsByAccountId(
        campaignId,
        listId,
        accountId,
        searchQueryParams
      ),
    /**
     * Updates the local cache with the fetched contacts after a successful mutation.
     *
     * This function is triggered when the API call (mutation) is successful. It:
     * 1. Retrieves the `contacts` from the API response.
     * 2. Normalizes the filters (if any) to ensure they can be used for caching purposes.
     * 3. Uses `queryClient.setQueryData` to update the cached list of contacts for the specified list.
     * 4. It modifies the cached contacts using `produce` to ensure that any new or updated contacts
     *    from the API response are merged into the correct pages of contacts in the cache.
     * 5. Contacts are updated by:
     *    - Syncing the current page's contacts with the contacts from the response using `selectorSyncContactsByAccountId`.
     *    - Keeping track of contacts that have not yet been synced (remaining contacts).
     * 6. If there are still unsynced contacts after processing all pages, they are prepended to the first page of contacts.
     **/
    onSuccess: (resp, { reactQueryKeys, request }) => {
      const accountContacts = resp.data?.contacts;

      queryClient.setQueryData<
        InfiniteData<AxiosResponse<GetListResponseI, any>>
      >(
        getListContactsQueryKey(reactQueryKeys.listId, {
          sortParams: reactQueryKeys.sortParams,
          filters: selectorNormalizedFilters(
            reactQueryKeys.filters,
            reactQueryKeys.isNurtureList,
            reactQueryKeys.isSystemList
          ),
        }),
        (oldData) => {
          if (!oldData) return oldData;

          let accountContactsToSync = accountContacts;

          const newData = produce(oldData, (draft) => {
            draft.pages.forEach((page) => {
              const [syncedContacts, remainingContactsToSync] =
                selectorSyncContactsByAccountId(
                  page.data.contacts || [],
                  accountContactsToSync,
                  request.accountId
                );

              accountContactsToSync = remainingContactsToSync;
              page.data.contacts = syncedContacts;
            });

            if (accountContactsToSync.length) {
              draft.pages[0].data.contacts.unshift(...accountContactsToSync);
            }

            return draft;
          });

          return newData as InfiniteData<AxiosResponse<GetListResponseI, any>>;
        }
      );
    },
    onError: () => {
      // Do nothing
      // Errors are logged by default into dd
    },
  });
};

export const useBulkAddContactsToList = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      contacts,
      list_ids,
    }: {
      contacts: ContactToListI[];
      list_ids: string[];
    }) =>
      api.bulkAddContactsToListRequest({
        contacts,
        list_ids,
      }),
    onSuccess: (_, { contacts: { length }, list_ids: listIds }) => {
      toast.success(
        `${
          length > 1 ? "Contacts have" : "Contact has"
        } been added to the specified ${pluralizeNoun("list", length)}`
      );

      listIds.forEach((listId) => {
        queryClient.invalidateQueries({
          queryKey: getListContactsQueryKey(listId),
        });
      });
    },
    onError: (_, { contacts: { length } }) => {
      toast.error(
        `Failed to add ${pluralizeNoun(
          "contact",
          length
        )} to the specified ${pluralizeNoun("list", length)}`
      );
    },
  });
};

export const useBulkRemoveContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ membership_ids }: BulkRemoveContactsFromListRequestI) =>
      api.bulkRemoveContactsFromListRequest(listId, {
        membership_ids,
      }),
    onSuccess: () => {
      toast.success("Contacts have been removed from the list.");

      queryClient.invalidateQueries({
        queryKey: getListContactsQueryKey(listId),
      });
    },
    onError: () => toast.error("Failed to remove contacts from this list."),
  });
};

export const useBulkDisqualifyContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      membership_ids,
      reason,
    }: BulkDisqualifyContactsFromListRequestI) =>
      api.bulkDisqualifyContactsFromListRequest(listId, {
        membership_ids,
        reason,
      }),
    onSuccess: () => {
      toast.success("Contacts have been disqualified from the list.");

      queryClient.invalidateQueries({
        queryKey: getListContactsQueryKey(listId),
      });
    },
    onError: () => toast.error("Failed to disqualify contacts from the list."),
  });
};
