import { createElement, FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { AccountUserListTypeI } from "shared/lib/interfaces/account";
import {
  LIST_TYPES,
  SYSTEM_LIST_TITLES,
} from "@/modules/pipeline/lists/list/constants";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { LIST_STATUS_PILL_VARIANTS } from "@/modules/pipeline/lists/list/workspace/table/column-cell-definition/status-pill/constants";

interface ListStatusPillPropsI extends PropsWithClassNameI {
  listType: AccountUserListTypeI;
}

export const ListStatusPill: FC<ListStatusPillPropsI> = ({
  listType,
  className,
}) => {
  if (listType in LIST_STATUS_PILL_VARIANTS) {
    const typedListType = listType as keyof typeof LIST_STATUS_PILL_VARIANTS;
    const pillConfig = LIST_STATUS_PILL_VARIANTS[typedListType];

    const listName =
      typedListType === LIST_TYPES.CALLBACKS
        ? "Nurture"
        : SYSTEM_LIST_TITLES[typedListType];

    return (
      <div
        className={clsxMerge(
          "flex w-fit items-center rounded px-2 py-1",
          "brand-typography-h7 gap-1.5",
          pillConfig.bg,
          pillConfig.color,
          className
        )}
      >
        {createElement(pillConfig.icon, {
          className: clsxMerge(pillConfig?.color, "w-4 h-4"),
        })}

        {listName}
      </div>
    );
  }

  return <span className="text-black/60">N/A</span>;
};
