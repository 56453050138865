import { FC } from "react";
import { Portal } from "react-portal";

import { UploadLeadsSidebar } from "@/modules/pipeline/shared/upload-leads-sidebar";

import {
  ADD_NEW_LEAD_TO_LIST_MODAL,
  AddNewLeadToListModal,
} from "@/modals/lists/add-new-lead-to-list";
import { ButtonMenu } from "shared/ui";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import { modal } from "@/modals/index";
import { LISTS_ADD_LEAD_MENU_ID } from "@/constants/element-ids";

interface AddOrImportLeadsButtonsPropsI {
  isUploadLeadsSidebarOpen: boolean;
  onOpenUploadLeadsSidebar: () => void;
  onCloseUploadLeadsSidebar: () => void;
  loadAccounts?: () => void;
}

/**
 * This component represents a dropdown with "New Lead" and "Import Leads"
 * buttons used in the header of the Leads pages (open pipeline and lists).
 *
 * @param isUploadLeadsSidebarOpen
 * @param onOpenUploadLeadsSidebar
 * @param onCloseUploadLeadsSidebar
 * @param loadAccounts
 * @constructor
 */
export const AddOrImportLeadsButtons: FC<AddOrImportLeadsButtonsPropsI> = ({
  isUploadLeadsSidebarOpen,
  onOpenUploadLeadsSidebar,
  onCloseUploadLeadsSidebar,
}) => {
  return (
    <>
      <ButtonMenu
        id={LISTS_ADD_LEAD_MENU_ID}
        position="end"
        className="btn-ae-default w-[118px]"
        text="Add Lead"
        items={[
          <ButtonMenuItem
            text="New Lead"
            onClick={() => modal.trigger(ADD_NEW_LEAD_TO_LIST_MODAL)}
          />,
          <ButtonMenuItem
            text="Import Leads"
            onClick={onOpenUploadLeadsSidebar}
          />,
        ]}
      />

      <Portal>
        <UploadLeadsSidebar
          isOpen={isUploadLeadsSidebarOpen}
          onClose={onCloseUploadLeadsSidebar}
        />

        <AddNewLeadToListModal />
      </Portal>
    </>
  );
};
