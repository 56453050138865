import { useMemo } from "react";
import { Portal } from "react-portal";
import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { useDisclosure } from "shared/lib/hooks";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";

import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { clsxMerge } from "shared/lib/helpers";

import {
  CONFIRM_DELETE_LIST,
  ConfirmDeleteList,
} from "@/components/modals/lists/confirm-delete-list";

import {
  CreateListSidebar,
  UpdateListSidebar,
} from "@/modules/pipeline/lists/list/create-list-sidebar";

import { getListName } from "@/modules/pipeline/utils";
import { LIST_TYPES } from "@/modules/pipeline/lists/list/constants";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { useDeleteList } from "@/modules/pipeline/lists/list/queries/mutations";

export const ListActions = () => {
  const {
    activeListId,
    activeList,
    resetActiveListId,
    listDetails: data,
    isSystemList,
  } = useListsContext();
  const listType = activeList?.list_type;

  // Only custom lists can be deleted, system and exclusive lists cannot
  const isCustomList = useMemo(
    () => activeList?.list_type === LIST_TYPES.CUSTOM,
    [listType]
  );

  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const {
    isOpen: isUpdateListSidebarOpen,
    onClose: onCloseUpdateListSidebar,
    onOpen: onOpenUpdateListSidebar,
  } = useDisclosure();

  const {
    isOpen: isCreateListSidebarOpen,
    onOpen: onOpenCreateListSidebar,
    onClose: onCloseCreateListSidebar,
  } = useDisclosure();

  const { mutateAsync: deleteList } = useDeleteList(activeListId);

  const { name, description } = useMemo(() => {
    if (!data) {
      return {
        name: "",
        description: null,
      };
    }

    return {
      name: getListName({ name: data?.name, list_type: listType }),
      description: data?.description,
    };
  }, [data]);

  const handleDeleteList = () => {
    // Disable deleting of any lists other than custom ones
    if (isCustomList) {
      deleteList().then(resetActiveListId);
    }
  };

  return (
    <div
      className={clsxMerge(
        " flex h-fit gap-3",
        "transition-opacity duration-300",
        {
          "pointer-events-none opacity-50": isGlobalDialer,
        }
      )}
    >
      <div className="divider divider-horizontal m-0 py-2" />

      <button
        className="btn-transparent px-0"
        onClick={onOpenCreateListSidebar}
      >
        <PlusCircleIcon className="mr-0.5 w-5" />
      </button>

      {!isSystemList && (
        <button
          className="btn-transparent px-0"
          onClick={onOpenUpdateListSidebar}
        >
          <PencilIcon className="mr-0.5 w-5" />
        </button>
      )}

      {!isSystemList && (
        <button
          className="btn-transparent px-0"
          onClick={() => modalHelpers.open(CONFIRM_DELETE_LIST)}
        >
          <TrashIcon className="mr-0.5 w-5" />
        </button>
      )}

      <Portal>
        {!isSystemList && (
          <UpdateListSidebar
            isOpen={isUpdateListSidebarOpen}
            onClose={onCloseUpdateListSidebar}
            listId={activeListId}
            name={name || ""}
            description={description || ""}
          />
        )}

        <CreateListSidebar
          isOpen={isCreateListSidebarOpen}
          onClose={onCloseCreateListSidebar}
        />
      </Portal>

      {isCustomList && (
        <ConfirmDeleteList
          listTitle={name || ""}
          onConfirm={handleDeleteList}
        />
      )}
    </div>
  );
};
