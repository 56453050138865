import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import noop from "lodash/noop";

import {
  AccountExecutiveI,
  AccountHistoryItemI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { CampaignI } from "@/interfaces/campaign";
import { useInfinitePagination } from "shared/lib/hooks/index";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { UseInfinitePaginationReturnI } from "shared/lib/hooks/infinite-scrolling/use-infinite-pagination";
import { UseFetchAccountDetailsApiI } from "@/api/routes/account/queries";
import { getLeadStateFlags } from "@/modules/pipeline/account-details/utils";
import {
  AccountDetailsStatusI,
  AccountDetailsSummaryI,
  AccountI,
  AccountUserListI,
} from "shared/lib/interfaces/account";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DialerCallStatusI } from "@/interfaces/dialer/call-status";

export interface AccountDetailsContextI {
  account?: AccountI;
  accountSummary?: AccountDetailsSummaryI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  contactsForContextMenu: Array<ContactDetailI>;
  accountStatus?: AccountDetailsStatusI;
  accountExecutiveData?: AccountExecutiveI;
  accountUserLists?: AccountUserListI[];
  accountDetailsApi?: UseFetchAccountDetailsApiI;

  accountHistoryData: UseInfinitePaginationReturnI<AccountHistoryItemI>;

  focusedContact: ContactDetailI | undefined;
  setFocusedContact: (contact?: ContactDetailI) => void;

  onAccountUpdate: () => void;

  callStatus?: DialerCallStatusI;
  areErrorsMuted?: boolean;
}

export interface AccountDetailsProviderI {
  account?: AccountI;
  accountSummary?: AccountDetailsSummaryI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  accountStatus?: AccountDetailsStatusI;
  accountExecutiveData?: AccountExecutiveI;
  accountUserLists?: AccountUserListI[];
  accountDetailsApi?: UseFetchAccountDetailsApiI;

  accountHistory?: Array<AccountHistoryItemI>;

  onAccountUpdate: () => void;

  callStatus?: DialerCallStatusI;
  areErrorsMuted?: boolean;
}

const accountHistoryFetcher = (
  campaignId: string,
  accountId: string,
  nextToken: string | undefined
) =>
  glencocoClientAPI()
    .getAccountHistoryV2(campaignId, accountId, nextToken)
    .catch((e) => e);

const AccountDetailsContext = createContext<AccountDetailsContextI>({
  focusedContact: undefined,
  setFocusedContact: () => {},
  onAccountUpdate: () => {},
  accountHistoryData: {
    isLoading: false,
    isReachedEnd: false,
    data: [],
    reloadData: () => {},
    loadMore: () => {},
  },
  accountDetailsApi: {} as UseFetchAccountDetailsApiI,
  contactsForContextMenu: [],
  callStatus: undefined,
});

export const AccountDetailsProvider: FC<
  AccountDetailsProviderI & PropsWithChildren
> = ({
  campaign: campaignData,
  account: accountData,
  contacts: contactsData,
  accountUserLists: accountUserListsData,
  accountStatus: accountStatusData,
  accountSummary: accountSummaryData,
  accountHistory,

  onAccountUpdate,
  accountExecutiveData,
  accountDetailsApi,
  areErrorsMuted = false,
  callStatus: callStatusFromProps,
  children,
}) => {
  const [focusedContact, setFocusedContact] = useState<ContactDetailI>();

  const [campaign, setCampaign] = useState(campaignData);
  useEffect(() => setCampaign(campaignData), [campaignData]);

  const [account, setAccount] = useState(accountData);
  useEffect(() => setAccount(accountData), [accountData]);

  const [contacts, setContacts] = useState(contactsData);
  useEffect(() => setContacts(contactsData), [contactsData]);

  const [accountStatus, setAccountStatus] = useState(accountStatusData);
  useEffect(() => setAccountStatus(accountStatusData), [accountStatusData]);

  const [accountSummary, setAccountSummary] = useState(accountSummaryData);
  useEffect(() => setAccountSummary(accountSummaryData), [accountSummaryData]);

  const [accountExecutive, setAccountExecutive] =
    useState(accountExecutiveData);
  useEffect(
    () => setAccountExecutive(accountExecutive),
    [accountExecutiveData]
  );

  const [accountUserLists, setAccountUserLists] =
    useState(accountUserListsData);
  useEffect(
    () => setAccountUserLists(accountUserListsData),
    [accountUserListsData]
  );

  const callStatus = useDialerCallStatus();

  const accountHistoryData = useInfinitePagination<AccountHistoryItemI, APII>({
    enabled: !accountHistory,
    isInitiallyLoading: true,
    apiFactory: glencocoClientAPI,
    collectionKeyInResponse: "activity_logs",
    fetchMore: (nextToken) =>
      accountHistoryFetcher(
        campaign?.id as string,
        account?.id as string,
        nextToken
      ),
    errorMessage: "Failed to load account history. Please contact Glencoco.",
  });

  // As `contacts` represent a full collection of contacts including ones that
  // are disqualified or marked as "do not call", we need to filter them out for
  // Disqualify, Email, Add to List, Book/Reschedule Meeting and Call buttons.
  const contactsForContextMenu = useMemo(() => {
    if (!contacts) {
      return [];
    }

    return contacts.filter((contact) => {
      const { isLeadDisqualified, isLeadMarkedDNC } =
        getLeadStateFlags(contact);

      return !isLeadDisqualified && !isLeadMarkedDNC;
    });
  }, [contacts]);

  return (
    <AccountDetailsContext.Provider
      value={{
        campaign,
        account,
        accountSummary,
        contacts,
        contactsForContextMenu,
        accountStatus,

        // Use passed history data if available, otherwise - load from backend
        accountHistoryData: accountHistory
          ? {
              isLoading: false,
              isReachedEnd: true,
              data: accountHistory,
              reloadData: noop,
              loadMore: noop,
            }
          : accountHistoryData,

        focusedContact,
        setFocusedContact,
        onAccountUpdate,
        accountExecutiveData,
        accountUserLists,
        accountDetailsApi,

        areErrorsMuted,
        callStatus: callStatusFromProps || callStatus,
      }}
    >
      {children}
    </AccountDetailsContext.Provider>
  );
};

export const useAccountDetailsContext = (): AccountDetailsContextI =>
  useContext(AccountDetailsContext);
