import { ChangeEvent, FC, MouseEventHandler, useRef, useState } from "react";
import toast from "react-hot-toast";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { readFileAsDataURL } from "shared/lib/helpers/file";
import Avatar from "shared/ui/avatar";
import { validateSelectedAvatar } from "./utils";
import {
  AVATAR_VALIDATION_MESSAGES,
  DROPZONE_ACCEPT,
  MAX_AVATAR_SIZE_BYTES,
  MAX_AVATAR_SIZE_MB,
  SUPPORTED_AVATAR_FORMATS_LIST,
  SUPPORTED_AVATAR_FORMATS_READABLE_LIST,
} from "./constants";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";
import { Dropzone, DropzoneImperativeRefI } from "shared/ui/uploader/dropzone";

interface ProfileImageSelectorPropsI extends PropsWithClassNameI {
  savedPictureURL?: string;
  fallbackText?: string;
  isUploadingProfilePicture: boolean;
  onChangeProfilePicture: (file: File | undefined) => void;
}

type InputChangeEventI = ChangeEvent<HTMLInputElement>;

const AVATAR_BLOCK_CLASSNAME = "h-[166px] w-[166px] rounded-full";

export const ProfileImageSelector: FC<ProfileImageSelectorPropsI> = ({
  className,
  savedPictureURL,
  isUploadingProfilePicture,
  onChangeProfilePicture,
  fallbackText = "You",
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewDataURL, setPreviewDataURL] = useState<string>();
  const dropzoneImperativeRef = useRef<DropzoneImperativeRefI>(null);

  const avatarImageURL = previewDataURL || savedPictureURL;

  const handleChange = async (e: InputChangeEventI) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    const { isValid, conditions } = validateSelectedAvatar(file);

    if (!isValid) {
      if (!conditions.format && !conditions.size) {
        toast.error(AVATAR_VALIDATION_MESSAGES.BOTH);
      } else {
        toast.error(
          !conditions.format
            ? AVATAR_VALIDATION_MESSAGES.FORMAT
            : AVATAR_VALIDATION_MESSAGES.SIZE
        );
      }

      return;
    }

    onChangeProfilePicture(file);

    const imageDataURL = await readFileAsDataURL(file);

    if (imageDataURL) {
      setPreviewDataURL(imageDataURL);
    }
  };

  const handleDropFile = (files: File[]) => {
    /**
     * A little hack to get access to a real FileList object with files as FileList
     * is not directly constructable
     */
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));

    return handleChange({
      target: {
        files: dataTransfer.files,
      },
    } as InputChangeEventI);
  };

  const handleCancelSelectedImage: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setPreviewDataURL(undefined);
    onChangeProfilePicture(undefined);
  };

  return (
    <section className={clsxMerge("flex w-full flex-col gap-2.5", className)}>
      <div className="flex w-full justify-between typography-body-4-medium">
        <span>Upload a profile picture</span>
        <span className="text-[#999]">Optional</span>
      </div>

      {avatarImageURL ? (
        <div
          className={clsxMerge(
            "flex w-full flex-col place-items-center pt-8",
            "rounded-md border-[1px] border-[#ccc]",
            isUploadingProfilePicture
              ? "cursor-not-allowed"
              : "cursor-pointer transition-colors hover:bg-black/3"
          )}
          onClick={() => fileInputRef.current?.click()}
        >
          <input
            ref={fileInputRef}
            type="file"
            multiple={false}
            className="hidden"
            accept={SUPPORTED_AVATAR_FORMATS_LIST}
            onChange={handleChange}
          />

          {avatarImageURL ? (
            <div
              className={clsxMerge(
                "relative transition-colors",
                AVATAR_BLOCK_CLASSNAME,
                isUploadingProfilePicture && "bg-[rgb(0,0,0,.2)]"
              )}
            >
              <Avatar
                isLazy
                src={avatarImageURL}
                placeholderText={fallbackText}
                className={clsxMerge(
                  AVATAR_BLOCK_CLASSNAME,
                  "animate-fadein typography-body-2",
                  isUploadingProfilePicture && "opacity-50"
                )}
              />

              {isUploadingProfilePicture && (
                <div className="absolute-centered-xy">
                  <LoadingSpinner className="text-white" />
                </div>
              )}
            </div>
          ) : (
            <div
              className={clsxMerge(
                AVATAR_BLOCK_CLASSNAME,
                "flex items-center justify-center",
                "bg-black text-white  typography-body-2"
              )}
            >
              {fallbackText}
            </div>
          )}

          <div className="flex gap-1 py-4 text-[#4474E3] typography-body-4">
            <span>Choose a different image</span>

            {previewDataURL && (
              <>
                <span>|</span>
                <span onClick={handleCancelSelectedImage}>
                  Cancel selected image
                </span>
              </>
            )}
          </div>
        </div>
      ) : (
        <Dropzone
          ref={dropzoneImperativeRef}
          onDrop={handleDropFile}
          maxSize={MAX_AVATAR_SIZE_BYTES}
          multiple={false}
          accept={DROPZONE_ACCEPT}
        />
      )}

      <div className="flex w-full justify-between text-[#999] typography-body-5">
        <span>Supports {SUPPORTED_AVATAR_FORMATS_READABLE_LIST}</span>
        <span>Maximum size: {MAX_AVATAR_SIZE_MB}MB</span>
      </div>
    </section>
  );
};
