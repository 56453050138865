import {
  FC,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import { clsxMerge, getS3AssetPath } from "shared/lib/helpers";

interface DropzoneUIPropsI {
  isActive?: boolean;
}

export interface DropzoneImperativeRefI {
  open: () => void;
}

type DropzonePropsI = Omit<DropzoneOptions, "onDrop"> & {
  onDrop?: (files: File[]) => void;
};

export const DropzoneUI: FC<DropzoneUIPropsI> = ({ isActive }) => (
  <div
    className={clsxMerge(
      "h-[220px]",
      "rounded-xl border-[2px] border-dashed border-[#666]",
      "flex flex-col items-center justify-center",
      "mb-3",
      "transition-all duration-300",
      isActive && "border-[#4474E3] bg-[#E4ECFF]"
    )}
  >
    <div className="mb-8">
      <img
        src={getS3AssetPath(
          isActive
            ? "platform/shared-ui/uploader/upload-active-icon.png"
            : "platform/shared-ui/uploader/upload-icon.png"
        )}
        alt="upload icon"
        width="35px"
        className="animate-fadein"
      />
    </div>

    <span className="b-typography-button2 font-normal">
      Drag & drop file here or{" "}
      <span className="cursor-pointer font-medium underline">Choose files</span>
    </span>
  </div>
);

const DropzoneRaw = (
  { accept, onDrop, multiple = true }: DropzonePropsI,
  ref: ForwardedRef<DropzoneImperativeRefI>
) => {
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    accept: accept ?? {},
    multiple,
  });

  // this exposes the open method to the parent component via the ref
  // allowing you to open the dropzone through some other action
  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open]
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <DropzoneUI isActive={isDragActive} />
    </div>
  );
};

export const Dropzone = forwardRef(DropzoneRaw);
