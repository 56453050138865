import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

import { glencocoClientAPI } from "@/api/glencoco";

export interface UploadFileI {
  file?: File;
  data?: string | ArrayBuffer;
}

export const useFileUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const handleCSVUpload = async (
    fileData: UploadFileI,
    campaignId?: string,
    onSuccess?: (ingestionId: string) => void
  ) => {
    if (fileData?.file && campaignId) {
      const API = glencocoClientAPI();

      const resp = await API.ingestions
        .getLeadCSVUploadURL(campaignId, {
          file_size: fileData?.file?.size,
          file_name: fileData?.file?.name,
        })
        .catch(() => ({
          status: null,
          data: null,
        }));

      if (resp.status === 200) {
        const UPLOAD_URL = resp.data.upload_url;

        const config = {
          headers: {
            "Content-Type": "text/csv",
          },
        };

        const uploadFileResp = await uploadDataToS3(
          UPLOAD_URL,
          config,
          fileData
        );

        if (uploadFileResp.status === 200) {
          setIsUploadComplete(true);
          onSuccess?.(resp.data.ingestion_id);
        }
      } else {
        toast.error("Server error. Failed to get CSV upload URL.");
      }
    } else {
      toast.error("Failed to upload CSV. Missing file or campaign ID.");
    }

    setIsLoading(false);
  };

  const uploadDataToS3 = (
    uploadURL: string,
    config: object,
    _file: UploadFileI
  ) => {
    return axios.put(uploadURL, _file?.file, config).catch(() => {
      toast(
        "Failed to upload file to storage service. Please contact Glencoco."
      );
      return { data: null, status: null };
    });
  };

  return {
    isLoading,
    isUploadComplete,
    handleCSVUpload,
  };
};
