import { ReactElement, ReactNode, useMemo } from "react";
import type { NextPage } from "next";
import Head from "next/head";
import type { AppProps } from "next/app";

import MetaViewport from "shared/ui/meta-viewport";

import { useDatadogRum } from "@/helpers/datadog";
import { GoogleTagManager } from "@/helpers/tracking";

import AppLayout from "@/components/layouts/app-layout";
import { AppLayoutPagePropsI } from "@/interfaces/layout";

import { useRouter } from "next/router";
import { APP_LAYOUT_SUPPORTED_ROUTES } from "@/constants/layout";

import "./globals.css";

export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<AppLayoutPagePropsI> & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const { route } = useRouter();

  useDatadogRum();

  // Use the layout defined at the page level, if available
  // const getLayout = Component.getLayout ?? ((page) => page);

  const pageInternals = useMemo(
    () => (
      <>
        <MetaViewport head={Head} />

        <GoogleTagManager />

        <Component {...pageProps} />
        {/**
         * NOTE: with a SPA approach the loader regression has been introduced many times
         *       is not able to indicate that loading has been completed
         */}
        {/* <NavigationProgressbar /> */}
      </>
    ),
    [Component, pageProps]
  );

  if (!APP_LAYOUT_SUPPORTED_ROUTES.includes(route)) {
    return pageInternals;
  }

  return <AppLayout layoutProps={pageProps}>{pageInternals}</AppLayout>;
}
