import { FC, useEffect, useState } from "react";
import clsx from "clsx";

import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { UploadLeadsSidebarDownloadSample } from "./download-sample";
import { UploadLeadsSidebarPastUploadsTable } from "./past-uploads-table";
import { UploadLeadsSidebarContentChildI } from "@/modules/pipeline/shared/upload-leads-sidebar";
import { useFetchPastIngestions } from "@/modules/pipeline/shared/upload-leads-sidebar/queries";

type UploadLeadsSidebarContentDownloadCSVExampleI =
  UploadLeadsSidebarContentChildI;

export const UploadLeadsSidebarContentDownloadCSVExample: FC<
  UploadLeadsSidebarContentDownloadCSVExampleI
> = ({ onClose = () => {}, handleNextStep = () => {} }) => {
  const { data: pastIngestionsData } = useFetchPastIngestions();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <SidebarSlide
      size={SIDEBAR_SIZE.MD}
      title=" "
      headerClassName="pb-0"
      contentClassName="pt-0"
      onClose={onClose}
    >
      <article
        className={clsx(
          "relative transition-opacity duration-300",
          isVisible ? "opacity-100" : "opacity-0"
        )}
      >
        <section className="mb-6">
          <p className="b-typography-h3 mb-1">Import Leads</p>
          <p className="b-typography-body3">
            Add your own self sourced leads to your pipeline
          </p>
        </section>

        <section className="mb-4">
          <UploadLeadsSidebarDownloadSample />
        </section>

        <section>
          <UploadLeadsSidebarPastUploadsTable
            ingestions={pastIngestionsData?.data?.ingestions}
          />
        </section>

        <section className="z-100 fixed bottom-0 left-0 flex w-full justify-between px-6 py-4">
          <button className="btn-transparent" onClick={onClose}>
            Cancel
          </button>

          <button className="btn-ae-default w-[197px]" onClick={handleNextStep}>
            Start an import
          </button>
        </section>
      </article>
    </SidebarSlide>
  );
};
