import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";

import { UserI } from "@/interfaces/user";
import { CampaignI, CampaignV3I } from "@/interfaces/campaign";
import { GetUserResponseI, glencocoClientAPI } from "@/api/glencoco";
import { useEffectOnce } from "shared/lib/hooks";

import { AsyncGetI, asyncGet } from "@/helpers/context";
import { GlobalNotificationsProvider } from "@/context/notifications-context";
import { SessionStorage } from "@/helpers/session-storage";
import { UserOnboardingStatusI } from "@/interfaces/signup";

export interface DefaultGlobalContextI {
  isUserLoaded?: boolean;
  glencocoUser?: UserI;
  userOnboardingStatus?: UserOnboardingStatusI;
  currentCampaign?: CampaignI;
  campaigns?: Array<CampaignI>;
  intercom?: any;

  asyncGet?: AsyncGetI;
  setCampaigns?: Dispatch<SetStateAction<Array<CampaignI | CampaignV3I>>>;
  reloadUser?: () => Promise<void>;
}

export interface GlobalContextI extends DefaultGlobalContextI {
  asyncGet: AsyncGetI;
  setCampaigns: Dispatch<SetStateAction<Array<CampaignI | CampaignV3I>>>;
}

const GlobalContext = createContext<DefaultGlobalContextI>({});

export const GlobalContextProvider = ({
  children,
  ...props
}: {
  children?: any;
  props?: DefaultGlobalContextI;
}) => {
  const typedProps = props as DefaultGlobalContextI;

  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [user, setUser] = useState(typedProps?.glencocoUser);
  const [userOnboardingStatus, setUserOnboardingStatus] = useState(
    typedProps?.userOnboardingStatus
  );
  const [currentCampaign, setCurrentCampaign] = useState(
    typedProps?.currentCampaign
  );
  const [campaigns, setCampaigns] = useState(typedProps?.campaigns);
  const [intercom, setIntercom] = useState(typedProps?.intercom);

  const reloadUser = async () => {
    const SS = new SessionStorage();
    const API = glencocoClientAPI();

    const sessionUser = SS.user;
    const sessionCampaigns = SS.campaigns;

    if (sessionUser) {
      setUser(sessionUser);
    }

    if (sessionCampaigns) {
      setCampaigns(sessionCampaigns as unknown as Array<CampaignI>);
    }

    if (sessionUser && sessionCampaigns) {
      setIsUserLoaded(true);
    }

    // NOTE - set the current user ---------------------------
    const userPromise = API.getUser()
      .then((UserResp) => {
        if (UserResp.status === 200) {
          const data = UserResp?.data as GetUserResponseI;

          // const user_onboarding_status = {
          //   picked_campaign: true,
          //   watched_intro_video: true,
          //   weekly_call_target: 1,
          // };

          SS.user = data?.user;
          setUser(data?.user);
          setCurrentCampaign(data?.campaign);
          setIntercom(data?.intercom_payload);
          setUserOnboardingStatus(data?.user_onboarding_status);
        }
      })
      .catch((e) => e);

    // NOTE - Get campaigns user can start calling to ---------------------------
    // It's not needed here anymore, as glenX user is attached to just one campaign
    // const campaignsPromise = requestAndMergeCampaigns({
    //   filter: {
    //     custom: {
    //       is_approved_to_call: true,
    //     },
    //   },
    // });

    userPromise
      .then(() => {
        setTimeout(() => {
          setIsUserLoaded(true);
        }, 100);
      })
      .catch(() => {
        setIsUserLoaded(true);
      });
    /** ----------------------- END ----------------------- */
  };

  const [gc, setGlobalContext] = useState({
    ...props,
    setIsUserLoaded,
    setUser,
    setCampaigns,
    setIntercom,
    setUserOnboardingStatus,

    asyncGet,
    reloadUser,
  } as GlobalContextI);

  useEffectOnce(() => {
    reloadUser();
  });

  useEffect(() => {
    setGlobalContext({
      ...gc,
      glencocoUser: user,
      currentCampaign,
      isUserLoaded,
      campaigns,
      intercom,
      userOnboardingStatus: userOnboardingStatus,
    });
  }, [
    user,
    isUserLoaded,
    currentCampaign,
    campaigns,
    intercom,
    userOnboardingStatus,
  ]);

  return (
    <GlobalContext.Provider value={gc}>
      <GlobalNotificationsProvider>{children}</GlobalNotificationsProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
