import { useEffect } from "react";
import { AxiosResponse } from "axios";
import toast from "react-hot-toast";

import { glencocoClientAPI } from "@/api/glencoco";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";
import {
  IngestionFieldAIMapping,
  IngestionFieldMapItemI,
} from "@/interfaces/ingestion";
import { GetIngestionFieldsResponseI } from "@/api/routes/ingestions";

const MAX_ATTEMPTS = Infinity;
const RETRY_INTERVAL = TIME_INTERVALS_MS.ONE_SECOND * 10;

type IngestionFieldsResponseI = AxiosResponse<GetIngestionFieldsResponseI>;

export const useFetchIngestionFields = (
  campaignId: string | null | undefined,
  ingestionId: string,
  setIngestions: (ingestionFields: IngestionFieldMapItemI[]) => void,
  setAIMappings: (aiMappings: IngestionFieldAIMapping[]) => void,
  onError?: (errMsg: string) => void,
  onStoppedFetching?: () => void
) =>
  useEffect(() => {
    let attempt = 0;

    if (!campaignId || !ingestionId) {
      return;
    }

    const fetchIngestionFields = async () => {
      const API = glencocoClientAPI();

      const getIngestionFieldsResponse = await API.ingestions
        .getIngestionFields(campaignId, ingestionId)
        .catch((e) => e);

      return getIngestionFieldsResponse as IngestionFieldsResponseI;
    };

    const getIsRetry = (
      getIngestionFieldsResponse: IngestionFieldsResponseI
    ) => {
      const ingestionFields =
        getIngestionFieldsResponse?.data?.ingestion_fields;

      const aiMappings = getIngestionFieldsResponse?.data?.ai_mappings;

      if (
        getIngestionFieldsResponse.status === 200 &&
        ingestionFields?.length
      ) {
        setIngestions(
          ingestionFields.map((ingestionField) => ({
            label: ingestionField.field_name,
            value: ingestionField.field_name,
            sample: ingestionField.field_sample_value,
          }))
        );

        setAIMappings(
          aiMappings.map((aiMapping) => ({
            dataColumnName: aiMapping.data_column_name,
            glenColumnName: aiMapping.glen_column_name,
            tableName: aiMapping.table_name,
          }))
        );

        onStoppedFetching?.();

        return false;
      } else if (
        (getIngestionFieldsResponse as any)?.response?.data?.error_code === 1084
      ) {
        onError?.(
          "Unable to upload. Your csv has more than 10,000 leads. Please reduce your lead list to 10,00 leads or less and re-upload."
        );

        onStoppedFetching?.();
      } else {
        const isRetry = attempt < MAX_ATTEMPTS;

        if (
          (getIngestionFieldsResponse as any)?.response?.data?.error_code ===
          1047
        ) {
          return isRetry;
        }

        toast.error(
          "Failed to receive ingestion fields. Please contact Glencoco team."
        );

        onStoppedFetching?.();

        return false;
      }
    };

    (async () => {
      const getIngestionFieldsResponse = await fetchIngestionFields();
      const isRetry = getIsRetry(getIngestionFieldsResponse);

      if (isRetry) {
        const interval = setInterval(() => {
          (async () => {
            const getIngestionFieldsResponse = await fetchIngestionFields();
            const isRetry = getIsRetry(getIngestionFieldsResponse);

            if (isRetry) {
              attempt += 1;
            } else {
              clearInterval(interval);
            }
          })();
        }, RETRY_INTERVAL);
      }
    })();
  }, [campaignId, ingestionId]);
