import { useEffect } from "react";
import { useQueryState } from "nuqs";

import { WIDGETS } from "@/constants/widgets";
import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "@/components/shared/widgets/constants";
import { DialerCallStatusI } from "@/interfaces/dialer/call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const useMaxedDialerTourSearchQueryParams = (
  callStatus: DialerCallStatusI = DIALER_CALL_STATUS.DURING
) => {
  const [, setWidgetType] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE
  );
  const [callStatusFromSearch, setCallStatus] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.DIALER_TOUR_CALL_STATUS
  );

  useEffect(() => {
    setWidgetType(WIDGETS.MAXED_DIALER_TOUR);

    return () => {
      setWidgetType(null);
      setCallStatus(null);
    };
  }, []);

  useEffect(() => {
    setCallStatus(callStatus);
  }, [callStatus]);

  return { callStatus: callStatusFromSearch as DialerCallStatusI };
};
