import { useQuery } from "@tanstack/react-query";

import { UploadLeadsSidebarQueryKeys } from "./keys";
import { useApiClient } from "@/context/api-client";

/**
 * This query is responsible for fetching AEs, which are assigned to an ingestion
 * as meeting takers.
 */
export const useFetchUploadLeadsMeetingTakers = () => {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: UploadLeadsSidebarQueryKeys.meetingTakers(),
    queryFn: apiClient.ingestions.getMeetingTakers,
    select: (response) => response.data.meeting_takers,
  });
};
