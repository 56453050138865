import _reject from "lodash/reject";
import _uniqBy from "lodash/uniqBy";

import { ListPillI } from "@/api/routes/list";
import { PIPELINE_LIST_TYPES } from "@/modules/pipeline/open/constants";

import {
  selectDefaultColumns,
  DIALER_STATUS_LIST_COLUMN,
  UNIQUE_BOOKED_MEETING_LIST_COLUMNS,
  STATUS_LIST_COLUMN,
  NEXT_TOUCH_TIME_COLUMN,
  COLD_LIST_UNIQUE_COLUMNS,
  COLUMNS_MAP,
} from "./index";
import {
  DIALER_LIST_STATUS_COLUMN_INDEX,
  SYSTEM_LIST_NUMBER_OF_CONVERSATIONS_COLUMN_INDEX,
  SYSTEM_LIST_STATUS_COLUMN_INDEX,
} from "../constants";
import { ListSubsectionI } from "@/modules/pipeline/lists/list/interfaces";
import {
  LIST_SUBSECTIONS_MAP,
  LIST_TABLE_COLUMNS,
  SYSTEM_LIST_TYPES,
  SYSTEM_NURTURE_LIST_TYPES,
} from "@/modules/pipeline/lists/list/constants";
import {
  LiteralTypeFromArray,
  ValueOfObjectFields,
} from "shared/lib/interfaces/utils";

const selectUniqueListsTableColumns = (list: ListPillI | undefined) => {
  if (list?.list_type === SYSTEM_LIST_TYPES.BOOKED_MEETINGS) {
    return UNIQUE_BOOKED_MEETING_LIST_COLUMNS;
  }

  return [];
};

const selectDialerListColumns = (list: ListPillI | undefined) => {
  const defaultColumns = selectDefaultColumns(list);

  defaultColumns.splice(
    DIALER_LIST_STATUS_COLUMN_INDEX,
    0,
    DIALER_STATUS_LIST_COLUMN,
    ...selectUniqueListsTableColumns(list)
  );

  return defaultColumns;
};

const selectSystemListColumns = (list: ListPillI | undefined) => {
  const defaultColumns = selectDefaultColumns(list);

  defaultColumns.splice(
    SYSTEM_LIST_STATUS_COLUMN_INDEX,
    0,
    STATUS_LIST_COLUMN(list),
    ...selectUniqueListsTableColumns(list)
  );

  return defaultColumns;
};

const selectNurtureListColumns = (list: ListPillI | undefined) => {
  const defaultColumns = selectDefaultColumns(list);

  defaultColumns.splice(
    SYSTEM_LIST_NUMBER_OF_CONVERSATIONS_COLUMN_INDEX,
    0,
    NEXT_TOUCH_TIME_COLUMN,
    ...selectUniqueListsTableColumns(list)
  );

  // TODO uniqBy should be removed
  return _uniqBy(defaultColumns, "id");
};

const selectColdListColumns = (list: ListPillI | undefined) => {
  const defaultColumns = selectDefaultColumns(list);

  defaultColumns.splice(
    SYSTEM_LIST_NUMBER_OF_CONVERSATIONS_COLUMN_INDEX - 1,
    0,
    ...COLD_LIST_UNIQUE_COLUMNS,
    ...selectUniqueListsTableColumns(list)
  );

  const clearColumns = _reject(defaultColumns, ({ id }) =>
    [
      LIST_TABLE_COLUMNS.NUMBER_OF_DIALS,
      LIST_TABLE_COLUMNS.LAST_CONVERSATION,
    ].includes(id as any)
  );

  clearColumns.splice(
    4,
    0,
    COLUMNS_MAP[LIST_TABLE_COLUMNS.NUMBER_OF_CONVERSATIONS]({
      enableHiding: true,
    })
  );

  // TODO uniqBy should be removed
  return _uniqBy(clearColumns, "id");
};

export const selectListType = ({
  list,
  isSearchMode,
  isDialing,
  listSubsection,
  isNurtureList,
}: {
  list: ListPillI | undefined;
  isSearchMode?: boolean;
  isDialing?: boolean;
  listSubsection?: ListSubsectionI;
  isNurtureList?: boolean | null;
}) => {
  if (isDialing) {
    return PIPELINE_LIST_TYPES.DIALER;
  }

  /**
   * isNurtureList === true means that this list is a "new flow" list, supporting
   * nurture (or cold) subsections
   */
  if (isNurtureList) {
    /**
     * If the subsection filter hasn't been set yet, and we're in a "new flow" list,
     * we default to the nurture subsection
     */
    if (listSubsection === undefined) {
      return PIPELINE_LIST_TYPES.NURTURE_LIST;
    }

    return listSubsection === LIST_SUBSECTIONS_MAP.NURTURE ||
      SYSTEM_NURTURE_LIST_TYPES.includes(
        list?.list_type as LiteralTypeFromArray<
          typeof SYSTEM_NURTURE_LIST_TYPES
        >
      )
      ? PIPELINE_LIST_TYPES.NURTURE_LIST
      : PIPELINE_LIST_TYPES.COLD_LIST;
  }

  if (!list || !list.is_default_list || isSearchMode) {
    return PIPELINE_LIST_TYPES.DEFAULT;
  }

  return PIPELINE_LIST_TYPES.SYSTEM_LIST;
};

export const selectorListsTableColumns = (
  listType: ValueOfObjectFields<typeof PIPELINE_LIST_TYPES>,
  { list }: { list: ListPillI | undefined }
) => {
  switch (listType) {
    case PIPELINE_LIST_TYPES.DEFAULT:
      return selectDefaultColumns(list);
    case PIPELINE_LIST_TYPES.DIALER:
      return selectDialerListColumns(list);
    case PIPELINE_LIST_TYPES.SYSTEM_LIST:
      return selectSystemListColumns(list);
    case PIPELINE_LIST_TYPES.NURTURE_LIST:
      return selectNurtureListColumns(list);
    case PIPELINE_LIST_TYPES.COLD_LIST:
      return selectColdListColumns(list);
    default:
      return selectDefaultColumns(list);
  }
};
