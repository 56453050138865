import { useEffect, useRef, useState } from "react";

import { LoadingComponent } from "@/components/modules/loading";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import Calling from "@/helpers/calling";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { BasicButton } from "@/modules/calling/control-menu/v2/buttons/basic-button";
import { clsxMerge } from "shared/lib/helpers";
import { PhoneIcon } from "shared/ui/icons";
import { PhoneNumbersMenu } from "./phone-numbers-menu";

const START_CALL_BUTTON_ID = "start-call-button";

export const StartCallButton = ({
  className,
  onClick,
  isDisabled,
}: {
  className?: string;
  onClick?: () => Promise<void>;
  isDisabled?: boolean;
}) => {
  const context = useCallingContext();
  const inCallContext = useInCallContext();
  const callStatus = useDialerCallStatus();

  const { contact, setStartAt, setEndAt } = inCallContext;

  const [isLoading, setIsLoading] = useState(false);

  //NOTE default call is on mobile numbers
  const onCallHandler = (isPhone?: boolean) => async () => {
    console.log(isPhone);

    setIsLoading(true);
    setStartAt("");
    setEndAt("");

    if (onClick) {
      await onClick();
    } else {
      await Calling.startCall({
        context,
        inCallContext,
        contactId: contact?.id,
      });
    }

    setIsLoading(false);
  };

  const timeout = useRef<number>();

  useEffect(() => {
    clearTimeout(timeout.current);

    setIsLoading(true);
    timeout.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [callStatus]);

  return (
    <PhoneNumbersMenu
      onMobileClick={onCallHandler()}
      onPhoneClick={onCallHandler(true)}
      isShowOptions={false}
    >
      <BasicButton
        id={START_CALL_BUTTON_ID}
        className={clsxMerge(
          "btn w-[85px] border-none text-white",
          "bg-[#349538] hover:bg-[#349538]/80",
          {
            "disabled pointer-events-none opacity-50": isLoading || isDisabled,
          },
          className
        )}
        onClick={onCallHandler()}
      >
        {isLoading && (
          <LoadingComponent className="w-full animate-fadein [&>svg]:text-white" />
        )}

        {!isLoading && (
          <>
            <span className="mb-[6px] animate-fadein">
              <PhoneIcon className="h-4 w-4" />
            </span>
            <span className="brand-typography-detail3 animate-fadein  whitespace-nowrap">
              {callStatus === DIALER_CALL_STATUS.AFTER
                ? "Call Again"
                : " Start Call"}
            </span>
          </>
        )}
      </BasicButton>
    </PhoneNumbersMenu>
  );
};
