import { LeadListsWorkspaceHeader } from "@/modules/pipeline/lists/list/workspace/header";
import { LeadListsWorkspaceTable } from "@/modules/pipeline/lists/list/workspace/table";

import {
  LIST_ACTIONS_SLOT_ID,
  LIST_HEADER_AND_ACTIONS_CONTAINER_ID,
} from "@/modules/pipeline/lists/list/workspace/constants";
import { useListsContext } from "@/modules/pipeline/lists/context";

export const LeadListsWorkspace = () => {
  const { onChangeFilters, isNurtureList } = useListsContext();

  return (
    <>
      <div
        id={LIST_HEADER_AND_ACTIONS_CONTAINER_ID}
        className="sticky left-0 top-0 z-[13] min-h-[90px] bg-white px-6 pt-4"
      >
        <LeadListsWorkspaceHeader onChangeFilters={onChangeFilters} />

        {/*This DIV represents the container for the list actions, which are rendered inside LeadListsWorkspaceTable*/}
        <div id={LIST_ACTIONS_SLOT_ID} />
      </div>

      {isNurtureList !== null && (
        <LeadListsWorkspaceTable
          onChangeFilters={onChangeFilters}
          isNurtureList={isNurtureList}
        />
      )}
    </>
  );
};
