export enum WIDGETS_ENUM {
  DIALER = "widget-dialer",
  MAXED_DIALER = "widget-maxed-dialer",
  MAXED_DIALER_TOUR = "widget-maxed-dialer-tour",
}

export const WIDGETS = {
  DIALER: WIDGETS_ENUM.DIALER,
  MAXED_DIALER: WIDGETS_ENUM.MAXED_DIALER,
  MAXED_DIALER_TOUR: WIDGETS_ENUM.MAXED_DIALER_TOUR,
} as const;
