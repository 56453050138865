import { FC } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { pluralizeNoun } from "shared/lib/helpers";
import { TextArea } from "shared/ui";

export const CONFIRM_DISQUALIFY_CONTACTS_FROM_LIST =
  "confirm-disqualify-contacts-from-list";

interface ConfirmDisqualifyContactsFromListPropsI {
  leadsToDisqualifyCount: number;
  onConfirm: (reason: string) => Promise<unknown>;
}

const EnhancedTextArea = TextArea(Field);

const FormSchema = Yup.object().shape({
  reason: Yup.string()
    .max(1000, "Max 1000 characters")
    .min(2, "Please provide a reason")
    .required("Please provide a reason"),
});

export const ConfirmDisqualifyContactsFromList: FC<
  ConfirmDisqualifyContactsFromListPropsI
> = ({ leadsToDisqualifyCount, onConfirm }) => {
  return (
    <Modal
      id={CONFIRM_DISQUALIFY_CONTACTS_FROM_LIST}
      showCloseButton={false}
      title={`Why are you disqualifying ${leadsToDisqualifyCount} ${pluralizeNoun(
        "lead",
        leadsToDisqualifyCount
      )}?`}
      description="Please confirm you want to disqualify the selected leads."
    >
      {(props) => (
        <ConfirmDisqualifyContactsModal onConfirm={onConfirm} {...props} />
      )}
    </Modal>
  );
};

const ConfirmDisqualifyContactsModal: FC<
  ModalChildrenFnPropsI &
    Pick<ConfirmDisqualifyContactsFromListPropsI, "onConfirm">
> = ({ handleClose, onConfirm }) => {
  const handleConfirm = async ({ reason }: { reason: string }) => {
    await onConfirm(reason);
    handleClose();
  };

  return (
    <Formik
      initialValues={{
        reason: "",
      }}
      onSubmit={handleConfirm}
      validationSchema={FormSchema}
    >
      {({ errors, touched, isValid, isSubmitting }) => (
        <Form className="mt-3 flex flex-col gap-3">
          <EnhancedTextArea
            name="reason"
            label="Reason"
            placeholder="Add reason for disqualification."
            errors={errors.reason}
            touched={touched.reason}
          />

          <div className="mt-2 flex justify-end">
            <button
              type="button"
              className={clsx("btn-ae-text mr-2", {
                disabled: isSubmitting,
              })}
              onClick={handleClose}
            >
              Cancel
            </button>

            <button
              type="submit"
              className={clsx("btn-ae-default", {
                disabled: !isValid || isSubmitting,
              })}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
