import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

interface UseFileUploadParamsI {
  maxFileSize?: number;
  allowedMimeTypes?: string[];
  onUploadSuccess?: () => void;
}

const DEFAULT_MAX_FILE_SIZE = 2.5e7; //25MB

/**
 * TODO Move validation to a different layer, it should only be responsible for
 * uploads.
 *
 * @param maxFileSize - Maximum file size in bytes
 * @param getUploadUrl - Function that gets the upload URL for a given file. This will be called when the user selects a file to upload.
 * @param onUploadSuccess - Function that is called when the file is successfully uploaded
 * @returns hook for uploading files to S3
 */
export const useFileUpload = ({
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  onUploadSuccess = () => {},
}: UseFileUploadParamsI) => {
  const [loading, setLoading] = useState(false);

  const validateFile = (file: File) => {
    if (!file) {
      toast.error("Missing file to upload.");
      return false;
    }

    if (file.size > maxFileSize) {
      toast.error(
        `File size too large! Maximum size is ${maxFileSize / 1e6} MB`
      );
      return false;
    }

    return true;
  };

  const uploadFile = async (
    file: File,
    uploadUrl: string,
    headers?: Record<string, string>
  ) => {
    if (!validateFile(file)) {
      return;
    }

    setLoading(true);

    try {
      const config = {
        headers: {
          "content-type": file.type,
          ...headers,
        },
      };

      await axios.put(uploadUrl, file, config);

      // In your component, you probably want to update UI with fresh data from backend
      onUploadSuccess();
    } catch (e) {
      toast.error("Failed to upload file");
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadFile,
    loading,
  };
};
