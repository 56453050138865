import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { useQueryClient } from "@tanstack/react-query";

import { glencocoClientAPI } from "@/api/glencoco";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { AddNewLeadToListForm, AddNewLeadToListFormI } from "./new-lead-form";
import { handleErrorMessage } from "@/helpers/error";
import { openAccountDetails } from "@/helpers/account-details";
import { useGlobalContext } from "@/hooks/use-global-context";
import Router from "@/helpers/router";
import { LISTS_QUERY_KEY } from "@/modules/pipeline/lists/list/queries/keys";

interface AddNewLeadToListModalPropsI {
  onSuccess?: () => void;
  handleClose?: () => void;
}

export const ADD_NEW_LEAD_TO_LIST_MODAL = "add_new_lead_to_list_modal";

const ModalContent: FC<ModalChildrenFnPropsI & AddNewLeadToListModalPropsI> = ({
  onSuccess = () => {},
  handleClose = () => {},
}) => {
  const { push } = useRouter();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { currentCampaign } = useGlobalContext();
  const campaignId = currentCampaign?.id;

  const handleSubmit = async (data: AddNewLeadToListFormI) => {
    if (campaignId) {
      const API = glencocoClientAPI();
      setLoading(true);

      const resp = await API.addLeadNewAccount(campaignId, {
        company_name: data.account_name,
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        phone: data.phone,
        mobile: data.mobile,
        email: data.email,
        linkedin_url: data.linkedin_url,
        list_id: data.list_id,
      }).catch((e) => e);

      if (resp.status === 200) {
        toast.success("Success");

        openAccountDetails({
          campaignId,
          accountId: resp.data.account?.id,
        });

        if (data.list_id) {
          // Navigate to the list, which has been selected to add this lead to
          await push({
            search: Router.searchQueryParams.listId(data.list_id),
          });

          await queryClient.invalidateQueries({
            queryKey: [LISTS_QUERY_KEY, data.list_id],
          });
        }

        onSuccess();
        handleClose();
      } else {
        handleErrorMessage(
          resp?.response?.data?.error_code as number,
          "Failed to add new lead. Please contact Glencoco."
        );
      }

      setLoading(false);
    }
  };

  return (
    <AddNewLeadToListForm
      campaignId={currentCampaign?.id}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      loading={loading}
    />
  );
};

export const AddNewLeadToListModal: FC<AddNewLeadToListModalPropsI> = ({
  onSuccess,
}) => (
  <Modal id={ADD_NEW_LEAD_TO_LIST_MODAL}>
    {(props) => <ModalContent {...props} onSuccess={onSuccess} />}
  </Modal>
);
