import { FC, useEffect, useState } from "react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";

import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { Checkbox } from "shared/ui/ae-user-input";

const CheckboxField = Checkbox();

interface FilterTimezonesPropsI extends ControllableComponentPropsI<string[]> {
  value?: string[];
}

export const LEAD_LISTS_TIMEZONES = ["EST", "CST", "MST", "PST"];

export const FilterTimezones: FC<FilterTimezonesPropsI> = ({
  value,
  onChange,
}) => {
  const { isEnabled } = useDialerGlobalContext();

  const [selected, setSelected] = useState<string[]>(
    value || LEAD_LISTS_TIMEZONES
  );

  const handleChange = (triggeredTimezone: string) => {
    let updatedSelection: string[];

    if (selected.includes(triggeredTimezone)) {
      updatedSelection = selected.filter((t) => t !== triggeredTimezone);
    } else {
      updatedSelection = [...selected, triggeredTimezone];
    }

    /**
     * If all timezones selected, don't send any, endpoint will act like all are
     * selected.
     */
    onChange?.(
      updatedSelection.length === LEAD_LISTS_TIMEZONES.length
        ? []
        : updatedSelection
    );

    setSelected([...updatedSelection]);
  };

  useEffect(() => {
    if (selected.length === 0) {
      setSelected(LEAD_LISTS_TIMEZONES);
    }
  }, [selected]);

  return (
    <div
      className={clsxMerge("flex items-center justify-between", {
        "pointer-events-none": isEnabled,
      })}
    >
      <span className="flex gap-2">
        <GlobeAltIcon className="h-4 w-4" />
        <span className="typography-body-4">Timezone</span>
      </span>

      <div className="flex gap-2">
        {LEAD_LISTS_TIMEZONES.map((timezone) => (
          <CheckboxField
            key={timezone}
            name={timezone}
            label={timezone}
            labelClassName="p-0"
            labelTextClassName="ml-2"
            inputProps={{
              checked: selected.includes(timezone),
              onChange: () => handleChange(timezone),
            }}
          />
        ))}
      </div>
    </div>
  );
};
