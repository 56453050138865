import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { LeadListsWorkspaceHeaderMainInfo as ListMainInfo } from "./info/main-info";
import { LeadListsWorkspaceHeaderPropsI } from "./interface";
import { LeadListsWorkspaceHeaderActions } from "@/modules/pipeline/lists/list/workspace/header/actions";

import { useListsContext } from "@/modules/pipeline/lists/context";
import { LIST_SUBSECTIONS_MAP } from "@/modules/pipeline/lists/list/constants";

export const LeadListsWorkspaceHeader: FC<LeadListsWorkspaceHeaderPropsI> = ({
  className,
  onChangeFilters,
}) => {
  const { isPendingListDetails, filters, setSearchTerm, isNurtureList } =
    useListsContext();

  const isNurtureMode =
    isNurtureList && filters?.list_subsection === LIST_SUBSECTIONS_MAP.NURTURE;

  if (isPendingListDetails) {
    return null;
  }

  return (
    <div className={clsxMerge("flex flex-col bg-white", className)}>
      <div className="flex w-full justify-between">
        <ListMainInfo
          onChangeFilters={onChangeFilters}
          className="mr-3"
          isNurtureMode={isNurtureMode}
        />

        <LeadListsWorkspaceHeaderActions setSearchTerm={setSearchTerm} />
      </div>

      <div className="mb-7 mt-6 h-[1px] w-20 bg-[#ccc]" />
    </div>
  );
};
