import { FC } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { TextArea, TextField } from "shared/ui/ae-user-input";
import { clsxMerge } from "shared/lib/helpers";

const EnhancedTextField = TextField(Field);
const EnhancedTextArea = TextArea(Field);

export interface CreateOrUpdateListFormI {
  name: string;
  description?: string;
}

interface CreateOrUpdateListFormPropsI
  extends Partial<CreateOrUpdateListFormI> {
  onSubmit?: (data: CreateOrUpdateListFormI) => void;
  onCancel?: () => void;
}

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .max(1000, "Max 1000 characters")
    .min(2, "Name with more than 2 characters required")
    .required("Please provide a name"),
  description: Yup.string().max(1000, "Max 1000 characters"),
});

export const CreateOrUpdateListForm: FC<CreateOrUpdateListFormPropsI> = ({
  name = "",
  description = "",
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const form: CreateOrUpdateListFormI = {
    name,
    description,
  };

  const handleSubmit = (data: CreateOrUpdateListFormI) => {
    onSubmit(data);
  };

  return (
    <section className="h-full">
      <Formik
        enableReinitialize={true}
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="flex h-[inherit] flex-col justify-between">
            <section>
              <EnhancedTextField
                name="name"
                placeholder="Add a list name - Eg. 'East coast leads' or 'Good decision maker titles'"
                label="List name"
                labelClassName="text-black b-typography-h6"
                inputClassName="bg-white focus:bg-white"
                errors={errors.name}
                touched={touched.name}
              />

              <EnhancedTextArea
                name="description"
                placeholder="Add a list name - Eg. 'East coast leads' or 'Good decision maker titles'"
                label="Description"
                labelClassName="text-black b-typography-h6"
                inputClassName="max-h-[50vh] min-h-[220px] bg-white"
                errors={errors.description}
                touched={touched.description}
              />
            </section>

            <section
              className={clsxMerge(
                "flex items-center justify-between",
                "rounded-md bg-black/10",
                "p-4"
              )}
            >
              <button
                type="button"
                className={clsxMerge("btn-b-white", isSubmitting && "disabled")}
                onClick={onCancel}
              >
                CANCEL
              </button>

              <button
                type="submit"
                className={clsxMerge(
                  "btn-ae-default min-w-[120px]",
                  isSubmitting && "loading disabled"
                )}
              >
                SAVE
              </button>
            </section>
          </Form>
        )}
      </Formik>
    </section>
  );
};
