import { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { toast } from "react-hot-toast";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";

import {
  IngestionFieldAIMapping,
  IngestionFieldsI,
  MiscI,
} from "@/interfaces/ingestion";
import { IngestionMappingI } from "@/interfaces/ingestion/request";
import FieldsMapper from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/fields-mapper";
import PreviewCard from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/preview-lead-card";
import DangerousActionConfirmationModal, {
  DANGEROUS_ACTION_CONFIRMATION_MODAL_ID,
} from "@/components/modals/dangerous-action-confirmation-modal";

import { INGESTION_FIELDS, INGESTION_TYPES } from "@/constants/ingestion";

import { modal } from "@/components/modals";
import {
  GC_FIELDS,
  INGESTION_TABLE_TYPES,
} from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/constants";
import {
  CustomFieldI,
  FieldsMapperGlencocoFieldI,
  IngestionTypesI,
} from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/interfaces";
import { IngestionFieldMapItemI } from "@/interfaces/ingestion";
import CustomFieldsManager from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/fields-mapper/custom-fields-mananger";
import { useUploadLeadsSidebarContext } from "../../../context";
import { SubmitIngestionFieldsRequestI } from "@/api/routes/ingestions";

interface FieldsMapFormPropsI {
  campaignId: string;
  ingestionId: string;
  ingestionType?: IngestionTypesI;
  ingestionFields: IngestionFieldMapItemI[];
  ingestionAIMappings: IngestionFieldAIMapping[];
  onNextStep?: () => void;
  onCancel?: () => void;
  description?: string;
}

type IngestionFieldKeyI = keyof IngestionFieldsI;

type FieldsMapI = {
  [key in IngestionFieldKeyI]: string;
};

const FieldsMapForm: FC<FieldsMapFormPropsI> = ({
  ingestionType,
  ingestionFields,
  ingestionAIMappings,
  onNextStep = () => {},
  onCancel = () => {},
  description,
}) => {
  const [fieldsMap, setFieldsMap] = useState<FieldsMapI>({} as FieldsMapI);

  const fieldAIMap = useMemo(
    () => keyBy(ingestionAIMappings, "glenColumnName"),
    [ingestionAIMappings]
  );

  const { setIngestionRequest } = useUploadLeadsSidebarContext();

  const [prospectIngestionFields, setProspectIngestionFields] = useState<
    IngestionFieldMapItemI[]
  >([]);
  const [accountIngestionFields, setAccountIngestionFields] = useState<
    IngestionFieldMapItemI[]
  >([]);
  const [leadIngestionFields, setLeadIngestionFields] = useState<
    IngestionFieldMapItemI[]
  >([]);
  const [errors, setErrors] = useState<IngestionFieldKeyI[]>([]);

  useEffect(() => {
    if (ingestionFields.length > 0) {
      const prospectFields: IngestionFieldMapItemI[] = [];
      const accountFields: IngestionFieldMapItemI[] = [];
      const leadFields: IngestionFieldMapItemI[] = [];

      ingestionFields.forEach((ingestionField) => {
        const value = ingestionField?.value;

        if (value?.includes("contact.")) {
          prospectFields.push(ingestionField);
        } else if (value?.includes("account.")) {
          accountFields.push(ingestionField);
        } else if (value?.includes("lead.")) {
          leadFields.push(ingestionField);
        }
      });

      setProspectIngestionFields(prospectFields);
      setAccountIngestionFields(accountFields);
      setLeadIngestionFields(leadFields);
    }
  }, [ingestionFields.length]);

  const handleFieldMapperChange =
    (gcFieldName: IngestionFieldKeyI) => (integrationFieldName: string) => {
      fieldsMap[gcFieldName] = integrationFieldName;

      setFieldsMap({ ...fieldsMap });
    };

  const handleFieldMapperFocus = (gcFieldName: IngestionFieldKeyI) => () => {
    setErrors(
      errors.filter((errorFieldName) => errorFieldName !== gcFieldName)
    );
  };

  const getSampleValueForField = (integrationFieldName?: string) => {
    if (typeof integrationFieldName !== "string") {
      return undefined;
    }

    return ingestionFields.find(
      (intFieldObj) => intFieldObj.value === integrationFieldName
    )?.sample;
  };

  const handleCustomFieldsChange = (customFields: CustomFieldI[]) => {
    setFieldsMap({
      ...fieldsMap,
      ["miscs" as IngestionFieldKeyI]: customFields.map((customField) => ({
        key: customField.gcFieldValue,
        value: customField.crmFieldValue,
        label: customField.gcFieldLabel,
        sample: getSampleValueForField(customField.crmFieldValue),
      })) as MiscI[],
    });
  };

  const getConditionalFieldsOptions = (gcf: FieldsMapperGlencocoFieldI) => {
    if (
      [INGESTION_TYPES.CSV as string, INGESTION_TYPES.CSV_SUPPRESSION].includes(
        ingestionType as string
      )
    ) {
      return ingestionFields;
    }

    // NOTE
    // Prospect fields can be contact. or lead.
    // Account fields can be account. or lead.
    // IF mapping includes contact or account it can not include lead and vice versa

    //IF any mapped fields include "lead." including custom fields

    // TODO MAKE explicit message that why you can have only one group of dropdowns in each case.
    const miscs = (fieldsMap?.miscs || []) as unknown as MiscI[];

    const isLead =
      Object.values(fieldsMap).some((fieldValue) =>
        fieldValue?.toLocaleLowerCase?.()?.includes("lead.")
      ) ||
      miscs.some((customField) =>
        customField?.value?.toLocaleLowerCase?.()?.includes("lead.")
      );

    if (isLead) {
      return leadIngestionFields;
    }

    const isContactOrAccountSelected =
      Object.values(fieldsMap).some(
        (fieldValue) =>
          fieldValue?.toLocaleLowerCase?.()?.includes("contact.") ||
          fieldValue?.toLocaleLowerCase?.()?.includes("account.")
      ) ||
      miscs.some((customField) =>
        customField?.value?.toLocaleLowerCase?.()?.includes("contact.")
      );

    const isContact = gcf.label?.toLowerCase?.()?.includes("prospect");
    const isAccount = gcf.label?.toLowerCase?.()?.includes("account");

    if (isContactOrAccountSelected) {
      if (isContact) {
        return prospectIngestionFields;
      }

      if (isAccount) {
        return accountIngestionFields;
      }
    } else {
      if (isContact) {
        return [...prospectIngestionFields, ...leadIngestionFields];
      }

      if (isAccount) {
        return [...accountIngestionFields, ...leadIngestionFields];
      }
    }

    return ingestionFields;
  };

  const getConditionalCustomFieldsOptions = () => {
    if (ingestionType === INGESTION_TYPES.CSV) {
      return ingestionFields;
    }

    const miscs = fieldsMap?.miscs as unknown as MiscI[];

    if (!miscs || !miscs?.length) {
      return [...prospectIngestionFields, ...leadIngestionFields];
    }

    const fieldValues = Object.values(fieldsMap);

    const isLead =
      fieldValues.some((fieldValue) => fieldValue.includes("lead.")) ||
      miscs.some((customField) => customField?.value?.includes("lead."));

    if (isLead) {
      return leadIngestionFields;
    }

    const isContact =
      fieldValues.some(
        (fieldValue) =>
          fieldValue.includes("contact.") || fieldValue.includes("account.")
      ) ||
      miscs.some((customField) => customField?.value?.includes("contact."));

    if (isContact) {
      return prospectIngestionFields;
    }

    return [...prospectIngestionFields, ...leadIngestionFields];
  };

  const confirmMappings = () => {
    modal.trigger(DANGEROUS_ACTION_CONFIRMATION_MODAL_ID);
  };

  const handleSubmit = () => {
    /**
      {
        "ingestion_mappings": [
            {
                "table_type": "Contact"
                "data_column_name": "email",
                "glen_column_name": "email"
            },
        ],
        "ingestion_id": "735b5f4a-c092-4326-a2a3-adf27619bb32"
      }

      and these are the possible value pairs for table_type + glen_column_name
      table_type: glen_column_name

      contact: first_name
      contact: last_name
      contact: title
      contact: email
      contact: phone
      account: name
      account: state
      contact: mobile
      contact: linkedin_url
      account: industry
      account: website
      account: city
      account: headcount
      account:revenue

      For custom column names
      contact: meta.<field_name>
    */

    const accountIngestion = Object.values(INGESTION_FIELDS.ACCOUNT).map(
      (key) =>
        ({
          table_type: "Account",
          data_column_name: fieldsMap[key] as string,
          glen_column_name: key as string,
        } as IngestionMappingI)
    );

    const contactIngestion = Object.values(INGESTION_FIELDS.CONTACT).map(
      (key) =>
        ({
          table_type: "Contact",
          data_column_name: fieldsMap[key] as string,
          glen_column_name: key as string,
        } as IngestionMappingI)
    );

    const miscsIngestion =
      (fieldsMap.miscs as unknown as MiscI[])
        ?.filter((misc) => misc?.key && misc?.value && misc?.label)
        ?.map(
          (misc) =>
            ({
              table_type: "Contact",
              data_column_name: misc.value,
              glen_column_name: `meta.${misc.key}`,
              label: misc.label as string,
            } as IngestionMappingI)
        ) || [];

    const ingestionRequest: SubmitIngestionFieldsRequestI = {
      ingestion_mappings: [
        ...accountIngestion,
        ...contactIngestion,
        ...miscsIngestion,
      ],
    };

    if (INGESTION_TYPES.CSV === ingestionType) {
      ingestionRequest.ingestion_mappings = [
        {
          table_type: "Account",
          data_column_name: fieldsMap[INGESTION_FIELDS.OWNER_ID] as string,
          glen_column_name: INGESTION_FIELDS.OWNER_ID,
        } as IngestionMappingI,
        ...ingestionRequest.ingestion_mappings,
      ];
    }

    if (INGESTION_TYPES.CSV_SUPPRESSION === ingestionType) {
      ingestionRequest.ingestion_mappings = Object.values(
        INGESTION_FIELDS.SUPPRESSION
      ).map(
        (key) =>
          ({
            table_type: INGESTION_TABLE_TYPES.SUPPRESSION,
            data_column_name: fieldsMap[key] as string,
            glen_column_name: key as string,
          } as IngestionMappingI)
      );
    }

    // NOTE - First check required fields
    const requiredGcFields = GC_FIELDS[ingestionType as IngestionTypesI]
      ?.filter((gcf) => gcf.required)
      ?.map((gcf) => gcf.value as string);

    let errors: IngestionFieldKeyI[] = [];
    ingestionRequest.ingestion_mappings.forEach((field) => {
      if (
        requiredGcFields.includes(field.glen_column_name) &&
        !field.data_column_name
      ) {
        errors.push(field.glen_column_name as IngestionFieldKeyI);
      }
    });

    setErrors(errors);

    if (errors?.length) {
      toast.error("Please fill out all required fields.");
      return;
    }

    // NOTE - Check fields where only one is required
    errors = [];
    const groups = Object.values(
      groupBy(
        GC_FIELDS[ingestionType as IngestionTypesI] || [],
        ({ group }) => group
      ) || {}
    );

    groups.forEach((group) => {
      const oneOfGroupRequiredGcFields = group
        ?.filter((gcf) => gcf.isOneOfGroupRequired)
        ?.map((gcf) => gcf.value as string);

      const isAnyFilled = ingestionRequest.ingestion_mappings.some(
        (field) =>
          oneOfGroupRequiredGcFields.includes(field.glen_column_name) &&
          !!field.data_column_name
      );

      if (!isAnyFilled) {
        ingestionRequest.ingestion_mappings.forEach((field) => {
          if (oneOfGroupRequiredGcFields.includes(field.glen_column_name)) {
            errors.push(field.glen_column_name as IngestionFieldKeyI);
          }
        });
      }
    });

    setErrors(errors);

    if (errors?.length) {
      toast.error("At least one field is required.");
      return;
    }

    ingestionRequest.ingestion_mappings =
      ingestionRequest.ingestion_mappings.filter(
        (ingestionField) =>
          ingestionField.glen_column_name && ingestionField.data_column_name
      );

    /**
     * Persist created ingestion to the context for sending to BE after AE
     * selection
     */
    setIngestionRequest(ingestionRequest);

    onNextStep();
  };

  const getLocation = () => {
    const city = getSampleValueForField(
      fieldsMap[INGESTION_FIELDS.ACCOUNT.CITY]
    );

    const state = getSampleValueForField(
      fieldsMap[INGESTION_FIELDS.ACCOUNT.STATE]
    );

    return city ? `${city}, ${state}` : state;
  };

  const contactPhoneNumberSampleValue = useMemo(() => {
    if (INGESTION_TYPES.CSV_SUPPRESSION === ingestionType) {
      return getSampleValueForField(
        fieldsMap[INGESTION_FIELDS.SUPPRESSION.PHONE_NUMBER]
      );
    }

    /**
     * INGESTION_FIELDS.CONTACT.PHONE is preferred over INGESTION_FIELDS.CONTACT.MOBILE,
     * because it's used in the preview card
     */
    return (
      getSampleValueForField(fieldsMap[INGESTION_FIELDS.CONTACT.PHONE]) ||
      getSampleValueForField(fieldsMap[INGESTION_FIELDS.CONTACT.MOBILE])
    );
  }, [fieldsMap, ingestionType, getSampleValueForField]);

  return (
    <article>
      <section>
        <div className="mb-6">
          <p className="ae-typography-body-1">{description}</p>
          <p className="ae-typography-detail1 font-normal text-black/60">
            Fields marked with a * are required.
          </p>
        </div>

        <div className="flex gap-x-14 pb-36">
          <div className="w-[540px]">
            <div className="mb-4 grid grid-cols-2 gap-x-8">
              <h4 className="ae-typography-h4">GlenX field</h4>

              <div className="flex justify-between">
                <h4 className="ae-typography-h4">Field from your CSV</h4>

                {ingestionAIMappings && ingestionAIMappings.length && (
                  <span className="gradient-text animate-fadein typography-body-4-bold">
                    Auto mapped
                  </span>
                )}
              </div>
            </div>

            {GC_FIELDS[ingestionType as IngestionTypesI].map(
              (glencocoField, index) => {
                return (
                  <FieldsMapper
                    key={index}
                    gcField={glencocoField}
                    disabled={!ingestionFields.length}
                    autoMappedValue={
                      fieldAIMap[glencocoField.value]?.dataColumnName
                    }
                    value={fieldsMap[glencocoField.value]}
                    onChange={handleFieldMapperChange(glencocoField.value)}
                    isError={
                      !!errors.find(
                        (errorFieldName) =>
                          glencocoField.value === errorFieldName
                      )
                    }
                    options={getConditionalFieldsOptions(glencocoField)}
                    onFocus={handleFieldMapperFocus(glencocoField.value)}
                  />
                );
              }
            )}

            {INGESTION_TYPES.CSV_SUPPRESSION !== ingestionType &&
              !!ingestionFields.length && (
                <CustomFieldsManager
                  max={3}
                  options={getConditionalCustomFieldsOptions()}
                  onChange={handleCustomFieldsChange}
                />
              )}
          </div>

          <div className="relative">
            <PreviewCard
              first_name={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.CONTACT.FIRST_NAME]
              )}
              last_name={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.CONTACT.LAST_NAME]
              )}
              linkedin_url={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.CONTACT.LINKEDIN_URL]
              )}
              title={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.CONTACT.TITLE]
              )}
              name={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.ACCOUNT.NAME]
              )}
              website={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.ACCOUNT.WEBSITE]
              )}
              phone={contactPhoneNumberSampleValue}
              email={
                INGESTION_TYPES.CSV_SUPPRESSION === ingestionType
                  ? getSampleValueForField(
                      fieldsMap[INGESTION_FIELDS.SUPPRESSION.EMAIL_DOMAIN]
                    )
                  : getSampleValueForField(
                      fieldsMap[INGESTION_FIELDS.CONTACT.EMAIL]
                    )
              }
              headcount={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.ACCOUNT.HEADCOUNT]
              )}
              industry={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.ACCOUNT.INDUSTRY]
              )}
              revenue={getSampleValueForField(
                fieldsMap[INGESTION_FIELDS.ACCOUNT.REVENUE]
              )}
              state={getLocation()}
              miscs={fieldsMap.miscs as unknown as MiscI[]}
              config={{
                ingestionType,
                info: "This is how your lead information will be displayed to cold callers",
              }}
            />
          </div>
        </div>

        <section
          className={clsx(
            "z-100 fixed bottom-0 left-0 flex w-full justify-end gap-3  py-4 pr-[22px]"
          )}
        >
          <button className="btn-nofill border-none" onClick={onCancel}>
            Cancel
          </button>

          <button
            className="btn-ae-default w-[197px]"
            onClick={confirmMappings}
          >
            Next
          </button>
        </section>
      </section>

      <DangerousActionConfirmationModal
        title="Are your mappings correct?"
        description="Please double check your mappings as you will need to start over if you would like to make any changes"
        mainButtonText="Yes, they are correct"
        cancelButtonText="View mappings"
        callback={handleSubmit}
      />
    </article>
  );
};

export default FieldsMapForm;
