import { FC, useState } from "react";
import { Modal } from "shared/ui";
import UICheckbox from "shared/ui/ae-user-input/checkbox";

const Checkbox = UICheckbox();

export const CONFIRM_ADD_TO_LIST_MODAL_ID = "confirm-add-to-list-modal";

interface ConfirmAddToListModalPropsI {
  leadsToAddCount: number;
  targetListNames: string[];
  onConfirm: (isRemoveLeads: boolean) => void;
}

export const ConfirmAddToListModal: FC<ConfirmAddToListModalPropsI> = ({
  leadsToAddCount,
  targetListNames,
  onConfirm,
}) => {
  const [isRemoveLeads, setIsRemoveLeads] = useState(false);

  const onChangeHandler = () => {
    setIsRemoveLeads((isRemoveLeads) => !isRemoveLeads);
  };

  return (
    <Modal
      id={CONFIRM_ADD_TO_LIST_MODAL_ID}
      showCloseButton={false}
      title={`Add these ${leadsToAddCount} leads to the specified list(s)?`}
      description={`Please confirm that you want to add ${leadsToAddCount} selected lead(s) to the folowing lists: ${targetListNames.join(
        ", "
      )}.`}
    >
      {({ handleClose }) => {
        const handleCancel = () => {
          handleClose();
        };

        const handleConfirm = () => {
          onConfirm(isRemoveLeads);
          handleClose();
        };

        return (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox
                id={`${CONFIRM_ADD_TO_LIST_MODAL_ID}-checkbox`}
                name={`${CONFIRM_ADD_TO_LIST_MODAL_ID}-checkbox`}
                className="-mr-3"
                inputProps={{
                  checked: isRemoveLeads,
                  onChange: onChangeHandler,
                }}
              />

              <label
                className="brand-typography-body3 cursor-pointer"
                htmlFor={`${CONFIRM_ADD_TO_LIST_MODAL_ID}-checkbox`}
              >
                Remove leads from current list
              </label>
            </div>
            <div className="flex justify-end gap-3">
              <button className="btn-ae-text" onClick={handleCancel}>
                Cancel
              </button>

              <button className="btn-ae-default" onClick={handleConfirm}>
                Yes, add to list
              </button>
            </div>
          </div>
        );
      }}
    </Modal>
  );
};
