import { MeetingTakerDropdown } from "@/modules/pipeline/account-details/components/shared/meeting-taker-dropdown";
import { MeetingTakerI } from "@/interfaces/accounts";
import { Modal } from "shared/ui";
import CalendarForm, { CalendarDateI } from "shared/ui/calendar-form";
import { TextArea } from "shared/ui/ae-user-input";
import { useEffect, useMemo, useState } from "react";
import { convertTime12to24, DayJs } from "shared/lib/helpers/date";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { clsxMerge } from "shared/lib/helpers";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { glencocoClientAPI } from "@/api/glencoco";
import toast from "react-hot-toast";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { dispatchCustomEvent } from "@/helpers/events";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { DATE_FORMAT_ONLY_TIME } from "shared/lib/constants/time";

const SimpleTextArea = TextArea();

export const X_BOOK_MEETING_MODAL_ID = "x_book_meeting_modal";

const NOTES_INPUT_ID = `${X_BOOK_MEETING_MODAL_ID}-notes-input`;

const ModalContent = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { modalAccount } = useAccountsContext();

  const [meetingTime, setMeetingTime] = useState<string>();
  const [accountExecutiveId, setAccountExecutiveId] = useState<string>();
  const [notes, setNotes] = useState<string>();

  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    setIsDisabled(!meetingTime || !accountExecutiveId);
  }, [meetingTime, accountExecutiveId]);

  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = ({ date, time, timezone }: CalendarDateI) => {
    const { hours, minutes } = convertTime12to24(time);
    const dateTime = DayJs(date)
      .hour(hours)
      .minute(minutes)
      .tz(TIMEZONES.find((tz) => tz.fullZoneName === timezone)?.tzCode, true);

    setMeetingTime?.(dateTime.toISOString());
  };

  const handleOnChangeMeetingTaker = (meetingTaker: MeetingTakerI) => {
    setAccountExecutiveId(meetingTaker?.id);

    (
      window.document.querySelector(`#${NOTES_INPUT_ID} textarea`) as any
    )?.focus();
  };

  const handleOnNotesChange = (notes: string) => {
    setNotes(notes);
  };

  const handleBookMeeting = async () => {
    setIsLoading(true);

    const campaignId = modalAccount?.campaignId as string;
    const accountId = modalAccount?.accountId as string;
    const contactId = modalAccount?.contact?.id as string;

    const API = glencocoClientAPI();

    const Resp = await API.bookMeeting(campaignId, accountId, contactId, {
      account_executive_id: accountExecutiveId as string,
      meeting_time: meetingTime as string,
      notes,
    }).catch((e) => e);

    if (Resp.status === 200) {
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
      });
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
      });

      onSuccess?.();
    } else {
      toast.error("Failed to book a meeting");

      dd.rum.error(
        `${ERROR_CATEGORIES.BOOK_MEETING} - failed to book a meeting`,
        {
          data: Resp,
        }
      );
    }

    setIsLoading(false);
  };

  const initialTime = useMemo(() => {
    if (!modalAccount?.followUpTime) return;

    const time = DayJs(modalAccount?.followUpTime);

    return time.format(DATE_FORMAT_ONLY_TIME);
  }, [modalAccount?.followUpTime]);

  return (
    <div>
      <CalendarForm
        bookingDeadlineInMonths={false}
        isNotes={false}
        onDateChange={handleOnChange}
        initialDate={modalAccount?.followUpTime}
        initialTime={initialTime}
      />

      <div className="mb-6 flex flex-col">
        <label className="mb-3 typography-body-4">
          Who did you set the meeting for?
        </label>
        <MeetingTakerDropdown
          className="w-full"
          textFieldClassName="w-full"
          onChange={handleOnChangeMeetingTaker}
          defaultValue={modalAccount?.accountExecutive?.id}
        />
      </div>

      <div>
        <SimpleTextArea
          id={NOTES_INPUT_ID}
          name="notes"
          label="Notes"
          placeholder="Type your notes here"
          inputClassName="max-h-[50vh] min-h-[130px] bg-white border border-black/20"
          labelClassName="typography-body-4 text-black"
          inputProps={{
            onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
              handleOnNotesChange(ev.target.value);
            },
          }}
        />
      </div>

      <div className="flex items-center justify-between">
        <span className="typography-body-4-bold">
          This will be moved to your booked meetings list.
        </span>
        <button
          className={clsxMerge(
            "btn-ae-default btn-lg min-w-[170px] typography-header-8-semibold",
            {
              loading: isLoading,
              disabled: isDisabled || isLoading,
            }
          )}
          disabled={isDisabled || isLoading}
          onClick={handleBookMeeting}
        >
          Save meeting
        </button>
      </div>
    </div>
  );
};

export const GlenXBookMeetingModal = ({
  modalId = X_BOOK_MEETING_MODAL_ID,
}: {
  modalId?: string;
  className?: string;
}) => {
  return (
    <Modal
      id={modalId}
      showCloseButton
      modalBoxClassName="overflow-y-auto"
      title="Book meeting"
      titleClassName="typography-header-6 mb-10"
      unmountOnClose
    >
      {({ handleClose }) => <ModalContent onSuccess={handleClose} />}
    </Modal>
  );
};
