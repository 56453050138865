import { DIALER_VERSIONS } from "@/constants/dialer";
import { CallingContentProvider, DialerV1ProviderI } from "@/context/dialer-v1";
import { DialerModeInitializer } from "@/context/dialer-v2/mode-initializer";

export const DialerV2Provider = ({
  children,
  config = {},
  ...props
}: DialerV1ProviderI) => {
  const mode = config?.mode;
  config = {
    ...config,
    mode,
    version: DIALER_VERSIONS.V2,
  };

  return (
    <CallingContentProvider {...props} config={config}>
      <DialerModeInitializer mode={mode} config={config}>
        {children}
      </DialerModeInitializer>
    </CallingContentProvider>
  );
};
