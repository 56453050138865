import { FC } from "react";

import { ListPillI } from "@/api/routes/list";
import { clsxMerge } from "shared/lib/helpers";
import {
  getListContactCountString,
  getListIcon,
} from "@/components/modules/pipeline/lists/list/primary-header/selector/utils";
import { getListName } from "@/modules/pipeline/utils";

interface ListSelectorMenuCategoryPropsI {
  className?: string;
  categoryName: string;
  items: ListPillI[] | undefined;
  activeListId: string | undefined | null;
  onSelect: (newActiveListId: string) => void;
}

export const ListSelectorMenuCategory: FC<ListSelectorMenuCategoryPropsI> = ({
  className,
  categoryName,
  items,
  activeListId,
  onSelect,
}) => {
  return (
    <div className={clsxMerge("flex flex-col gap-2", className)}>
      <h5 className="pl-[30px] pt-3 text-[#999] typography-body-4-bold">
        {categoryName}
      </h5>

      {items && items.length > 0 ? (
        <ul className="overflow-y-auto">
          {items.map((list) => {
            if (!list) {
              return null;
            }

            const isActiveList = list.id === activeListId;

            return (
              <li
                key={list.id}
                className={clsxMerge(
                  "flex cursor-pointer p-2 transition-colors",
                  isActiveList ? "bg-[#4474E3]" : "hover:bg-black/5"
                )}
                onClick={() => onSelect(list.id)}
              >
                <div className={isActiveList ? "text-white" : "text-[#F88167]"}>
                  {getListIcon({
                    list_type: list.list_type,
                    isExclusive: list.is_exclusive_list,
                    isWhiteAwardIcon: isActiveList,
                  })}
                </div>

                <div className="ml-1.5 flex flex-col">
                  <span
                    title={list?.name}
                    className={clsxMerge(
                      "line-clamp-2 typography-body-4-medium",
                      isActiveList ? "text-white" : "text-black"
                    )}
                  >
                    {getListName(list)}
                  </span>

                  <span
                    className={clsxMerge(
                      "typography-body-5-medium",
                      isActiveList ? "text-white" : "text-[#666]"
                    )}
                  >
                    {getListContactCountString(list)}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <span className="px-[30px] text-[#666] typography-body-4">
          There are no lists in this category yet.
        </span>
      )}
    </div>
  );
};
