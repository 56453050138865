import { DIALER_MODES, DIALER_STATES } from "@/constants/dialer";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useEffect } from "react";
import { DirectCallInitialization } from "./direct-call-initializtion";
import { DialerConfigI } from "@/interfaces/dialer/config";
import { DialerWidgetProvider } from "../widget";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";

const UNIQUE_INITIALIZATION_MODES = [DIALER_MODES.WIDGET_MINI_DIALER as string];

export const DialerModeInitializer = ({
  children,
  mode,
  config,
}: {
  children?: any;
  mode?: (typeof DIALER_MODES)[keyof typeof DIALER_MODES];
  config?: DialerConfigI;
}) => {
  const { isEnabled: isGlobalDialer } = useDialerGlobalContext();
  const { setCallingState } = useCallingContext();

  useEffect(() => {
    if (mode) {
      switch (mode) {
        case DIALER_MODES.WIDGET_MINI_DIALER:
          /**
           * We rely in Calling help of Dialing state. If it is not dialing call will be rejected.
           * For now this is only for compatibility purposes with auto dialing
           */
          setCallingState(
            isGlobalDialer ? DIALER_STATES.DIALING : DIALER_STATES.PRE
          );
          break;
        default:
          setCallingState(DIALER_STATES.PRE);
      }
    }
  }, [mode, isGlobalDialer]);

  return (
    <>
      {!UNIQUE_INITIALIZATION_MODES.includes(mode as string) && children}

      {/*|DEPRECTATED| - NOTE WIDGET SUPPORTS DIRECT CALLS AND LIST DIALING*/}
      {DIALER_MODES.WIDGET_MINI_DIALER === mode && (
        <DialerWidgetProvider>
          <DirectCallInitialization config={config} />
          {children}
        </DialerWidgetProvider>
      )}
    </>
  );
};
