import { IngestionFieldsI } from "@/interfaces/ingestion";
import { IngestionAccountFieldsI } from "@/interfaces/ingestion/account";
import { IngestionContactFieldsI } from "@/interfaces/ingestion/contact";
import { IngestionSuppressionFieldsI } from "@/interfaces/ingestion/suppression";

export const INGESTION_FIELDS_ACCOUNT = {
  NAME: "name" as keyof IngestionAccountFieldsI,
  STATE: "state" as keyof IngestionAccountFieldsI,
  INDUSTRY: "industry" as keyof IngestionAccountFieldsI,
  WEBSITE: "website" as keyof IngestionAccountFieldsI,
  CITY: "city" as keyof IngestionAccountFieldsI,
  HEADCOUNT: "headcount" as keyof IngestionAccountFieldsI,
  REVENUE: "revenue" as keyof IngestionAccountFieldsI,
} as const;

export const INGESTION_FIELDS_CONTACT = {
  FIRST_NAME: "first_name" as keyof IngestionContactFieldsI,
  LAST_NAME: "last_name" as keyof IngestionContactFieldsI,
  TITLE: "title" as keyof IngestionContactFieldsI,
  EMAIL: "email" as keyof IngestionContactFieldsI,
  PHONE: "phone" as keyof IngestionContactFieldsI,
  MOBILE: "mobile" as keyof IngestionContactFieldsI,
  LINKEDIN_URL: "linkedin_url" as keyof IngestionContactFieldsI,
} as const;

export const INGESTION_FIELDS_SUPPRESSION = {
  EMAIL_DOMAIN: "email_domain" as keyof IngestionSuppressionFieldsI,
  PHONE_NUMBER: "phone_number" as keyof IngestionSuppressionFieldsI,
} as const;

export const INGESTION_TYPES = {
  MERGE: "merge",
  CSV: "csv",
  CSV_SUPPRESSION: "csv_suppression",
} as const;

export const INGESTION_FIELDS = {
  OWNER_ID: "owner_id" as keyof IngestionFieldsI,
  ACCOUNT: INGESTION_FIELDS_ACCOUNT,
  CONTACT: INGESTION_FIELDS_CONTACT,
  SUPPRESSION: INGESTION_FIELDS_SUPPRESSION,
} as const;

export const INGESTION_STATES = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
} as const;
