import { useState } from "react";

import { glencocoClientAPI } from "@/api/glencoco";
import { useFileUpload } from "shared/lib/hooks/use-file-upload";

export const useUploadProfileImage = () => {
  const [isUploadingProfileImage, setIsUploadingProfileImage] = useState(false);

  const { uploadFile } = useFileUpload({
    onUploadSuccess: () => {
      setIsUploadingProfileImage(false);
    },
  });

  const uploadProfileImage = async (file: File | undefined) => {
    if (!file) {
      return Promise.resolve();
    }

    setIsUploadingProfileImage(true);

    const profileImageUploadURLResponse = await glencocoClientAPI()
      .getResumeImageUploadURL({
        content_size: file?.size,
        content_type: file?.type || "",
      })
      .catch(() => {
        return { status: null, data: null };
      });

    if (
      profileImageUploadURLResponse.status !== 200 ||
      !profileImageUploadURLResponse.data?.upload_url
    ) {
      return Promise.reject();
    }

    const uploadURL = profileImageUploadURLResponse.data.upload_url;

    try {
      await uploadFile(file, uploadURL);
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  };

  return {
    isUploadingProfileImage,
    uploadProfileImage,
  };
};
