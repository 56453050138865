import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import identity from "lodash/identity";
import noop from "lodash/noop";

import {
  APII,
  GetKanbanAccountsResponseI,
  glencocoClientAPI as apiFactory,
  UpdateKanbanAccountParamsI,
  AddLeadToAccountParamsI,
} from "@/api/glencoco";

import { useApi } from "shared/lib/hooks/use-api";
import { ERRORS } from "@/constants/errors";

import { AccountStatusI } from "shared/lib/interfaces/account";
import { AccountExecutiveI } from "@/interfaces/accounts";

export interface ModalAccountDataI {
  campaignId: string;
  accountId: string;
  accountName: string;
  accountStatus: AccountStatusI;
  accountExecutive?: AccountExecutiveI;
  targetAccountStatus?: AccountStatusI;
  cancelMeetingRequestNote?: string;
  contact?: {
    id?: string;
    contact_id?: string;
    title: string;
    name: string;
    email?: string;
  };
  followUpTime?: string;
}

export interface ModalAccountContactI {
  id: string;
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  email: string;
}

type StatusTransitionActionRefI = MutableRefObject<null | (() => any)>;

export interface AccountsContextI {
  selectedCampaignIds: string[];
  setSelectedCampaignIds: Dispatch<SetStateAction<string[]>>;
  isLoadingAccounts: boolean;
  isUpdatingAccounts: boolean;
  modalAccount: ModalAccountDataI | null;
  setModalAccount: Dispatch<SetStateAction<ModalAccountDataI | null>>;
  modalContact: ModalAccountContactI | null;
  setModalContact: Dispatch<SetStateAction<ModalAccountContactI | null>>;
  isAccountUpdating: boolean;
  updateAccountWithCampaign: (
    accountId: string,
    campaignId: string,
    props: UpdateKanbanAccountParamsI
  ) => Promise<any>;
  addLeadToAccount: (
    accountId: string,
    campaignId: string,
    props: AddLeadToAccountParamsI
  ) => Promise<any>;
  loadAccounts: () => Promise<any>;
  openAccountsData: GetKanbanAccountsResponseI | null;
  clearModalAccount: () => void;
  clearModalContact: () => void;
  accountCalendlyUri: any;
  onChangeStatusSuccessRef: StatusTransitionActionRefI;
  onChangeStatusCancelRef: StatusTransitionActionRefI;
  toggleDoNotCall: (
    accountId: string,
    campaignId: string,
    note: string
  ) => Promise<any>;
}

export const AccountsContext = createContext<AccountsContextI>({
  selectedCampaignIds: [],
  setSelectedCampaignIds: identity,
  isLoadingAccounts: false,
  setModalAccount: identity,
  setModalContact: identity,
  isAccountUpdating: false,
  isUpdatingAccounts: false,
  updateAccountWithCampaign: identity,
  addLeadToAccount: identity,
  loadAccounts: () => Promise.resolve(),
  toggleDoNotCall: () => Promise.resolve(),
  modalAccount: null,
  modalContact: null,
  clearModalAccount: noop,
  clearModalContact: noop,
  openAccountsData: null,
  accountCalendlyUri: null,
  onChangeStatusSuccessRef: { current: null },
  onChangeStatusCancelRef: { current: null },
});

const updateAccountWithCampaignFetcher = (
  api: APII,
  accountId: string,
  campaignId: string,
  params: UpdateKanbanAccountParamsI
) => api.updateAccountWithCampaign(accountId, campaignId, params);

const addLeadToAccountFetcher = (
  api: APII,
  accountId: string,
  campaignId: string,
  params: AddLeadToAccountParamsI
) => api.addLeadToAccount(campaignId, accountId, params);

const toggleDoNotCallFetcher = (
  api: APII,
  accountId: string,
  campaignId: string,
  note: string
) => api.toggleDoNotCall(accountId, campaignId, note);

export const AccountsContextProvider: FC<{ children?: any }> = ({
  children,
}) => {
  const onChangeStatusSuccessRef: StatusTransitionActionRefI = useRef(null);
  const onChangeStatusCancelRef: StatusTransitionActionRefI = useRef(null);

  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
  const [modalAccount, setModalAccount] = useState<ModalAccountDataI | null>(
    null
  );

  const clearModalAccount = () => setModalAccount(null);

  const [modalContact, setModalContact] = useState<ModalAccountContactI | null>(
    null
  );
  const clearModalContact = () => setModalContact(null);

  const getAccountsFetcher = useMemo(() => {
    return (api: APII) => {
      return api.getKanbanAccounts({ campaigns: selectedCampaignIds });
    };
  }, [selectedCampaignIds]);

  const [
    { isLoading: isLoadingAccounts, data: openAccountsData },
    loadAccounts,
  ] = useApi({
    fetcher: getAccountsFetcher,
    isInitiallyLoading: false,
    shouldCallAutomatically: false,
    shouldResetDataOnRequest: false,
    apiFactory,
  });

  const isUpdatingAccounts = useMemo(
    () => !!openAccountsData && isLoadingAccounts,
    [isLoadingAccounts, openAccountsData]
  );

  const [
    { isLoading: isAccountWithCampaignUpdating },
    updateAccountWithCampaign,
  ] = useApi({
    apiFactory,
    fetcher: updateAccountWithCampaignFetcher,
    shouldCallAutomatically: false,
    errorBuilder: (resp) =>
      ERRORS[(resp as any)?.response?.data?.error_code as number] ||
      "Failed to update account status",
  });

  // const calendlyUriFetcher = useMemo(() => {
  //   return modalAccount &&
  //     modalAccount.targetAccountStatus === ACCOUNT_STATUSES.MEETING_SCHEDULED
  //     ? (api: APII) =>
  //         api.getAccountCalendlyUri(
  //           modalAccount?.accountId,
  //           modalAccount?.campaignId
  //         )
  //     : null;
  // }, [modalAccount]);

  // const [{ data: accountCalendlyUriData }] = useApi({
  //   fetcher: calendlyUriFetcher,
  //   apiFactory,
  //   errorBuilder: () => "Failed to get calendly uri, please try again",
  //   onError: () => {
  //     if (onChangeStatusCancelRef.current) {
  //       onChangeStatusCancelRef.current();
  //       onChangeStatusCancelRef.current = null;
  //     }

  //     setModalAccount(null);
  //   },
  // });

  const [, toggleDoNotCall] = useApi({
    fetcher: toggleDoNotCallFetcher,
    apiFactory,
    errorBuilder: () => "Failed to toggle do not call",
    shouldCallAutomatically: false,
  });

  const [, addLeadToAccount] = useApi({
    fetcher: addLeadToAccountFetcher,
    apiFactory,
    errorBuilder: () => "Failed to add lead to account",
    shouldCallAutomatically: false,
  });

  return (
    <AccountsContext.Provider
      value={{
        selectedCampaignIds,
        setSelectedCampaignIds,
        isLoadingAccounts,
        isUpdatingAccounts,
        openAccountsData,
        setModalAccount,
        setModalContact,
        loadAccounts,
        isAccountUpdating: isAccountWithCampaignUpdating,
        updateAccountWithCampaign,
        addLeadToAccount,
        modalAccount,
        clearModalAccount,
        modalContact,
        clearModalContact,
        toggleDoNotCall,
        accountCalendlyUri: null, //accountCalendlyUriData?.uri || null,
        onChangeStatusSuccessRef,
        onChangeStatusCancelRef,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};
