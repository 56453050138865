import clsx from "clsx";

export const Cell = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => (
  <div
    className={clsx(
      "flex items-center border-b border-black/10 p-4",
      className
    )}
  >
    {children}
  </div>
);
