export const PERFORMANCE_DASHBOARD_TIME_PERIODS = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  THIS_WEEK: "this_week",
  LAST_WEEK: "last_week",
  THIS_MONTH: "this_month",
  LAST_MONTH: "last_month",
  LAST_30_DAYS: "last_30_days",
  LAST_3_MONTHS: "last_3_months",
  YEAR_TO_DATE: "year_to_date",
  LIFETIME: "lifetime",
};

export const PERFORMANCE_DASHBOARD_TIME_PERIOD_OPTIONS = {
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.TODAY]: "Today",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.YESTERDAY]: "Yesterday",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.THIS_WEEK]: "This week",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_WEEK]: "Last week",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.THIS_MONTH]: "This month",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_MONTH]: "Last month",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_30_DAYS]: "Last 30 days",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_3_MONTHS]: "Last 3 months",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.YEAR_TO_DATE]: "Year to date",
  [PERFORMANCE_DASHBOARD_TIME_PERIODS.LIFETIME]: "Lifetime",
};
