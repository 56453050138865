export const UPDATE_PROFILE_MESSAGES = {
  SUCCESS: {
    BOTH: "Profile data has been updated",
    DATA: "Profile data has been updated",
    PICTURE: "Profile image has been updated",
  },
  ERROR: {
    DATA: "Failed to save profile data",
    PICTURE: "Failed to save profile image",
  },
};
