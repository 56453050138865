import { FC } from "react";
import Link from "next/link";
import { CloudArrowDownIcon } from "@heroicons/react/24/solid";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

const SAMPLE_LEADS_CSV_FILE_URL =
  "https://glencoco-assets.s3.amazonaws.com/platform/upload-leads/upload_your_own_leads_sample.csv";

const FAQ_URL_BULK_LEADS_UPLOAD =
  "https://goglencoco.notion.site/Bulk-Leads-Upload-d0bc703a6e3b44a3880bf9407c5dcdb3";

export const UploadLeadsSidebarDownloadSample: FC<PropsWithClassNameI> = ({
  className,
}) => {
  return (
    <div
      className={clsxMerge(
        "rounded-xl border border-[#ccc] bg-[#FAFAFA]",
        "flex flex-col items-center justify-center",
        "px-10 py-7",
        "text-center",
        className
      )}
    >
      <CloudArrowDownIcon className="w-24 text-[#ccc]" />

      <p className="b-typography-h5 mb-4">Download a sample spreadsheet</p>

      <p className="b-typography-body3 mb-6">
        Need to see this in action first? Download this small sample file and
        test the import process so there are no surprises. <br />
        <Link
          href={FAQ_URL_BULK_LEADS_UPLOAD}
          target="_blank"
          className="ae-link"
        >
          Click here to see FAQ
        </Link>
        .
      </p>

      <Link href={SAMPLE_LEADS_CSV_FILE_URL} className="btn-nofill mb-6">
        Download CSV Example
      </Link>

      <p className="ae-link b-typography-body3">
        <b>Note:</b> You can import 10000 leads max per CSV.
      </p>
    </div>
  );
};
