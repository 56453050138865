import { MeetingTakerI } from "@/interfaces/accounts";
import { clsxMerge } from "shared/lib/helpers";
import { Avatar } from "shared/ui";

export const MeetingTakerListItem = ({
  first_name,
  last_name,
  isSelected,
  onClick,
}: MeetingTakerI & {
  isSelected?: boolean;
  onClick: () => void;
}) => {
  return (
    <li
      className={clsxMerge(
        "flex cursor-pointer items-center gap-x-2",
        "px-3 py-[6px]",
        "hover:bg-black/5",
        { "bg-[#014DFE] text-white hover:bg-[#014DFE]": isSelected }
      )}
      onClick={onClick}
    >
      <Avatar
        placeholderText={`${first_name} ${last_name}`}
        className="h-4 w-4 typography-body-6"
      />
      <span>
        {first_name} {last_name}
      </span>
    </li>
  );
};

export const MeetingTakerList = ({
  data,
  selected,
  onSelect,
}: {
  data?: MeetingTakerI[];
  selected?: MeetingTakerI;
  onSelect: (meetingTaker: MeetingTakerI) => void;
}) => {
  const handleOnClick = (meetingTaker: MeetingTakerI) => () => {
    onSelect(meetingTaker);
  };

  return (
    <ul className={clsxMerge("max-h-[30vh]", "overflow-auto")}>
      {data?.map((mt) => (
        <MeetingTakerListItem
          key={mt.id}
          {...mt}
          onClick={handleOnClick(mt)}
          isSelected={selected?.id === mt.id}
        />
      ))}
    </ul>
  );
};
