import { FILE_MIME_TYPES, FILE_SIZE_BYTES } from "shared/lib/constants/files";
import { ImageMimeTypeI } from "shared/lib/interfaces/files";
import { MAX_AVATAR_SIZE_MB } from "./constants";

export const validateSelectedAvatar = (file: File) => {
  const conditions = {
    format: Object.values(FILE_MIME_TYPES.IMAGE).includes(
      file.type as ImageMimeTypeI
    ),
    size: file.size <= FILE_SIZE_BYTES.ONE_MEGABYTE * MAX_AVATAR_SIZE_MB,
  };

  return {
    isValid: conditions.format && conditions.size,
    conditions,
  };
};
