import { UserIcon } from "@heroicons/react/24/outline";

import { getS3AssetPath } from "shared/lib/helpers";
import { LISTS_ICON_MAP } from "@/components/modules/pipeline/lists/list/primary-header/selector/constants";
import { ListPillI } from "@/api/routes/list";
import { checkIfNurtureList } from "@/modules/pipeline/lists/list/utils";

export interface ListActivitiesItemI extends ListPillI {
  isActive?: boolean;
  isExclusive?: boolean;
  onClick?: () => void;
}

export const getListIcon = ({
  list_type: listType,
  isExclusive,
  isWhiteAwardIcon,
}: Pick<ListActivitiesItemI, "isExclusive" | "list_type"> & {
  isWhiteAwardIcon?: boolean;
}) => {
  if (isExclusive) {
    return (
      <img
        alt="Dedicated List"
        src={getS3AssetPath(
          isWhiteAwardIcon
            ? "platform/icons/award-icon-white.webp"
            : "platform/icons/award-icon.webp"
        )}
        className="w-[18px] min-w-[18px]"
      />
    );
  }

  if (listType in LISTS_ICON_MAP) {
    return LISTS_ICON_MAP[listType as keyof typeof LISTS_ICON_MAP];
  }

  return <UserIcon className="w-4" />;
};

export const getListContactCountString = (list: ListPillI | undefined) => {
  if (!list) {
    return "";
  }

  const isNurtureList = checkIfNurtureList(list);

  const nurtureCount = list.nurture_count || 0;
  const coldCount = list.cold_count || 0;

  if (isNurtureList && !list.is_default_list) {
    return `Nurture ${nurtureCount} / Cold ${coldCount}`;
  }

  const total = nurtureCount + coldCount;

  return `${total} total leads`;
};
