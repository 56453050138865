import { create } from "zustand/react";

interface ProfileSettingsStoreI {
  isProfileSettingsOpen: boolean;
  actions: {
    openProfileSettings: () => void;
    closeProfileSettings: () => void;
  };
}

export const useProfileSettingsStore = create<ProfileSettingsStoreI>()(
  (set) => ({
    isProfileSettingsOpen: false,
    actions: {
      openProfileSettings: () => set({ isProfileSettingsOpen: true }),
      closeProfileSettings: () => set({ isProfileSettingsOpen: false }),
    },
  })
);

/**
 * As Zustand's core is not tied to React's rendering mechanism, it allows to
 * update the store by directly calling store state updates from anywhere.
 */
export const openProfileSettings = () =>
  useProfileSettingsStore.getState().actions.openProfileSettings();

export const closeProfileSettings = () =>
  useProfileSettingsStore.getState().actions.closeProfileSettings();
