import { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";

import { LoadingComponent } from "@/components/modules/loading";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

export interface UploadFileI {
  file?: File;
  data?: string | ArrayBuffer;
}

interface UploadLeadsSidebarUploadButtonPropsI extends PropsWithClassNameI {
  disabled?: boolean;
  handleCSVUpload?: (file: UploadFileI) => Promise<void>;
  isLoading?: boolean;
}

export const UploadLeadsSidebarUploadButton: FC<
  UploadLeadsSidebarUploadButtonPropsI
> = ({ className, disabled, handleCSVUpload, isLoading }) => {
  const [file, setFile] = useState<UploadFileI>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const file = ev.target.files ? ev.target.files[0] : undefined;

      if (!file) {
        console.log("no file.");
        return;
      }

      const reader = new FileReader();

      reader.onloadstart = () => {
        console.log("onloadstart..");
      };

      reader.onloadend = function () {
        const fileObject = { file: file, data: reader.result || undefined };

        setFile(fileObject);
        handleCSVUpload?.(fileObject);
      };

      reader.readAsDataURL(file as Blob);
    },
    [handleCSVUpload]
  );

  const triggerFileUpload = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <div
        className={clsxMerge(
          "grid grid-cols-[30px_1fr_40px] items-center gap-4",
          "rounded-lg border-2 p-4",
          "cursor-pointer",
          "hover:bg-black/5",
          disabled && "pointer-events-none opacity-40",
          className
        )}
        onClick={triggerFileUpload}
      >
        <CloudArrowDownIcon className="w-7" />

        <div>
          <p className="b-typography-h5">
            {file ? file.file?.name : "Upload a CSV file"}
          </p>
          <p className="b-typography-body3">Quickly upload a static CSV file</p>
        </div>

        <div>{isLoading && <LoadingComponent className="p-0" />}</div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        name="filename"
        accept=".csv"
        className="h-0 w-0 opacity-0"
        onChange={handleFileChange}
      />
    </>
  );
};
