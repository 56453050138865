import { FILE_MIME_TYPES, FILE_SIZE_BYTES } from "shared/lib/constants/files";

const PROFILE_PICTURE_ALLOWED_MIME_TYPES = Object.values(FILE_MIME_TYPES.IMAGE);

export const MAX_AVATAR_SIZE_MB = 10;

export const MAX_AVATAR_SIZE_BYTES =
  FILE_SIZE_BYTES.ONE_MEGABYTE * MAX_AVATAR_SIZE_MB;

export const DROPZONE_ACCEPT = {
  "image/*": PROFILE_PICTURE_ALLOWED_MIME_TYPES,
};

export const SUPPORTED_AVATAR_FORMATS_LIST =
  PROFILE_PICTURE_ALLOWED_MIME_TYPES.join(", ");

export const SUPPORTED_AVATAR_FORMATS_READABLE_LIST =
  PROFILE_PICTURE_ALLOWED_MIME_TYPES.map((mime) =>
    mime.replace("image/", "")
  ).join(", ");

export const AVATAR_VALIDATION_MESSAGES = {
  FORMAT: `Only ${SUPPORTED_AVATAR_FORMATS_LIST} formats are allowed`,
  SIZE: `Maximum picture size is ${MAX_AVATAR_SIZE_MB}MB`,
  BOTH: `File size is too large (${MAX_AVATAR_SIZE_MB}MB max) and format is invalid (${SUPPORTED_AVATAR_FORMATS_LIST} formats are allowed)`,
};
