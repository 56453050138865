import { clsxMerge } from "shared/lib/helpers";

export const DoNotCallNotification = () => {
  return (
    <div className={clsxMerge("text-white")}>
      <span className={clsxMerge("rounded-lg p-1 px-2")}>
        This lead has been marked Do Not Call.
      </span>
    </div>
  );
};
