import { FC, useMemo } from "react";

import { useGlobalNotificationsContext } from "@/context/notifications-context";
import { DefaultPill } from "shared/ui/pills/default-pill";
import { clsxMerge } from "shared/lib/helpers";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

interface NotificationLabelPropsI {
  id?: string;
  className?: string;
  isPulsating?: boolean;
}

export const NOTIFICATION_MAPPED_IDS = {
  NOTIFICATIONS: "notifications",
  MESSAGES: "messages",
  COACHING: "coaching",
  TOTAL_MESSAGES: "total_messages",
  EMAILS: "emails",
} as const;

export const NotificationLabel: FC<NotificationLabelPropsI> = ({
  id,
  className,
  isPulsating,
}) => {
  const typedId = id as ValueOfObjectFields<typeof NOTIFICATION_MAPPED_IDS>;

  const { notificationCounters } = useGlobalNotificationsContext();
  const { unread, message_count, coaching_count, unread_email } =
    notificationCounters || {};

  // remove ids not being used.
  // ex. "NOTIFICATIONS" does not display notifications count from here. It has its own badge component in the `notifications-button.tsx`
  const notificationsMap = useMemo(
    () => ({
      [NOTIFICATION_MAPPED_IDS.NOTIFICATIONS]:
        unread && unread > 0 ? unread : null,
      [NOTIFICATION_MAPPED_IDS.MESSAGES]:
        message_count && message_count > 0 ? message_count : null,
      [NOTIFICATION_MAPPED_IDS.COACHING]:
        coaching_count && coaching_count > 0 ? coaching_count : null,
      [NOTIFICATION_MAPPED_IDS.TOTAL_MESSAGES]:
        (coaching_count || 0) + (message_count || 0) + (unread_email || 0),
      [NOTIFICATION_MAPPED_IDS.EMAILS]:
        unread_email && unread_email > 0 ? unread_email : null,
    }),
    [notificationCounters]
  );

  return notificationsMap[typedId] ? (
    <DefaultPill
      className={clsxMerge(className, {
        "animate-pulse-alt": isPulsating,

        "px-[6px]": (notificationsMap[typedId] || 0) < 10,
        "px-1": (notificationsMap[typedId] || 0) >= 10,
      })}
    >
      {notificationsMap[typedId]}
    </DefaultPill>
  ) : null;
};
