/**
 * 3rd PARTY LIBRARIES
 **/
import axios, { AxiosInstance, AxiosResponse } from "axios";

/**
 * SHARED LIBRARIES
 **/
import Cookies from "shared/lib/helpers/cookies";
import { PAYMENT_TYPE } from "shared/lib/constants/payment";
import { STATUS_TYPES } from "shared/lib/constants/statuses";
import {
  ResumeI,
  SalesResumeExperienceItemI,
} from "shared/ui/sales-resume/interfaces/resume";
import { IndustriesI } from "shared/lib/interfaces/industry";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";
import {
  AccountI,
  AccountStatusI,
  AccountUserListI,
} from "shared/lib/interfaces/account";

/**
 * INTERFACES
 */
import { CampaignI } from "@/interfaces/campaign";
import { CallAccountI, CallContactI, LeadInfoI } from "@/interfaces/dialer";
import { ScheduleItemI } from "@/interfaces/dashboard/reminders-table";
import { ActivityEventI } from "@/interfaces/dialer/activity";
import { KanbanAccountI } from "@/interfaces/dashboard/accounts";
import {
  AccountExecutiveI,
  AccountHistoryI,
  AccountHistoryItemI,
  AccountUserDispositionI,
  CallRecordingI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { ReferralI } from "@/interfaces/referrals";

/**
 * CONSTANTS
 */
import { DISPOSITIONS } from "@/constants/dispositions";
import { COOKIES_GLCC_ACCESS_TOKEN } from "@/constants/cookies";

/**
 * HELPERS / UTILS
 */
import { interceptors } from "./interceptors";

/**
 * API ROUTES
 */
import {
  GetDashboardLeaderboardResponseI,
  UserDashboardAPIEndpoints,
} from "@/api/routes/dashboard";
import {
  UserOnboardingAPIEndpoints,
  UserOnboardingApiI,
} from "./routes/onboarding";
import { CoachingAPIEndpoints, CoachingApiI } from "@/api/routes/coaching";
import {
  ImpersonateAPIEndpoints,
  ImpersonateApiI,
} from "@/api/routes/impersonate";

import { AccountAPIEndpoints } from "@/api/routes/account";
import { ListAPIEndpoints, ListApiI } from "@/api/routes/list";
import { NotificationsAPIEndpoints } from "@/api/routes/notifications";
import { UserAPIEndpoints, UserApiI } from "./routes/user";
import { PerformanceAPIEndpoints } from "@/api/routes/performance";
import { DialerAPIEndpoints, DialerApiI } from "@/api/routes/dialer";
import { AttachmentI, EmailAPIEndpoints } from "./routes/email";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { ChatsAPIEndpoints } from "@/api/routes/chats";
import { IngestionsAPIEndpoints } from "@/api/routes/ingestions";
import { AccountExecutiveSurveyEndpoints } from "@/api/routes/public/ae-survey";
import { TrainingAPIEndpoints } from "@/api/routes/training";

export type { CallRecordingI };

export type {
  GetUserResponseI,
  UserReferralSourceTypeI,
  UserUpdateResponseI,
  UserPatchParamsI,
} from "./routes/user";

export type {
  GetDialerLeadResponseI,
  SaveContactCallbackRequestI,
  SaveContactCallbackResponseI,
  AddNextLeadRequestParamsI,
  AddNextLeadResponseI,
  AddAccountAndContactRequestParamsI,
  AddAccountAndContactResponseI,
  GetCallContextResponseI,
  BookMeetingResponseI,
} from "@/api/routes/dialer";

export interface DefaultResponseI {
  error_code?: number;
  status: 0;
}

const applyInterceptors = interceptors;

export interface ResendVerificationEmailRequestParamsI {
  user_id: string;
}

export interface GetAuth0UserInfoRequestParamsI {
  user_id: string;
}

export interface GetAuth0UserInfoResponseI extends DefaultResponseI {
  user_info: {
    email_verified: boolean;
  };
}

export interface GetCampaignResponseI extends DefaultResponseI {
  campaign: CampaignI;
}

export interface SignatureUploadURLResponseI extends DefaultResponseI {
  url: string;
}

export interface GetStripeURLResponse extends DefaultResponseI {
  link: string;
}

export interface GetStripeAccountCreationStatus extends DefaultResponseI {
  stripe_status: "not_connected" | "not_completed" | "completed";
}

export interface RawContractURLResponseI extends DefaultResponseI {
  url: string;
}

export interface GetLastSignatureDateResponseI extends DefaultResponseI {
  last_modified: string;
}

export interface ScheduleCertificationCallResponseI extends DefaultResponseI {
  meeting_url: string;
}

export interface getUnmatchedCallLeadsResponseI extends DefaultResponseI {
  contacts: Array<LeadInfoI>;
}

export interface GetDashboardRemindersResponseI {
  schedule_items: ScheduleItemI[];
}

export interface GetDashboardItemResponseI {
  contact: CallContactI;
  account: CallAccountI;
  event: ActivityEventI;
  campaign_name: string;
  campaign_id: string;

  calendly_uri?: string;
  call_id?: string;
}

export interface MeetingsItemI {
  id: string;
  ae_name: string;
  campaign_id: string;
  campaign_name: string;
  lead_company: string;
  lead_name: string;
  lead_title: string;
  payout: number;
  rating: number;
  status: (typeof STATUS_TYPES)[keyof typeof STATUS_TYPES];
  time: string;
}

export interface GetMeetingsResponseI {
  items: Array<MeetingsItemI[]>;
  next_token: string;
}

export interface GetCallHistoryStatsResponseI {
  current_call_streak: number;
  connected_calls_this_week: number;
  life_time_connected_calls: number;
  hours_calling_this_week: number;
}

export interface GetMeetingsStatsResponseI {
  life_time_money_earned: number;
  life_time_qualified_meetings: number;
  life_time_booked_meetings: number;
  no_show_rate: number;
  average_rating: number;
}

export interface GetHomepageStatsResponseI {
  connected_calls_today: number;
  life_time_booked_meetings: number;
  no_show_rate: number;
  average_rating: number;
}

export interface CallHistoryItemI {
  id: string;
  campaign_id: string;
  campaign_name: string;
  disposition: (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS];
  lead_company: string;
  lead_name: string;
  lead_title: string;
  time: string;
}
export interface GetCallHistoryResponseI {
  items: Array<CallHistoryItemI>;
  next_token: string;
}

export interface GetCallHistoryItemResponseI
  extends GetDashboardItemResponseI {}

export interface AnnouncementI {
  image_url: string;
  title: string;
  body: string;
  link: string;
  link_text: string;
}

export interface GetBannerAnnouncementResponseI {
  announcements: Array<AnnouncementI>;
}

export interface GetCampaignSampleRecordingsResponseI {
  recordings: CallRecordingI[];
}

export interface GetCampaignAttachmentsResponseI {
  attachments: AttachmentI[];
}

export interface GetResumeAssetUploadURLResponseI {
  upload_url: string;
}

export interface GetResumeResponseI {
  user_resume: ResumeI;
}

export type SalesResumeExperienceNewItemParamI = Pick<
  SalesResumeExperienceItemI,
  "campaign_id" | "description" | "show_campaign_stats"
>;

export type SalesResumeExperienceEditItemParamI = Pick<
  SalesResumeExperienceItemI,
  "id" | "description" | "show_campaign_stats"
>;

export interface ResumePatchParamsI {
  first_name?: string;
  last_name?: string;
  years_of_experience?: number;
  linkedin_url?: string;
  location?: string;
  phone?: string;
  email?: string;
  about?: string;
  experiences?: [];
  is_profile_public?: boolean;
  industries?: IndustriesI | null;
}

export interface GetAccountInfoResponseI {
  account: AccountI;
  account_executive: AccountExecutiveI;
  account_user_disposition: AccountUserDispositionI;
  user_lists: AccountUserListI[];
}

export interface GetReferralsDashboardI {
  referral_earnings: number;
  potential_earnings: number;
  referrals: Array<ReferralI>;
}

export interface GetAccountHistoryV2ResponseI {
  next_token?: string;
  activity_logs?: AccountHistoryItemI[];
}

export interface GetContactDetailsResponseI {
  next_token?: string;
  items?: ContactDetailI[];
}

interface GetKanbanAccountParamsI {
  campaigns: Array<string>;
}

export interface PartialGetKanbanAccountsEarningsResponseI {
  potential_earnings_usd: number;
  potential_earnings_backlog: number;
  potential_earnings_callbacks: number;
  potential_earnings_meetings: number;
  potential_earnings_feedback: number;
}

export interface GetKanbanAccountsResponseI
  extends PartialGetKanbanAccountsEarningsResponseI {
  items: Array<KanbanAccountI>;
}

export interface ModifyCallbackRequest {
  follow_up_time: string;
  notes: string;
}

export interface ModifyMeetingRequest {
  event_uri: string;
  qualification_criteria: SelectedQualificationCriteriaQuestionI[];
  notes: string;
}

export interface UpdateKanbanAccountParamsI {
  status: AccountStatusI;
  modify_callback_request?: ModifyCallbackRequest;
  modify_meeting_request?: ModifyMeetingRequest;
  cancel_meeting_request?: {
    note: string;
  };
}

export interface AddLeadToAccountParamsI {
  first_name?: string;
  last_name?: string;
  title?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  company_name?: string;
  linkedin_url?: string;
  list_id?: string;
}

export interface AddLeadAndAccountParamsI extends AddLeadToAccountParamsI {}
export interface AddLeadNewAccountResponseI {
  account: AccountI;
}

export interface GetAccountCalendlyUriResponseI {
  uri: string;
}

export interface GetAccountCalendlyStartTimeResponseI {
  start_time: string;
}

export interface HistoryItemI extends AccountHistoryI {
  campaign_name: string;
  campaign_id: string;
  account_name: string;
  account_id: string;
}

export interface GetHistoryResponseI {
  items: HistoryItemI[];
  next_token: string;
}

export interface QualifiedMeetingsI {
  account_id?: string;
  campaign_id?: string;
  qualified_time?: string;
  campaign_name?: string;
  account_name?: string;
  prospect_name?: string;
  prospect_title?: string;
}
export interface GetQualifiedMeetingsResponseI {
  qualified_meetings: Array<QualifiedMeetingsI>;
  next_token?: string;
}

export interface UpdateUserSettingsParamsI {
  call_forwarding_disabled?: boolean;
  phone_number?: string;
}

export interface GetMeetingChargeInformationResponseI extends DefaultResponseI {
  error_code?: number;

  campaign_id: string;
  customer_charge_amount: number;

  is_finished_verification: boolean;
  id: string;
  last4: string;
  bank_name: string;
  payment_type: ValueOfObjectFields<typeof PAYMENT_TYPE>;
}

export interface APII
  extends UserApiI,
    UserOnboardingApiI,
    CoachingApiI,
    DialerApiI,
    ListApiI,
    ImpersonateApiI,
    ReturnType<typeof UserDashboardAPIEndpoints>,
    ReturnType<typeof AccountAPIEndpoints>,
    ReturnType<typeof PerformanceAPIEndpoints>,
    ReturnType<typeof ChatsAPIEndpoints> {
  Instance: AxiosInstance;

  email: ReturnType<typeof EmailAPIEndpoints>;

  notifications: ReturnType<typeof NotificationsAPIEndpoints>;

  ingestions: ReturnType<typeof IngestionsAPIEndpoints>;

  training: ReturnType<typeof TrainingAPIEndpoints>;

  public: {
    aeSurvey: ReturnType<typeof AccountExecutiveSurveyEndpoints>;
  };

  getCampaign(campaignId: string): Promise<AxiosResponse<GetCampaignResponseI>>;

  getStripeURL(params: {
    return_url: string;
  }): Promise<AxiosResponse<GetStripeURLResponse>>;

  getStripeDashboardURL(params: {
    return_url: string;
  }): Promise<AxiosResponse<GetStripeURLResponse>>;

  getStripeAccountCreationStatus(): Promise<
    AxiosResponse<GetStripeAccountCreationStatus>
  >;

  getSignatureUploadURL(): Promise<AxiosResponse<SignatureUploadURLResponseI>>;
  getRawContractURL(): Promise<AxiosResponse<RawContractURLResponseI>>;
  getLastSignatureDate(): Promise<AxiosResponse<GetLastSignatureDateResponseI>>;

  resendVerificationEmail(
    params: ResendVerificationEmailRequestParamsI
  ): Promise<AxiosResponse<DefaultResponseI>>;
  getAuth0UserInfo(
    params: GetAuth0UserInfoRequestParamsI
  ): Promise<AxiosResponse<GetAuth0UserInfoResponseI>>;

  scheduleCertificationCall(
    campaignId: string
  ): Promise<AxiosResponse<ScheduleCertificationCallResponseI>>;

  getUnmatchedCallLeads(
    campaignId: string
  ): Promise<AxiosResponse<getUnmatchedCallLeadsResponseI>>;

  getDashboardReminders(params: {
    date: string;
  }): Promise<AxiosResponse<GetDashboardRemindersResponseI>>;

  getDashboardReminderItem(
    itemId: string
  ): Promise<AxiosResponse<GetDashboardItemResponseI>>;

  getCallHistoryStats(): Promise<AxiosResponse<GetCallHistoryStatsResponseI>>;

  getMeetingsStats(): Promise<AxiosResponse<GetMeetingsStatsResponseI>>;

  getHomepageStats(): Promise<AxiosResponse<GetHomepageStatsResponseI>>;

  getCallHistory(
    campaign_ids?: string[],
    dispositions?: Array<(typeof DISPOSITIONS)[keyof typeof DISPOSITIONS]>,
    next_token?: string
  ): Promise<AxiosResponse<GetCallHistoryResponseI>>;

  getCallHistoryItem(
    callId: string
  ): Promise<AxiosResponse<GetCallHistoryItemResponseI>>;

  getMeetingHistory(
    campaign_ids?: string[],
    status_filters?: Array<(typeof STATUS_TYPES)[keyof typeof STATUS_TYPES]>,
    next_token?: string
  ): Promise<AxiosResponse<GetMeetingsResponseI>>;

  getBannerAnnouncement(): Promise<
    AxiosResponse<GetBannerAnnouncementResponseI>
  >;

  getCampaignSampleRecordings(
    campaignId: string
  ): Promise<AxiosResponse<GetCampaignSampleRecordingsResponseI>>;

  getCampaignAttachments(
    campaignId: string
  ): Promise<AxiosResponse<GetCampaignAttachmentsResponseI>>;

  getResume(): Promise<AxiosResponse<GetResumeResponseI>>;

  updateResume(params: ResumePatchParamsI): Promise<AxiosResponse>;

  getResumeImageUploadURL(params: {
    content_type: string;
    content_size: number;
  }): Promise<AxiosResponse<GetResumeAssetUploadURLResponseI>>;

  addResumeSalesExperience(
    experienceItem: SalesResumeExperienceNewItemParamI
  ): Promise<AxiosResponse<DefaultResponseI>>;

  saveResumeSalesExperiences(
    experienceItems: Array<SalesResumeExperienceEditItemParamI>
  ): Promise<AxiosResponse<DefaultResponseI>>;

  getResumeGreetingUploadURL(params: {
    content_type: string;
    content_size: number;
  }): Promise<AxiosResponse<GetResumeAssetUploadURLResponseI>>;

  getLeaderboardData(): Promise<
    AxiosResponse<GetDashboardLeaderboardResponseI>
  >;

  getAccountInfo(
    campaignId: string,
    accountId: string
  ): Promise<AxiosResponse<GetAccountInfoResponseI>>;

  getReferralsDashboard(): Promise<AxiosResponse<GetReferralsDashboardI>>;

  createReferral(slug: string): Promise<AxiosResponse<DefaultResponseI>>;

  getContactDetails(
    campaignId: string,
    accountId: string,
    nextToken?: string
  ): Promise<AxiosResponse<GetContactDetailsResponseI>>;

  editAccountInfo(
    campaignId: string,
    accountId: string,
    data: {
      name?: string;
      industry?: string;
      website?: string;
      revenue_bucket?: string;
      state?: string;
      headcount?: string;
    }
  ): Promise<AxiosResponse>;

  editContactInfo(
    campaignId: string,
    contactId: string,
    params: {
      first_name?: string;
      last_name?: string;
      title?: string;
      phone?: string;
      mobile?: string;
      email?: string;
      linkedin_url?: string;
    }
  ): Promise<AxiosResponse>;

  addNoteToAccount(
    campaignId: string,
    accountId: string,
    params: { note: string }
  ): Promise<AxiosResponse>;

  addLeadToAccount(
    campaignId: string,
    accountId: string,
    data: AddLeadToAccountParamsI
  ): Promise<AxiosResponse>;

  addLeadNewAccount(
    campaignId: string,
    data: AddLeadAndAccountParamsI
  ): Promise<AxiosResponse<AddLeadNewAccountResponseI>>;

  deleteAccountLead(
    campaignId: string,
    contactId: string
  ): Promise<AxiosResponse>;

  getKanbanAccounts(
    params: GetKanbanAccountParamsI
  ): Promise<AxiosResponse<GetKanbanAccountsResponseI>>;

  updateKanbanAccount(
    accountId: string,
    params: UpdateKanbanAccountParamsI
  ): Promise<AxiosResponse<any>>;

  updateAccountWithCampaign(
    accountId: string,
    campaignId: string,
    params: UpdateKanbanAccountParamsI
  ): Promise<AxiosResponse<any>>;

  getAccountCalendlyUri(
    accountId: string,
    campaignId: string
  ): Promise<AxiosResponse<GetAccountCalendlyUriResponseI>>;

  getAccountCalendlyStartTime: (
    event_ur: string
  ) => Promise<AxiosResponse<GetAccountCalendlyStartTimeResponseI>>;

  toggleDoNotCall(
    accountId: string,
    campaignId: string,
    note: string
  ): Promise<AxiosResponse<any>>;

  messageAE(
    accountId: string,
    campaignId: string,
    params: { message: string }
  ): Promise<AxiosResponse>;

  getHistory(
    next_token?: string,
    call_disposition?: (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS][],
    account_name_search_term?: string,
    campaigns?: string[]
  ): Promise<AxiosResponse<GetHistoryResponseI>>;

  getQualifiedMeetings(params?: {
    next_token?: string;
  }): Promise<AxiosResponse<GetQualifiedMeetingsResponseI>>;

  updateUserSettings(params: UpdateUserSettingsParamsI): Promise<AxiosResponse>;

  getMeetingChargeInformation(
    survey_id: string,
    params: {
      rating: number;
    }
  ): Promise<AxiosResponse<GetMeetingChargeInformationResponseI>>;

  submitLeadQualityFeedback(
    campaignId: string,
    params: { feedback: string }
  ): Promise<AxiosResponse>;
}

const requests = (API: AxiosInstance): APII => ({
  Instance: API,

  ...UserAPIEndpoints(API),

  email: EmailAPIEndpoints(API),
  notifications: NotificationsAPIEndpoints(API),
  ingestions: IngestionsAPIEndpoints(API),
  training: TrainingAPIEndpoints(API),

  public: {
    aeSurvey: AccountExecutiveSurveyEndpoints(API),
  },

  getCampaign: (campaignId) => API.get(`/v1/campaign/${campaignId}`),
  getStripeURL: (params) =>
    // Tested that this request requires a minimum of 7 seconds
    API.get("/v1/stripe/link", { params, timeout: 30000 }),
  getStripeDashboardURL: (params) =>
    API.get("/v1/stripe/dashboard/link", { params, timeout: 30000 }),
  getStripeAccountCreationStatus: () => API.get(`/v1/stripe/status`),

  getSignatureUploadURL: () => API.get(`/v1/signature/upload`),
  getRawContractURL: () => API.get(`/v1/signature/raw_contract`),
  getLastSignatureDate: () => API.get(`/v1/signature/exists`),

  resendVerificationEmail: (params) =>
    API.post(`/v1/resend-verification-email`, { ...params }),
  getAuth0UserInfo: (params) =>
    API.get(`/v1/session-info`, { data: JSON.stringify(params) }),
  scheduleCertificationCall: (campaignId) =>
    API.post(`/v1/campaign/${campaignId}/meeting`),
  getUnmatchedCallLeads: (campaignId) =>
    API.get(`/v1/campaign/${campaignId}/contacts`),

  getDashboardReminders: (params) => API.get(`v1/dashboard`, { params }),
  getDashboardReminderItem: (itemId) => API.get(`v1/dashboard/item/${itemId}`),

  getCallHistory: (campaignIds, dispositions, next_token) =>
    API.post(`v1/call_history`, {
      campaign_ids: campaignIds,
      dispositions: dispositions,
      next_token: next_token,
    }),

  getCallHistoryItem: (callId) => API.post(`v1/call_history/${callId}`),

  getMeetingHistory: (campaignIds, statusFilters, nextToken) =>
    API.post(`v1/meeting_history`, {
      campaign_ids: campaignIds,
      status: statusFilters,
      next_token: nextToken,
    }),

  getCallHistoryStats: () => API.get(`v1/stats/call_history`),
  getMeetingsStats: () => API.get(`v1/stats/meetings`),
  getHomepageStats: () => API.get(`v1/stats/homepage`),

  getBannerAnnouncement: () => API.get(`v1/glcc-announcements`),

  getCampaignSampleRecordings: (campaignId) =>
    API.get(`v1/campaign/${campaignId}/sample_recordings`),

  getCampaignAttachments: (campaignId) =>
    API.get(`v1/campaign/${campaignId}/attachments`),

  getResume: () => API.get(`v1/resume`),
  updateResume: (params) => API.patch(`v1/resume`, { ...params }),

  getResumeImageUploadURL: (params) =>
    API.post(`v1/profile/upload`, { ...params }),

  addResumeSalesExperience: (experienceItem) =>
    API.post("v1/resume/experience", experienceItem),

  saveResumeSalesExperiences: (experiences) =>
    API.patch("v1/resume/experience", {
      experiences,
    }),

  getResumeGreetingUploadURL: (params) =>
    API.post(`v1/greetings/upload`, { ...params }),

  getLeaderboardData: () => API.get(`v2/leaderboard`),

  getAccountInfo: (campaignId, accountId) =>
    API.get(`v1/campaign/${campaignId}/account/${accountId}`),

  getReferralsDashboard: () => API.get(`v1/referral_dashboard`),
  createReferral: (slug) => API.post(`v1/referral`, { slug }),

  getContactDetails: (campaignId, accountId, nextToken) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/details`, {
      next_token: nextToken,
    }),

  editAccountInfo: (campaignId, accountId, data) =>
    API.patch(`v1/campaign/${campaignId}/account/${accountId}`, {
      patch_account: { ...data },
    }),

  addNoteToAccount: (campaignId, accountId, params) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/note`, {
      ...params,
    }),

  addLeadToAccount: (campaignId, accountId, data) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/new_contact`, {
      ...data,
    }),

  addLeadNewAccount: (campaignId, data) =>
    API.post(`v2/campaign/${campaignId}/account_and_contact`, {
      ...data,
    }),

  deleteAccountLead: (campaignId, contactId) =>
    API.delete(`v1/campaign/${campaignId}/contact/${contactId}`),

  getKanbanAccounts: (params) => API.post("v1/open_accounts", params),

  updateKanbanAccount: (accountId, params) =>
    API.post(`v1/account_user_dispo/${accountId}/update_status`, params),

  updateAccountWithCampaign: (accountId, campaignId, params) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/dispo_status`,
      params
    ),

  getAccountCalendlyUri: (accountId, campaignId) =>
    API.get(`v1/campaign/${campaignId}/account/${accountId}/calendly_uri`),
  getAccountCalendlyStartTime: (event_uri) =>
    API.get(`/v1/calendly/start_time?event_uri=${event_uri}`),

  toggleDoNotCall: (accountId, campaignId, note: string) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/toggle_do_not_call`,
      {
        note,
      }
    ),

  messageAE: (accountId, campaignId, params) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/message_ae`, {
      ...params,
    }),

  editContactInfo: (campaignId, contactId, params) =>
    API.patch(`v1/campaign/${campaignId}/contact/${contactId}`, {
      patch_contact: params,
    }),

  getHistory: (
    next_token,
    call_disposition,
    account_name_search_term,
    campaigns
  ) =>
    API.post(`v1/history`, {
      next_token: next_token,
      account_name_search_term: account_name_search_term,
      call_disposition: call_disposition,
      campaigns,
    }),

  getQualifiedMeetings: ({ next_token } = {}) =>
    API.post(`/v1/trophies/meetings`, {
      next_token,
    }),

  updateUserSettings: (params) =>
    API.patch(`v1/settings`, { settings: params }),

  getMeetingChargeInformation: (surveyId, params) =>
    API.get(`/public/customer/v1/ae_survey/${surveyId}/charge_info`, {
      params,
    }),

  submitLeadQualityFeedback: (campaignId: string, params) =>
    API.post(`v1/campaign/${campaignId}/lead_feedback`, { ...params }),

  ...UserDashboardAPIEndpoints(API),
  ...UserOnboardingAPIEndpoints(API),
  ...CoachingAPIEndpoints(API),
  ...DialerAPIEndpoints(API),
  ...AccountAPIEndpoints(API),
  ...ListAPIEndpoints(API),
  ...ImpersonateAPIEndpoints(API),
  ...PerformanceAPIEndpoints(API),
  ...EmailAPIEndpoints(API),
  ...ChatsAPIEndpoints(API),
});

export const glencocoServerAPI = ({
  token,
  req,
  res,
}: {
  token?: string;
  req?: any;
  res?: any;
}) => {
  let auth0Token;

  if (token) {
    auth0Token = token;
  } else if (req && res) {
    const cookies = Cookies({ req, res });

    auth0Token = cookies.getCookie(COOKIES_GLCC_ACCESS_TOKEN);
  }

  const API = axios.create({
    // TODO Before release change https://dev-glensocket.glencoco.com/user to https://dev-glensocket.glencoco.com
    baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 10000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "accept-encoding": "*",
      Authorization: `Bearer ${auth0Token}`,
    },
  });

  applyInterceptors(API);

  return requests(API);
};

export const glencocoClientAPI = () => {
  const API = axios.create({
    // TODO Before release change https://dev-glensocket.glencoco.com/user to https://dev-glensocket.glencoco.com
    baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 20000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  });

  applyInterceptors(API);

  return requests(API);
};

export const glencocoPublicAPI = () => {
  const API = axios.create({
    // TODO before release switch to default domain value
    // https://dev-glensocket.glencoco.com
    // on release don't forget to update env vars
    // For now is just a workaround
    baseURL: process.env.NEXT_PUBLIC_API_DOMAIN?.replace(`/user`, ""),
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 20000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  });

  applyInterceptors(API);

  return requests(API);
};
