import { glencocoClientAPI } from "@/api/glencoco";
import { useGlobalContext } from "@/hooks/use-global-context";
import { Form, Formik, Field } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { clsxMerge } from "shared/lib/helpers";
import { Modal, TextField } from "shared/ui";
import * as Yup from "yup";

const EnhancedTextField = TextField(Field);

export const ADD_MEETING_TAKER_MODAL_ID = "add_meeting_taker_modal";

/**
 * Backend Fields
 */
const MeetingTakerFormFields = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  EMAIL: "email",
  CALENDAR_LINK: "calendar_link",
} as const;

export interface MeetingTakerFormI {
  [MeetingTakerFormFields.FIRST_NAME]: string;
  [MeetingTakerFormFields.LAST_NAME]: string;
  [MeetingTakerFormFields.EMAIL]: string;
  [MeetingTakerFormFields.CALENDAR_LINK]: string;
}

const MeetingTakerFormFormSchema = Yup.object().shape({
  [MeetingTakerFormFields.FIRST_NAME]: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters")
    .required("Please provide the first name"),
  [MeetingTakerFormFields.LAST_NAME]: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters")
    .required("Please provide the last name"),
  [MeetingTakerFormFields.EMAIL]: Yup.string()
    .email("The email is invalid")
    .required("Please provide the email"),
  [MeetingTakerFormFields.CALENDAR_LINK]: Yup.string()
    .min(1, "Minimum 1 characters")
    .max(1000, "Maximum 2048 characters")
    .required("Please provide the calendar link"),
});

const ModalContent = ({
  onSuccess,
}: {
  onSuccess?: (data: MeetingTakerFormI) => void;
}) => {
  const { currentCampaign } = useGlobalContext();

  const [isLoading, setIsLoading] = useState(false);

  const form: MeetingTakerFormI = {
    [MeetingTakerFormFields.FIRST_NAME]: "",
    [MeetingTakerFormFields.LAST_NAME]: "",
    [MeetingTakerFormFields.EMAIL]: "",
    [MeetingTakerFormFields.CALENDAR_LINK]: "",
  };

  const handlerOnFormSubmit = async (data: MeetingTakerFormI) => {
    const API = glencocoClientAPI();

    setIsLoading(true);

    const Reponse = await API.addMeetingTaker(
      currentCampaign?.id as string,
      data
    ).catch((e) => e);

    if (Reponse?.status === 200) {
      onSuccess?.(data);
    } else {
      toast.error("Failed to create the new meeting taker");
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Formik
        initialValues={form}
        validationSchema={MeetingTakerFormFormSchema}
        onSubmit={handlerOnFormSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <EnhancedTextField
              name={MeetingTakerFormFields.FIRST_NAME}
              label="First name"
              placeholder="Barry"
              labelContentClassName="typography-body-4 !font-normal"
              inputClassName="typography-body-4"
              errors={errors[MeetingTakerFormFields.FIRST_NAME]}
              touched={touched[MeetingTakerFormFields.FIRST_NAME]}
            />

            <EnhancedTextField
              name={MeetingTakerFormFields.LAST_NAME}
              label="Last name"
              placeholder="Allen"
              labelContentClassName="typography-body-4 !font-normal"
              inputClassName="typography-body-4"
              errors={errors[MeetingTakerFormFields.LAST_NAME]}
              touched={touched[MeetingTakerFormFields.LAST_NAME]}
            />

            <EnhancedTextField
              name={MeetingTakerFormFields.EMAIL}
              label="Email address"
              placeholder="barry.allen@company.com"
              labelContentClassName="typography-body-4 !font-normal"
              inputClassName="typography-body-4"
              errors={errors[MeetingTakerFormFields.EMAIL]}
              touched={touched[MeetingTakerFormFields.EMAIL]}
            />

            <hr className="mb-4 mt-8 h-[1px] bg-[#E8E8E8]" />

            <EnhancedTextField
              name={MeetingTakerFormFields.CALENDAR_LINK}
              label="Booking Link (Calendly, chilipiper, cal.com etc.)"
              placeholder="Add link"
              labelContentClassName="typography-body-4 !font-normal"
              inputClassName="typography-body-4"
              errors={errors[MeetingTakerFormFields.CALENDAR_LINK]}
              touched={touched[MeetingTakerFormFields.CALENDAR_LINK]}
            />

            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                disabled={isLoading}
                className={clsxMerge(
                  "btn-ae-default w-[180px] typography-header-8-semibold",
                  {
                    loading: isLoading,
                  }
                )}
              >
                Add meeting taker
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const AddMeetingTakerModal = ({
  modalId = ADD_MEETING_TAKER_MODAL_ID,
  onSuccess,
}: {
  modalId?: string;
  className?: string;

  onSuccess?: (data: MeetingTakerFormI) => void;
}) => {
  const handleOnSuccess =
    (handleClose: () => void) => (data: MeetingTakerFormI) => {
      onSuccess?.(data);
      handleClose?.();
    };

  return (
    <Modal
      id={modalId}
      title="Add meeting taker"
      titleClassName="typography-header-6"
    >
      {({ handleClose }) => (
        <ModalContent onSuccess={handleOnSuccess(handleClose)} />
      )}
    </Modal>
  );
};
