import { ListTableSortingStateI } from "@/components/modules/pipeline/lists/list/workspace/table/interfaces";
import { CampaignV3I } from "@/interfaces/campaign";
import { UserI } from "@/interfaces/user";

const USER_KEY = "user";
const CAMPAIGNS_KEY = "campaigns";

const DIALER_GLOBAL_TWILIO_TOKEN_UPDATED_AT =
  "dialer_global_twilio_token_updated_at";

/**
 * Should followup by list id
 */
const LIST_SORTING = "list_sorting";

// Works ONLY in the browser
export class SessionStorage {
  /**
   * Cache Get User API response
   */
  get user(): UserI | undefined {
    const userAsString = window.sessionStorage.getItem(USER_KEY) as string;

    try {
      return JSON.parse(userAsString);
    } catch (e) {
      // TODO datadog
      console.log("Failed to parse user");
      console.log(userAsString);
      return undefined;
    }
  }
  set user(data: UserI) {
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(data));
  }

  /**
   * Cache Get Campaigns available campaigns
   */
  get campaigns(): Array<CampaignV3I> | undefined {
    const campaignsAsString = window.sessionStorage.getItem(
      CAMPAIGNS_KEY
    ) as string;

    try {
      return JSON.parse(campaignsAsString);
    } catch (e) {
      // TODO datadog
      console.log("Failed to parse user");
      console.log(campaignsAsString);
      return undefined;
    }
  }
  set campaigns(data: Array<CampaignV3I>) {
    window.sessionStorage.setItem(CAMPAIGNS_KEY, JSON.stringify(data));
  }

  get dialerGlobalTwilioTokenUpdatedAt(): string | undefined | null {
    return window.sessionStorage.getItem(DIALER_GLOBAL_TWILIO_TOKEN_UPDATED_AT);
  }
  set dialerGlobalTwilioTokenUpdatedAt(date: string) {
    window.sessionStorage.setItem(DIALER_GLOBAL_TWILIO_TOKEN_UPDATED_AT, date);
  }

  getListSorting(listId: string): ListTableSortingStateI {
    try {
      return JSON.parse(
        window.sessionStorage.getItem(`${LIST_SORTING}-${listId}`) as string
      );
    } catch {
      return {};
    }
  }

  setListSorting(listId: string, sortParams?: ListTableSortingStateI): void {
    window.sessionStorage.setItem(
      `${LIST_SORTING}-${listId}`,
      JSON.stringify(sortParams || {})
    );
  }

  clear() {
    window.sessionStorage.clear();
  }
}
