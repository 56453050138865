import { FC } from "react";

import { UploadLeadsSidebarContentChildI } from "@/modules/pipeline/shared/upload-leads-sidebar";
import FieldsMapForm from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/fields-form";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { useUploadLeadsSidebarContext } from "@/modules/pipeline/shared/upload-leads-sidebar/context";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { IngestionTypesI } from "@/modules/pipeline/shared/upload-leads-sidebar/components/fields-map/interfaces";

type UploadLeadsSidebarContentFieldsMapPropsI = {
  ingestionType?: IngestionTypesI;
} & UploadLeadsSidebarContentChildI;

export const UploadLeadsSidebarContentFieldsMap: FC<
  UploadLeadsSidebarContentFieldsMapPropsI
> = ({ handleNextStep, ingestionType, onClose = () => {} }) => {
  const {
    selectedCampaignId,
    ingestionId,
    ingestionFields,
    ingestionAIMappings,
  } = useUploadLeadsSidebarContext();

  return (
    <SidebarSlide
      title=" "
      headerClassName="pb-0"
      contentClassName="pt-0"
      size={SIDEBAR_SIZE.LG}
      onClose={onClose}
    >
      <section>
        <p className="b-typography-h2 mb-1">Map Leads</p>
        <p className="b-typography-body3">
          Map your CSV columns to match the required Glencoco fields
        </p>
      </section>

      {selectedCampaignId && ingestionId && (
        <FieldsMapForm
          campaignId={selectedCampaignId}
          ingestionId={ingestionId}
          ingestionType={ingestionType}
          ingestionFields={ingestionFields}
          ingestionAIMappings={ingestionAIMappings}
          onNextStep={handleNextStep}
          onCancel={onClose}
        />
      )}
    </SidebarSlide>
  );
};
