import { FC } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { ConnectedLabel } from "./connected";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { DialingLabel } from "./dialing";
import { EndedLabel } from "./ended";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { DialerCallStatusI } from "@/interfaces/dialer/call-status";

interface DialerConnectionLabelPropsI extends PropsWithClassNameI {
  callStatus?: DialerCallStatusI;
  startAt?: string;
}

dayjs.extend(duration);

export const DialerConnectionLabel: FC<DialerConnectionLabelPropsI> = ({
  callStatus: callStatusProp,
  className,
}) => {
  const callStatusRaw = useDialerCallStatus();
  const { startAt } = useInCallContext();

  const callStatus = callStatusProp || callStatusRaw;

  const isConnected = callStatus === DIALER_CALL_STATUS.DURING && !!startAt;
  const isDialing = callStatus === DIALER_CALL_STATUS.DURING && !startAt;
  const isEnded = callStatus === DIALER_CALL_STATUS.AFTER;

  return (
    <>
      {isConnected && <ConnectedLabel className={className} />}
      {isDialing && <DialingLabel className={className} />}
      {isEnded && <EndedLabel className={className} />}
    </>
  );
};
