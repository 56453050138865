import { FC, useMemo } from "react";
import { DevicePhoneMobileIcon, PhoneIcon } from "@heroicons/react/24/solid";

import {
  clsxMerge,
  fixUrl,
  getFullEntityName,
  getS3AssetPath,
} from "shared/lib/helpers";
import { ContactDetailI } from "shared/lib/interfaces/account";
import CopyToClipboard from "shared/ui/copy-to-clipboard";
import { AccountDetailsV2LeadItemEditLeadMenu } from "@/modules/pipeline/account-details/components/sidebar/sections/leads-section/edit-lead-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { PROSPECT_DISPOSITION_TYPES_MAP } from "shared/lib/constants/dispositions";
import { getLeadStateFlags } from "@/modules/pipeline/account-details/utils";
import { PhoneLink } from "@/modules/pipeline/account-details/sidebar/components/phone-link";

interface LeadItemPropsI {
  className?: string;
  contact: ContactDetailI;
  isDeleteContactAllowed?: boolean;
  isCurrentlyCalling?: boolean;
}

const COPY_VALUES_CLASSNAMES = clsxMerge(
  "max-w-[250px]",
  "brand-typography-body1 leading-5 font-medium",
  "break-words line-clamp-2 text-ellipsis"
);

interface LeadTagPropsI {
  tag: string;
}

const LeadTag: FC<LeadTagPropsI> = ({ tag }) =>
  tag && (
    <div
      className={clsxMerge(
        "bg-[#DFDFDF] px-2.5 py-1 text-[#666]",
        "rounded text-center typography-body-4-bold"
      )}
    >
      {tag}
    </div>
  );

export const AccountDetailsV2LeadItem: FC<LeadItemPropsI> = ({
  className,
  contact,
  isDeleteContactAllowed,
  isCurrentlyCalling,
}) => {
  const { isLeadDisqualified, isLeadMarkedDNC } = useMemo(
    () => getLeadStateFlags(contact),
    [contact?.contact_disposition_type]
  );

  const linkedin = useMemo(
    () => contact?.linkedin || contact?.linkedin_url,
    [contact]
  );

  return (
    <div className={clsxMerge("flex w-full", className)}>
      <div className="flex grow items-start gap-3">
        <img
          alt="Lead Avatar"
          src={getS3AssetPath(
            "platform/account-details/lead-avatar-placeholder.webp"
          )}
          className="h-8 w-8 -translate-y-1.5"
        />

        <div className="flex max-w-[320px] flex-col gap-2">
          <h5
            className={clsxMerge(
              "line-clamp-2 max-w-[240px] text-ellipsis typography-body-3-bold",
              {
                "line-through": isLeadDisqualified || isLeadMarkedDNC,
              }
            )}
          >
            {getFullEntityName(contact)}
          </h5>

          <span className={clsxMerge(COPY_VALUES_CLASSNAMES, "text-[#666]")}>
            {contact.title}
          </span>

          {contact?.email && (
            <CopyToClipboard showCopyIcon textToCopy={contact.email}>
              <span
                className={clsxMerge(COPY_VALUES_CLASSNAMES, "text-[#666]")}
              >
                {contact.email}
              </span>
            </CopyToClipboard>
          )}

          {contact?.phone && (
            <div className="flex items-center gap-x-1">
              <PhoneIcon className={clsxMerge("h-4 w-4")} />
              <PhoneLink
                contactId={contact?.id}
                phoneNumber={contact.phone}
                className={COPY_VALUES_CLASSNAMES}
                isDNC={contact?.phone_v2?.do_not_call}
                isLoadingComponent
              />
              <CopyToClipboard showCopyIcon textToCopy={contact.phone} />
            </div>
          )}

          {contact?.mobile && (
            <div className="flex items-center gap-x-1">
              <DevicePhoneMobileIcon className={clsxMerge("h-4 w-4")} />
              <PhoneLink
                contactId={contact?.id}
                phoneNumber={contact.mobile}
                className={COPY_VALUES_CLASSNAMES}
                isDNC={contact?.mobile_v2?.do_not_call}
                isLoadingComponent
              />
              <CopyToClipboard showCopyIcon textToCopy={contact.mobile} />
            </div>
          )}

          {linkedin && (
            <a
              target="_blank"
              rel="noreferrer"
              href={fixUrl(linkedin)}
              className={clsxMerge(COPY_VALUES_CLASSNAMES, "ae-link")}
            >
              {linkedin}
            </a>
          )}

          <div className="mt-2 flex flex-wrap gap-1.5">
            {contact.is_primary_lead && (
              <Tooltip showDelay={50}>
                <TooltipTrigger>
                  <LeadTag tag="Primary Lead" />
                </TooltipTrigger>
                <TooltipContent className="z-50 max-w-[250px] text-center">
                  A primary lead is the main contact for an account. They are
                  the lead that gets dialed first when calling.
                </TooltipContent>
              </Tooltip>
            )}

            {isLeadDisqualified ? (
              <LeadTag tag="Disqualified" />
            ) : (
              <>
                {contact?.contact_disposition_type && (
                  <LeadTag
                    tag={
                      PROSPECT_DISPOSITION_TYPES_MAP[
                        contact?.contact_disposition_type
                      ]
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-start">
        <AccountDetailsV2LeadItemEditLeadMenu
          contact={contact}
          isDeleteContactAllowed={isDeleteContactAllowed}
          isCurrentlyCalling={isCurrentlyCalling}
          isDisqualified={isLeadDisqualified}
          isMarkedDNC={isLeadMarkedDNC}
        />
      </div>
    </div>
  );
};
