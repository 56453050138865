/**
 * 3rd party
 */
import { useMemo } from "react";
import { Row } from "@tanstack/react-table";

/**
 * Utils
 */
import { DayJs } from "shared/lib/helpers/date";
import { isCooldown } from "@/modules/pipeline/lists/list/workspace/table/utils";

/**
 * Interfaces
 */
import { PipelineListContactI } from "@/interfaces/pipeline/list";

/**
 * Icons
 */
import { ClockIcon } from "shared/ui/icons";
import { NoSymbolIcon } from "@heroicons/react/24/solid";

/**
 * Components
 */
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import { LeadCooldownNotification } from "@/modules/pipeline/lists/list/workspace/table/notifications/lead-cooldown-notification";
import { DoNotCallNotification } from "@/modules/pipeline/lists/list/workspace/table/notifications/dnc-notification";
import { ListPillI } from "@/api/routes/list";

const CooldownIndicator = ({ date }: { date?: string }) => (
  <Tooltip placement="right" arrowFillColor="rgba(0,0,0,0.8)">
    <TooltipTrigger>
      <ClockIcon className="mr-1 w-4" />
    </TooltipTrigger>

    <TooltipContent className="-ml-2 animate-fadein rounded-xl bg-black/80">
      <LeadCooldownNotification date={date as string} />
    </TooltipContent>
  </Tooltip>
);

const DNCIndicator = () => (
  <Tooltip placement="right" arrowFillColor="rgba(0,0,0,0.8)">
    <TooltipTrigger>
      <NoSymbolIcon className="mr-1 w-4 text-[#9E0202]" />
    </TooltipTrigger>

    <TooltipContent className="-ml-2 animate-fadein rounded-xl bg-black/80">
      <DoNotCallNotification />
    </TooltipContent>
  </Tooltip>
);

export const prospectNameCell = (list?: ListPillI) =>
  function ProspecNameCell({ row }: { row: Row<PipelineListContactI> }) {
    const isCooldownMemo = useMemo(
      () => isCooldown(row.original?.cooldown_until),
      [row.original?.cooldown_until]
    );

    const isCallbackIndicator = useMemo(
      () =>
        !!row.original?.callback_time &&
        DayJs(row.original?.callback_time).isAfter(DayJs()),
      [row.original?.callback_time]
    );

    const isDNC = useMemo(
      () => !!row.original?.do_not_call,
      [row.original?.do_not_call]
    );

    /**
     * Array represents indicators to show icons
     *
     * [0] - DNCIndicator
     * [1] - CooldownIndicator
     * [2] - CallbackSetIndicator
     */
    const indicators = useMemo(
      () => [
        /**
         * DNCIndicator
         */
        isDNC,
        /**
         * CooldownIndicator
         */
        !isDNC && isCooldownMemo,
      ],
      [list?.is_default_list, isDNC, isCooldownMemo, isCallbackIndicator]
    );

    const prospectName = row.original.contact_name?.trim() || "N/A";

    return (
      <span className="flex items-center gap-x-2">
        {indicators.some(Boolean) && (
          <span className="flex items-center">
            {indicators[0] && <DNCIndicator />}

            {indicators[1] && (
              <CooldownIndicator date={row.original?.cooldown_until} />
            )}
          </span>
        )}

        <span title={prospectName}>{prospectName}</span>
      </span>
    );
  };
