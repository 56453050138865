import { LeadDescriptionContactItem } from "@/components/modules/calling/cards/lead-card/lead-description/content";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/solid";
import { clsxMerge, formatUSPhoneNumber } from "shared/lib/helpers";
import { CheckCircleIcon, PhoneIcon } from "shared/ui/icons";

export const PhoneNumbersMenu = ({
  children,
  isShowOptions,
  onMobileClick = () => {},
  onPhoneClick = () => {},
}: {
  isShowOptions?: boolean;
  onMobileClick?: () => void;
  onPhoneClick?: () => void;
  children?: any;
}) => {
  const { contact } = useInCallContext();
  const { mobile, phone } = contact || {};

  return (
    <div className="dropdown dropdown-top dropdown-hover">
      <label tabIndex={4}>{children}</label>

      {!!isShowOptions && (
        <ul
          tabIndex={4}
          className={clsxMerge(
            "dropdown-content menu w-52 rounded-lg bg-base-100 p-2 shadow",
            "brand-typography-detail2",
            "[&_a]:p-2",
            "[&_a:active]:bg-black [&_a:active]:[box-shadow:0_0_0_0px_#4474E3] [&_a:active_span]:text-white [&_a:active_svg]:text-white"
          )}
        >
          <li className="mb-2">
            <a
              className="relative bg-[#E8F3FF] [box-shadow:0_0_0_2px_#4474E3]"
              onClick={onMobileClick}
            >
              <CheckCircleIcon className="absolute left-[26px] top-[5px] w-3 text-[#318C35]" />

              <LeadDescriptionContactItem
                icon={<DevicePhoneMobileIcon className="h-3 w-3" />}
                content={
                  mobile
                    ? (formatUSPhoneNumber(mobile) as string)
                    : "No mobile phone"
                }
              />
            </a>
          </li>
          <li>
            <a onClick={onPhoneClick}>
              <LeadDescriptionContactItem
                icon={<PhoneIcon className="h-3 w-3" />}
                content={
                  phone ? (formatUSPhoneNumber(phone) as string) : "No phone"
                }
              />
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};
