import { PersonasIcon } from "shared/ui/icons";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "shared/ui/icons";
import { MailIcon } from "shared/ui/icons";
import { DescList } from "shared/ui";
import { InfoRecordI } from "shared/ui/desc-list";
import { IngestionAccountFieldsI } from "@/interfaces/ingestion/account";
import { IngestionContactFieldsI } from "@/interfaces/ingestion/contact";
import { formatNumbers } from "shared/lib/helpers";

const createInfoRecord = (
  icon: JSX.Element,
  data?: string,
  config?: any
): InfoRecordI => ({
  icon,
  data,
  config,
});

export interface LeadDesciprionI
  extends IngestionAccountFieldsI,
    IngestionContactFieldsI {
  miscs?: Array<string>;
}

const LeadDescription = ({
  name = "-",
  headcount = "-",
  industry = "-",
  revenue = "-",
  state = "-",
  phone = "-",
  email = "-",
  miscs = [],

  hideAccount,
}: LeadDesciprionI & {
  hideAccount?: boolean;
}) => {
  const accountInfo = [
    createInfoRecord(
      <PersonasIcon className="mr-1 h-2 min-w-[8px]" />,
      formatNumbers(headcount) as string
    ),
    createInfoRecord(
      <BuildingOfficeIcon className="mr-1 h-3 min-w-[12px]" />,
      industry
    ),
    createInfoRecord(
      <BanknotesIcon className="mr-1 h-3 min-w-[12px]" />,
      formatNumbers(revenue) as string
    ),
    createInfoRecord(<MapPinIcon className="mr-1 h-3 min-w-[12px]" />, state),
    ...miscs?.map((misc: string) =>
      createInfoRecord(
        <InformationCircleIcon className="mr-1 h-3 min-w-[12px]" />,
        misc
      )
    ),
  ];

  const contactInfo = [
    createInfoRecord(<PhoneIcon className="mr-1 h-3 min-w-[12px]" />, phone),
    createInfoRecord(<MailIcon className="mr-1 h-3 min-w-[12px]" />, email, {
      isAccent: true,
      href: `mailto:${email}`,
      target: "_blank",
      rel: "noreferrer",
    }),
  ];

  return (
    <div className="grid grid-cols-[minmax(45%,55%)_1fr]">
      {!hideAccount && (
        <div className="pr-10">
          <h6 className="ae-typography-detail1 mb-3 text-base-content/40">
            About {name}
          </h6>

          <DescList infoList={accountInfo} />
        </div>
      )}
      <div>
        <h6 className="ae-typography-detail1  mb-3 text-base-content/40">
          Contact Info
        </h6>

        <DescList infoList={contactInfo} />
      </div>
    </div>
  );
};

export default LeadDescription;
