import { FC } from "react";
import { DayJs } from "shared/lib/helpers/date";
import { useCountdownTimer } from "shared/lib/hooks/use-countdown-timer";

import { clsxMerge } from "shared/lib/helpers";

export const LeadCooldownNotification: FC<{ date: string }> = ({ date }) => {
  const countdown = useCountdownTimer({
    targetDate: DayJs(date).toDate(),
    updateInterval: 1000,
  });

  return (
    <div className={clsxMerge("text-white")}>
      <span className={clsxMerge("rounded-lg p-1 px-2")}>
        This lead has been marked for cool down because they’ve been called in
        the past 24 hrs. Available to call in {countdown}
      </span>
    </div>
  );
};
