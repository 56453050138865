import {
  AcademicCapIcon,
  ArrowLeftStartOnRectangleIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  PhoneArrowUpRightIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { CoachIcon, CogsIcon, LeaderboardIcon } from "shared/ui/icons";

import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/primary-navigation/v3/interfaces";
import Router from "@/helpers/router";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { NOTIFICATION_MAPPED_IDS } from "@/components/primary-navigation/shared/notification-label";
import { openProfileSettings } from "../../../lib/store/profile-settings";

export const SETTINGS_ITEM_ID = "settings";
export const CALL_FORWARDING_ITEM_ID = "call_forwarding";

export const NAV_GROUP_NAMES = {
  LEADS: "leads",
  EMAILS: "emails",
  PERFORMANCE: "performance",
  COACHING: "coaching",
  SETTINGS: "settings",
} as const;

type NavGroupNamesI = ValueOfObjectFields<typeof NAV_GROUP_NAMES>;

// All nav items organized by their group structures.
// Remember to reference the item you want from here into the appropriate menu array (found further below).
export const NAV_V3_ITEMS: Record<
  NavGroupNamesI,
  PrimaryNavigationItemCollapsableWithIconI
> = {
  [NAV_GROUP_NAMES.LEADS]: {
    text: "Leads",
    icon: <CurrencyDollarIcon />,
    href: Router.lists(),
    disabled: false,
  },
  [NAV_GROUP_NAMES.EMAILS]: {
    text: "Emails",
    id: NOTIFICATION_MAPPED_IDS.EMAILS,
    icon: <EnvelopeIcon />,
    href: Router.emailInbox(),
    disabled: false,
  },
  [NAV_GROUP_NAMES.PERFORMANCE]: {
    text: "Performance",
    icon: <LeaderboardIcon />,
    href: Router.performanceDashboard(),
    disabled: false,
  },
  [NAV_GROUP_NAMES.COACHING]: {
    text: "Coaching",
    icon: <CoachIcon />,
    isCollapsed: true,
    children: [
      {
        text: "Coaching",
        href: Router.coaching(),
        icon: <CoachIcon />,
        disabled: false,
      },
      {
        text: "Training",
        href: Router.training(),
        icon: <AcademicCapIcon />,
        disabled: false,
      },
    ],
  },
  [NAV_GROUP_NAMES.SETTINGS]: {
    text: "Settings",
    id: SETTINGS_ITEM_ID,
    icon: <CogsIcon className="w-5" />,
    isCollapsed: true,
    children: [
      {
        text: "My Profile",
        handler: openProfileSettings,
        icon: <UserIcon />,
      },
      {
        text: "Call Forwarding",
        id: CALL_FORWARDING_ITEM_ID,
        href: Router.settingsCallForwarding(),
        icon: <PhoneArrowUpRightIcon />,
      },
      {
        text: "Sign out",
        href: Router.logout(),
        icon: <ArrowLeftStartOnRectangleIcon />,
      },
    ],
  },
};

// These nav items persist across all screen sizes

// These nav items hide on collapsed screen sizes
export const NAV_V3_ITEMS_PRIMARY: Array<PrimaryNavigationItemCollapsableWithIconI> =
  [
    NAV_V3_ITEMS[NAV_GROUP_NAMES.PERFORMANCE],
    NAV_V3_ITEMS[NAV_GROUP_NAMES.COACHING],
  ];
