export interface UploadFileI {
  file?: File;
  data?: string | ArrayBuffer;
}

const KILOBYTE_IN_BYTES = 1024;

export const FILE_SIZE_BYTES = {
  ONE_KILOBYTE: KILOBYTE_IN_BYTES,
  ONE_MEGABYTE: KILOBYTE_IN_BYTES * 1024,
  ONE_GIGABYTE: KILOBYTE_IN_BYTES * 1024 * 1024,
} as const;

export const FILE_MIME_TYPES = {
  IMAGE: {
    JPEG: "image/jpeg",
    PNG: "image/png",
    GIF: "image/gif",
  },
} as const;
