import { InfoModal } from "@/components/modals/info-modal";
import { INFO_MODAL_DIALING_LIST_COMPLETE_ID } from "./constants";
import { ListTourModal } from "@/modals/lists/list-tour-modal";
import { ListTour } from "@/modules/tours/list";
import { useListTourContext } from "@/modules/tours/list/context";

export const ListsModalsAndTours = () => {
  const { openTour } = useListTourContext();

  return (
    <>
      <ListTour />

      <ListTourModal onEnterTour={openTour} />

      <InfoModal
        id={INFO_MODAL_DIALING_LIST_COMPLETE_ID}
        title="List dialing complete"
        description="All contacts have been called through today"
        confirmButtonText="Got it"
        showCloseButton={false}
      />
    </>
  );
};
