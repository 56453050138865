import { FC } from "react";

import { AccountDetailsLayout as Layout } from "./components/layout";

import { SidebarExtensionResources as Resources } from "@/modules/pipeline/account-details/components/sidebar/components/resources";

import { ConnectedLeadSection } from "@/modules/pipeline/account-details/components/primary-content/sections/connected-lead-section";
import { AccountSection } from "@/modules/pipeline/account-details/components/sidebar/sections/account-section";
import { StatusSection } from "@/modules/pipeline/account-details/components/sidebar/sections/status-section";
import { ListsSection } from "@/modules/pipeline/account-details/components/sidebar/sections/lists-section";
import { LeadsSection } from "@/modules/pipeline/account-details/components/sidebar/sections/leads-section";

import { PrimaryContent } from "./components/primary-content";

import { AccountDetailsControlMenu as ControlMenu } from "./components/control-menu";
import { AccountDetailsProvider, AccountDetailsProviderI } from "./context";

import { AccountDetailsModals as ModalsAndSidebars } from "components/modules/pipeline/account-details/components/modals";
import { AccountsContextProvider } from "@/context/accounts";
import { AddUserToListSidebarProvider } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { EmailTemplatesSidebarProvider } from "@/modules/email-templates-sidebar/context";

export const AccountDetailsModule: FC<AccountDetailsProviderI> = (props) => (
  <AccountsContextProvider>
    <AccountDetailsProvider {...props}>
      <AddUserToListSidebarProvider>
        <EmailTemplatesSidebarProvider>
          <Layout>
            <Layout.SidebarExtension>
              <Resources />
            </Layout.SidebarExtension>

            <Layout.GridRows>
              <Layout.Top>
                <ConnectedLeadSection />
              </Layout.Top>

              <Layout.GridColumns>
                <Layout.Sidebar>
                  <div className="flex flex-col divide-y pb-36">
                    <AccountSection />
                    <StatusSection />
                    <ListsSection />
                    <LeadsSection />
                  </div>
                </Layout.Sidebar>

                <Layout.Content>
                  <PrimaryContent />
                </Layout.Content>
              </Layout.GridColumns>
            </Layout.GridRows>

            <ControlMenu />

            <ModalsAndSidebars />
          </Layout>
        </EmailTemplatesSidebarProvider>
      </AddUserToListSidebarProvider>
    </AccountDetailsProvider>
  </AccountsContextProvider>
);

export { DISQUALIFY_DISPOSITION_LABELS } from "shared/lib/constants/dispositions";
