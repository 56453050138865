import { FC, useCallback, useEffect, useState } from "react";

import { clsxMerge } from "shared/lib/helpers";
import {
  ControllableComponentPropsI,
  PropsWithClassNameI,
} from "shared/lib/interfaces/ui";
import { LIST_SUBSECTIONS_MAP } from "@/modules/pipeline/lists/list/constants";
import { ListSubsectionI } from "@/modules/pipeline/lists/list/interfaces";
import { ListSubsectionButton } from "@/components/modules/pipeline/lists/list/primary-header/list-subsection-switch/subsection-button";

import {
  COLD_LEADS_BUTTON_ID,
  NURTURE_LEADS_BUTTON_ID,
  NURTURE_LIST_TOOLTIP,
} from "./constants";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { useListTourContext } from "@/modules/tours/list/context";

interface ListTypeSwitchPropsI
  extends ControllableComponentPropsI<ListSubsectionI>,
    PropsWithClassNameI {
  listId?: string;
  nurtureLeadsTotal?: number;
  nurtureLeadsAvailable?: number;
  coldLeadsTotal?: number;
  coldLeadsAvailable?: number;
}

// Switcher between list types (nurture or cold call)
export const ListSubsectionSwitch: FC<ListTypeSwitchPropsI> = ({
  className,
  nurtureLeadsTotal,
  nurtureLeadsAvailable,
  coldLeadsTotal,
  coldLeadsAvailable,
  value,
  onChange,
}) => {
  const { activeList } = useListsContext();
  const { isTourOpen } = useListTourContext();

  const [selected, setSelected] = useState<ListSubsectionI>(
    value || LIST_SUBSECTIONS_MAP.NURTURE
  );

  const handleChangeListSubsection = useCallback(
    (listSubsection: ListSubsectionI) => {
      if (listSubsection === selected || isTourOpen) {
        return;
      }

      setSelected(listSubsection);
      onChange(listSubsection);
    },
    [selected, isTourOpen, onChange]
  );

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  return !activeList?.is_default_list || isTourOpen ? (
    <div className={clsxMerge("relative flex items-center gap-3", className)}>
      <ListSubsectionButton
        id={NURTURE_LEADS_BUTTON_ID}
        tooltip={NURTURE_LIST_TOOLTIP}
        subsection={LIST_SUBSECTIONS_MAP.NURTURE}
        numerator={nurtureLeadsAvailable}
        denominator={nurtureLeadsTotal}
        value={selected as ListSubsectionI}
        onChange={handleChangeListSubsection}
      />

      <ListSubsectionButton
        id={COLD_LEADS_BUTTON_ID}
        subsection={LIST_SUBSECTIONS_MAP.COLD_CALL}
        numerator={coldLeadsAvailable}
        denominator={coldLeadsTotal}
        value={selected}
        onChange={handleChangeListSubsection}
      />
    </div>
  ) : null;
};
