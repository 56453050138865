import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ViewColumnsIcon } from "@heroicons/react/24/outline";

import { BasicMultiselect } from "shared/ui/user-input/multiselect";
import { clsxMerge } from "shared/lib/helpers";
import { useListsContext } from "@/modules/pipeline/lists/context";

interface DataTableColumnFilterPropsI {
  className?: string;
}

export const DataTableColumnVisibilityFilter = ({
  className,
}: DataTableColumnFilterPropsI) => {
  const { columnVisibility, setColumnVisibility, columns } = useListsContext();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const alwaysVisibleColumns = useMemo(
    () =>
      columns.filter(({ enableHiding }) => !enableHiding).map(({ id }) => id),
    [columns]
  );

  const options = useMemo(
    () =>
      columns.map(({ header, id }) => ({
        label: header as string,
        value: id as string,
      })),
    [columns]
  );

  const handleChange = useCallback(
    (newValues: string[]) => {
      // update UI state
      setSelectedValues(newValues);

      // update object of visibility state for all columns in a structure needed for the table
      const newVisibility = columns.reduce(
        (visibleColumnsObject, currentColumn) => {
          if (currentColumn.id) {
            !newValues.includes(currentColumn.id)
              ? (visibleColumnsObject[currentColumn.id] = false)
              : (visibleColumnsObject[currentColumn.id] = true);
          }

          return visibleColumnsObject;
        },
        {} as Record<string, boolean>
      );

      setColumnVisibility(newVisibility);
    },
    [columns]
  );

  // initially sync selectedValues with initial columnVisibility values
  useEffect(() => {
    setSelectedValues(() => {
      const initiallyVisibleColumns = columns.map(({ id }) => id as string);

      return columnVisibility
        ? columns
            .map(({ id }) => id as string)
            .filter((id) => columnVisibility[id])
        : initiallyVisibleColumns;
    });
  }, [columns]);

  return (
    <BasicMultiselect
      canSelectAll
      value={selectedValues}
      options={options}
      onChange={handleChange}
      isOptionDisabled={(_, option) =>
        alwaysVisibleColumns.includes(option as string)
      }
      getLabel={() => <ViewColumnsIcon className="h-6 w-6 text-[#666666]" />}
      className={clsxMerge("w-max", className)}
      selectWrapperClassName="border-none"
      menuClassName="right-0 max-h-[360px] bg-white"
      itemClassName="py-1.5"
    />
  );
};
