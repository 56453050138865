/**
 * 3rd PARTY LIBRARIES
 **/
import { AxiosInstance } from "axios";

/**
 * CONSTANTS
 */

/**
 * HELPERS / UTILS
 */
import { StreamAPIConfig } from "@/api/stream";

/**
 * API ROUTES
 */
import {
  EmailWebAIAPIEndpoints,
  EmailWebAIAPII,
} from "@/api/routes/email-webai";
import { getAuthToken } from "lib/auth/token";

export interface DefaultResponseI {
  error_code?: number;
  status: 0;
}

export interface APII {
  Instance: AxiosInstance;
}

export interface StreamAPII extends EmailWebAIAPII {}

const streamRequests = (API: StreamAPIConfig): StreamAPII =>
  EmailWebAIAPIEndpoints(API);

export const webAIClientStreamAPI = () => {
  const authToken = getAuthToken();

  const API: StreamAPIConfig = {
    baseURL: process.env.NEXT_PUBLIC_AI_API_DOMAIN ?? "",
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 20000 : 60000,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  // Since Fetch doesn't have interceptors, we can create a wrapper function
  // to apply any request/response transformations or error handling
  // For example, you can create a custom fetch function

  return streamRequests(API);
};
