import { FC, useRef } from "react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

import { useOnClickOutside } from "shared/lib/hooks/use-on-click-outside";
import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { clsxMerge } from "shared/lib/helpers";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { LeadListsTableFiltersMenu } from "./menu";

interface LeadListsTableFiltersPropsI {
  className?: string;
  menuClassName?: string;
}

export const LeadListsTableFilters: FC<LeadListsTableFiltersPropsI> = ({
  className,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(filterContainerRef, onClose);

  const { activeList, filters } = useListsContext();

  const appliedFilterCount = [
    filters?.filter_available_to_call,
    !!filters?.timezone_filters?.length,
  ].filter((val) => val === true).length;

  if (!activeList) {
    return null;
  }

  return (
    <div
      ref={filterContainerRef}
      className={clsxMerge("relative w-max", className)}
    >
      <div
        role="button"
        onClick={onOpen}
        className={clsxMerge("flex w-max cursor-pointer items-center gap-1.5")}
      >
        <div className="flex w-fit items-center">
          <AdjustmentsHorizontalIcon className="mr-1.5 h-6 w-6 text-[#666666]" />

          <span
            className={clsxMerge(
              "mr-1 text-black/40 typography-body-4-semibold"
            )}
          >
            {appliedFilterCount ? "Filters" : "Filter"}
          </span>

          <span className="typography-body-4-semibold">
            {appliedFilterCount || null}
          </span>
        </div>
      </div>

      {isOpen && <LeadListsTableFiltersMenu />}
    </div>
  );
};
