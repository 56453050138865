import { FC, useEffect, useLayoutEffect } from "react";
import _throttle from "lodash/throttle";
import { useRouter } from "next/router";

import { useEffectOnce } from "shared/lib/hooks";
import { LeadListsPrimaryHeader } from "@/components/modules/pipeline/lists/list/primary-header";
import { LeadListsWorkspace } from "@/modules/pipeline/lists/list/workspace";

import Router from "@/helpers/router";
import { ListsModalsAndTours } from "@/modules/pipeline/lists/list/modals-and-tours";
import { setListTableHeaderOffsetTop } from "@/modules/pipeline/lists/list/workspace/table/utils";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { PipelineListFooter } from "@/modules/pipeline/lists/list/footer";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

import {
  PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID,
  PRIMARY_NAVIGATION_V3_CONTAINER_ID,
} from "@/constants/element-ids";

type LeadListsPropsI = PropsWithClassNameI;

const CONTENT_CONTAINER_RESIZE_INTERVAL_MS = TIME_INTERVALS_MS.ONE_SECOND / 2;

export const LeadLists: FC<LeadListsPropsI> = () => {
  const router = useRouter();

  const { listsAPI, activeListId, searchTerm, isSearchMode, listDetails } =
    useListsContext();

  useEffect(() => {
    const headerHeight =
      document.getElementById(PRIMARY_NAVIGATION_V3_CONTAINER_ID)
        ?.offsetHeight || 0;

    const contentContainer = document.getElementById(
      PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID
    );

    const windowResieHandler = _throttle(() => {
      if (contentContainer) {
        // Set the height of the content container to fill the remaining height
        // of the viewport, giving us a "page-wide" horizontal scrollbar at the
        // bottom, which is always visible.
        contentContainer.style.height = `calc(100vh - ${headerHeight}px)`;
      }
    }, CONTENT_CONTAINER_RESIZE_INTERVAL_MS);

    windowResieHandler();

    window.addEventListener("resize", windowResieHandler);

    return () => {
      window.removeEventListener("resize", windowResieHandler);
    };
  }, [listsAPI.isSuccess, searchTerm]);

  useEffectOnce(() => {
    const routeChangeHandler = (url: string) => {
      // When changing the current list query param, "routeChangeStart" event also
      // occurs. We don't want to reset the content container height in that case.
      if (url && url.includes(Router.lists())) {
        return;
      }

      const contentContainer = document.getElementById(
        PRIMARY_NAVIGATION_CONTENT_CONTAINER_ID
      );

      if (contentContainer) {
        // Reset the height of the content container after moving away from the
        // lists page
        contentContainer.style.height = "auto";
      }
    };

    router.events.on("routeChangeStart", routeChangeHandler);

    return () => {
      router.events.off("routeChangeStart", routeChangeHandler);
    };
  });

  // When search mode is toggled, we need to reposition the list table header
  useLayoutEffect(() => {
    setListTableHeaderOffsetTop();
  }, [isSearchMode]);

  useLayoutEffect(() => {
    if (listDetails) {
      setListTableHeaderOffsetTop();
    }
  }, [listDetails]);

  return (
    <>
      {!isSearchMode && (
        <LeadListsPrimaryHeader className="sticky left-0 z-[14] mb-6" />
      )}

      {activeListId && <LeadListsWorkspace />}

      <PipelineListFooter />

      <ListsModalsAndTours />
    </>
  );
};
