import { FC, useMemo, useRef, useState } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { TextArea, TextField } from "shared/ui/ae-user-input";
import { Dropdown } from "shared/ui";
import { yupPhone } from "shared/lib/helpers/yup";
import { useFetchLists } from "@/modules/pipeline/lists/list/queries/queries";
import { checkIfCanAddLeadsToList } from "@/modules/pipeline/utils";
import { AccountI } from "shared/lib/interfaces/account";
import toast from "react-hot-toast";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { useCreateList } from "@/modules/pipeline/lists/list/queries/mutations";

const EnhancedTextField = TextField(Field);
const EnhancedDropdown = Dropdown(Field);
const EnhancedTextArea = TextArea(Field);

export interface AddNewLeadToListFormI {
  account_name?: string;
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  mobile: string;
  email: string;
  linkedin_url: string;
  list_id?: string;
  new_list_name?: string;
  new_list_description?: string;
}

interface AddNewLeadToListFormPropsI {
  campaignId: string | undefined;
  account?: AccountI;
  loading?: boolean;
  onSubmit?: (data: AddNewLeadToListFormI) => void;
  onCancel?: () => void;
}

const CreateNewLeadFormValidation = Yup.object().shape(
  {
    account_name: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(1000, "Maximum 1000 characters")
      .required("Required field"),
    first_name: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Required field"),
    last_name: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Required field"),
    title: Yup.string()
      .max(1000, "Maximum 1000 characters")
      .required("Required field"),
    phone: Yup.string().when("mobile", {
      is: (mobile: string) => !mobile,
      then: yupPhone("phone"),
      otherwise: Yup.string(),
    }),
    mobile: Yup.string().when("phone", {
      is: (phone: string) => !phone,
      then: yupPhone("mobile"),
      otherwise: Yup.string(),
    }),
    email: Yup.string().email("Enter a valid email").required("Required field"),
    linkedin_url: Yup.string(),
    list_id: Yup.string().when(["new_list_name"], {
      is: (newListName: string) => newListName === undefined,
      then: Yup.string().required("Select a list or create one below"),
    }),
  },
  // @ts-ignore
  ["mobile", "phone"]
);

export const AddNewLeadToListForm: FC<AddNewLeadToListFormPropsI> = ({
  campaignId,
  account,
  loading,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const formikRef = useRef<FormikProps<AddNewLeadToListFormI>>(null);
  const { data: listsData } = useFetchLists();
  const { mutateAsync: createList } = useCreateList();
  const [isCreateListModeVisible, setIsCreateListModeVisible] = useState(false);

  const listOptions = useMemo(() => {
    const options =
      listsData?.data?.user_lists
        ?.filter(checkIfCanAddLeadsToList)
        .map((list) => ({ value: list.id, label: list.name })) || [];

    options.push({ label: "-None-", value: "" });

    return options;
  }, [listsData]);

  const form: AddNewLeadToListFormI = {
    account_name: account?.name || "",
    first_name: "",
    last_name: "",
    title: "",
    phone: "",
    mobile: "",
    email: "",
    linkedin_url: "",
    list_id: "",
    new_list_name: "",
    new_list_description: "",
  };

  const getListId = async (data: AddNewLeadToListFormI) => {
    // Use an existing list
    if (data.list_id) {
      return data.list_id;
    }

    // Create a new list
    if (data.new_list_name) {
      const createListResp = await createList({
        name: data.new_list_name,
        description: data.new_list_description,
      });

      return createListResp.data.list_id;
    }
  };

  const submitHandler = async (data: AddNewLeadToListFormI) => {
    const listId = await getListId(data);

    if (!campaignId) {
      toast.error(
        "Campaign ID is missing, please try reloading the page and submitting the form again."
      );

      dd.error(
        `${ERROR_CATEGORIES.LEAD} - Failed to create new lead through a form. Campaign ID is missing.`
      );
    }

    if (listId && campaignId) {
      onSubmit({ ...data, list_id: listId });
    }
  };

  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <h3 className="ae-typography-h3">Create New Lead</h3>
      </div>

      <p className="ae-typography-body2 mb-6 text-base-content/60">
        Add a new lead to this account
      </p>

      <Formik
        initialValues={form}
        validationSchema={CreateNewLeadFormValidation}
        onSubmit={submitHandler}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
          <Form>
            <EnhancedTextField
              name="account_name"
              label="Account Name"
              placeholder="Add account name"
              errors={errors.account_name}
              touched={touched.account_name}
              disabled={!!account}
            />

            <EnhancedTextField
              name="first_name"
              label="First Name"
              placeholder="Add first name"
              errors={errors.first_name}
              touched={touched.first_name}
            />

            <EnhancedTextField
              name="last_name"
              label="Last Name"
              placeholder="Add last name"
              errors={errors.last_name}
              touched={touched.last_name}
            />

            <EnhancedTextField
              name="title"
              label="Title"
              placeholder="Add title"
              errors={errors.title}
              touched={touched.title}
            />

            <EnhancedTextField
              name="phone"
              label="Phone Number"
              placeholder="Add phone number"
              errors={errors.phone}
              touched={touched.phone}
            />

            <EnhancedTextField
              name="mobile"
              label="Mobile Number"
              placeholder="Add mobile number"
              errors={errors.mobile}
              touched={touched.mobile}
            />

            <EnhancedTextField
              name="email"
              label="Work Email"
              placeholder="Add email"
              errors={errors.email}
              touched={touched.email}
            />

            <EnhancedTextField
              name="linkedin_url"
              label="Linkedin URL"
              placeholder="Add Linkedin URL"
              errors={errors.linkedin_url}
              touched={touched.linkedin_url}
            />

            <EnhancedDropdown
              name="list_id"
              label="Select list to add lead to"
              placeholder="Select list"
              options={listOptions}
              errors={errors.list_id}
              touched={touched.list_id}
              labelContentClassName="ae-typography-detail1 text-base-content/60 font-[600]"
            />

            <div className="b-typography-h6 divider" />

            <p className="mb-6 typography-body-4-bold">
              Don’t see the list you need? Create a new one
            </p>

            {isCreateListModeVisible ? (
              <>
                <EnhancedTextField
                  name="new_list_name"
                  label="List name"
                  placeholder="Add a list name"
                  disabled={!!values.list_id}
                  errors={errors.new_list_name}
                  touched={touched.new_list_name}
                  inputProps={{
                    autoFocus: true,
                  }}
                />

                <EnhancedTextArea
                  name="new_list_description"
                  label="Description"
                  placeholder="Add a list name - Eg. 'East coast leads' or 'Good decision maker titles'"
                  inputClassName="min-h-[100px] max-h-[150px]"
                  errors={errors.new_list_description}
                  touched={touched.new_list_description}
                  disabled={!!values.list_id}
                />
              </>
            ) : (
              <div
                role="button"
                className="btn-nofill h-12 w-[366px]"
                onClick={(e) => {
                  e.preventDefault();
                  setFieldValue("list_id", "");
                  setFieldTouched("list_id", false);
                  setIsCreateListModeVisible(true);
                }}
              >
                Create new list
              </div>
            )}

            <div className="mt-8 flex justify-end">
              <button
                type="button"
                className="btn-ae-text mr-2 cursor-pointer items-center px-4 text-black/40"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                disabled={loading}
                className={clsx("btn-ae-default w-[140px]", {
                  loading,
                })}
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
