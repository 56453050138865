import { FC, useEffect, useMemo, useRef } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { TextField } from "shared/ui/ae-user-input";
import clsx from "clsx";
import _debounce from "lodash/debounce";

import { CampaignI } from "@/interfaces/campaign";
import { yupPhone } from "shared/lib/helpers/yup";
import { useLocalStorage } from "@/context/local-storage";
import { AccountI } from "shared/lib/interfaces/account";

const EnhancedTextField = TextField(Field);

export interface CreateNewLeadFormI {
  campaign_name: string;
  account_name?: string;
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  mobile: string;
  email: string;
  linkedin_url: string;
}

interface NewLeadFormContainerPropsI {
  campaign?: CampaignI;
  account?: AccountI;
  loading?: boolean;
  onSubmit?: (data: CreateNewLeadFormI) => void;
  onCancel?: () => void;
}

const CreateNewLeadFormValidation = Yup.object().shape(
  {
    account_name: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(1000, "Maximum 1000 characters")
      .required("Required field"),
    first_name: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Required field"),
    last_name: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Required field"),
    title: Yup.string()
      .max(1000, "Maximum 1000 characters")
      .required("Required field"),
    phone: Yup.string().when("mobile", {
      is: (mobile: string) => !mobile,
      then: yupPhone("phone"),
      otherwise: Yup.string(),
    }),
    mobile: Yup.string().when("phone", {
      is: (phone: string) => !phone,
      then: yupPhone("mobile"),
      otherwise: Yup.string(),
    }),
    email: Yup.string().email("Enter a valid email").required("Required field"),
    linkedin_url: Yup.string(),
  },
  // @ts-ignore
  ["mobile", "phone"]
);

// TODO - Replace that with a more intuitive way to handle form changes. That
// should be considered as an anti-pattern.
const OnFormChange: FC<{ values: CreateNewLeadFormI }> = ({ values }) => {
  const storage = useLocalStorage();

  const debouncedPersistNewLeadForm = useMemo(
    () =>
      _debounce((value: CreateNewLeadFormI) => {
        storage.newLeadModalPersistedValues = value;
      }, 500),
    []
  );

  useEffect(() => {
    debouncedPersistNewLeadForm(values);
  }, [values]);

  return null;
};

export const NewLeadForm: FC<NewLeadFormContainerPropsI> = ({
  campaign,
  account,
  loading,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const formikRef = useRef<FormikProps<CreateNewLeadFormI>>(null);
  const storage = useLocalStorage();

  const form: CreateNewLeadFormI = useMemo(
    () => ({
      campaign_name: campaign?.name || "",
      account_name: account?.name || "",
      first_name: "",
      last_name: "",
      title: "",
      phone: "",
      mobile: "",
      email: "",
      linkedin_url: "",
      ...storage.newLeadModalPersistedValues,
    }),
    [campaign, account]
  );

  return (
    <div>
      <Formik
        initialValues={form}
        validationSchema={CreateNewLeadFormValidation}
        onSubmit={onSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ errors, touched, values }) => (
          <Form>
            <OnFormChange values={values} />

            <EnhancedTextField
              name="account_name"
              label="Account Name"
              placeholder="Add account name"
              errors={errors.account_name}
              touched={touched.account_name}
              disabled={!!account}
            />

            <EnhancedTextField
              name="first_name"
              label="First Name"
              placeholder="Add first name"
              errors={errors.first_name}
              touched={touched.first_name}
            />

            <EnhancedTextField
              name="last_name"
              label="Last Name"
              placeholder="Add last name"
              errors={errors.last_name}
              touched={touched.last_name}
            />

            <EnhancedTextField
              name="title"
              label="Title"
              placeholder="Add title"
              errors={errors.title}
              touched={touched.title}
            />

            <EnhancedTextField
              name="phone"
              label="Phone Number"
              placeholder="Add phone number"
              errors={errors.phone}
              touched={touched.phone}
            />

            <EnhancedTextField
              name="mobile"
              label="Mobile Number"
              placeholder="Add mobile number"
              errors={errors.mobile}
              touched={touched.mobile}
            />

            <EnhancedTextField
              name="email"
              label="Work Email"
              placeholder="Add email"
              errors={errors.email}
              touched={touched.email}
            />

            <EnhancedTextField
              name="linkedin_url"
              label="Linkedin URL"
              placeholder="Add Linkedin URL"
              errors={errors.linkedin_url}
              touched={touched.linkedin_url}
            />

            <div className="mt-8 flex justify-end">
              <button
                type="button"
                className="btn-ae-text mr-2 cursor-pointer items-center px-4 text-black/40"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                disabled={loading}
                className={clsx("btn-ae-default w-[140px]", {
                  loading,
                })}
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewLeadForm;
