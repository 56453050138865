import { ChangeEvent, FC, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { UploadLeadsSidebarContentChildI } from "@/modules/pipeline/shared/upload-leads-sidebar";
import { useFetchLists } from "@/modules/pipeline/shared/upload-leads-sidebar/queries";
import { TextArea, TextField } from "shared/ui/user-input";
import { useUploadLeadsSidebarContext } from "@/modules/pipeline/shared/upload-leads-sidebar/context";
import { clsxMerge } from "shared/lib/helpers";
import { checkIfCanAddLeadsToList } from "@/modules/pipeline/utils";
import { useTimeout } from "shared/lib/hooks/use-timeout";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import { useCreateList } from "@/modules/pipeline/lists/list/queries/mutations";
import {
  AddUserToListSidebarListsDropdown,
  CREATE_NEW_LIST_OPTION,
  CREATE_NEW_LIST_OPTION_ID,
} from "@/modules/pipeline/shared/add-user-to-list-sidebar/add-user-to-list-slide/lists-dropdown";
import _find from "lodash/find";

const EnhancedTextField = TextField();
const EnhancedTextArea = TextArea((props) => <textarea {...props} />);

type UploadLeadsSidebarContentAddToListI = UploadLeadsSidebarContentChildI;

export const UploadLeadsSidebarContentAddToList: FC<
  UploadLeadsSidebarContentAddToListI
> = ({
  onClose = () => {},
  handlePrevStep = () => {},
  handleNextStep = () => {},
}) => {
  const listsApi = useFetchLists();
  const { data: listsData } = listsApi;

  const { mutateAsync, isPending: isCreatingNewList } = useCreateList();
  const [isVisible, setIsVisible] = useState(false);

  const { selectedListId, setSelectedListId } = useUploadLeadsSidebarContext();
  const [newListName, setNewListName] = useState<string>();
  const [newListDescription, setNewListDescription] = useState<string>();

  const userEnabledLists = useMemo(
    () => listsData?.data.user_lists.filter(checkIfCanAddLeadsToList),
    [listsData]
  );

  const selectedList = useMemo(() => {
    if (selectedListId === CREATE_NEW_LIST_OPTION_ID) {
      return CREATE_NEW_LIST_OPTION;
    }

    return _find(userEnabledLists, ["id", selectedListId]);
  }, [userEnabledLists, selectedListId]);

  const areListsAvailable = Number(userEnabledLists?.length) > 0;

  const isListCreationMode = selectedListId === CREATE_NEW_LIST_OPTION_ID;

  const onClickNext = async () => {
    // using an existing list
    if (selectedListId && !isListCreationMode) {
      handleNextStep();
      return;
    }

    // Create a new list first and then go to the next step
    if (
      (isListCreationMode || !areListsAvailable) &&
      newListName &&
      newListDescription
    ) {
      const createListResponse = await mutateAsync({
        name: newListName,
        description: newListDescription,
      });

      if (createListResponse.status === 200) {
        setSelectedListId(createListResponse?.data?.list_id);
        handleNextStep();
      } else {
        toast.error("Failed to create list, please try again.");
      }
    }
  };

  const slideLabel = useMemo(() => {
    if (listsApi.isFetching) {
      return "Loading lists data...";
    }

    return areListsAvailable ? "Add to list or Create list" : "Create list";
  }, [listsApi, areListsAvailable]);

  useTimeout(() => setIsVisible(true), 100);

  return (
    <SidebarSlide size={SIDEBAR_SIZE.MD} title={slideLabel} onClose={onClose}>
      <article
        className={clsxMerge(
          "relative transition-opacity duration-300",
          isVisible ? "opacity-100" : "opacity-0"
        )}
      >
        <DataStatesWrapper viewName="lists" api={listsApi}>
          {Number(userEnabledLists?.length) > 0 && (
            <section className="mb-2">
              <label className="b-typography-h6 mb-1.5 block">
                Select list to add leads to
              </label>

              <AddUserToListSidebarListsDropdown
                lists={userEnabledLists}
                value={selectedList}
                onChange={(list) => setSelectedListId(list?.id || null)}
              />

              {(!userEnabledLists || !userEnabledLists.length) && (
                <p className="b-typography-detail2 p-2 opacity-40">
                  You have not yet created any custom lists to import leads to.
                  Create one by selecting "Create new list" dropdown option
                  above.
                </p>
              )}
            </section>
          )}

          {isListCreationMode && areListsAvailable && (
            <div className="divider my-6" />
          )}

          {(isListCreationMode || !areListsAvailable) && (
            <section>
              <EnhancedTextField
                name="name"
                placeholder="Add list name"
                label="List name"
                labelClassName="text-black typography-body-4-bold"
                inputClassName="bg-white typography-body-4"
                inputProps={{
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    setNewListName(e.target.value),
                }}
              />

              <EnhancedTextArea
                name="description"
                placeholder="Describe the purpose of this custom list. For example - This is list for leads with verified titles."
                label="Description"
                labelClassName="text-black typography-body-4-bold"
                textareaClassName="max-h-[50vh] min-h-[220px] bg-white !leading-[18px] typography-body-4 py-4"
                inputProps={{
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    setNewListDescription(e.target.value),
                }}
              />
            </section>
          )}
        </DataStatesWrapper>

        <section className="z-100 fixed bottom-0 left-0 flex w-full justify-between px-6 py-4">
          <button className="btn-transparent" onClick={onClose}>
            Cancel
          </button>

          <span>
            <button
              className="btn-nofill mr-2 w-[124px]"
              onClick={handlePrevStep}
            >
              Back
            </button>

            <button
              className="btn-ae-default w-[152px]"
              onClick={onClickNext}
              disabled={
                isCreatingNewList ||
                (!selectedListId && !(newListName && newListDescription))
              }
            >
              Next
            </button>
          </span>
        </section>
      </article>
    </SidebarSlide>
  );
};
