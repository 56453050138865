import { FC } from "react";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { LabelValue } from "@/modules/pipeline/account-details/components/sidebar/components/label-value";
import { AccountDetailsV2SidebarSection } from "@/modules/pipeline/account-details/components/sidebar/sections/section";
import { AccountStatusDetails } from "@/modules/pipeline/account-details/components/sidebar/sections/status-section/status-details";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { AccountDispositionStatusPill } from "@/modules/pipeline/account-details/components/sidebar/components/status-pill";
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import { getAccountDispositionStatusParams } from "shared/lib/helpers/disposition";
import { StatusSectionActions } from "@/modules/pipeline/account-details/components/sidebar/sections/status-section/actions";

interface StatusSectionPropsI {
  className?: string;
}

export const StatusSection: FC<StatusSectionPropsI> = () => {
  const {
    accountDetailsApi,
    campaign,
    account,
    accountStatus,
    accountExecutiveData,
    onAccountUpdate,
  } = useAccountDetailsContext();

  if (ACCOUNT_DISPOSITION_STATUSES.DISQUALIFIED === accountStatus?.status) {
    return null;
  }

  return (
    <AccountDetailsV2SidebarSection
      title="Status"
      actionsSection={
        <StatusSectionActions
          campaign={campaign}
          account={account}
          accountStatus={accountStatus}
          accountExecutive={accountExecutiveData}
          onAccountUpdate={onAccountUpdate}
        />
      }
    >
      <DataStatesWrapper
        viewName="Account state"
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[120px]" />}
      >
        {accountStatus && (
          <div className="mt-5 flex animate-fadein flex-col gap-5">
            <LabelValue
              label="Status"
              value={
                <AccountDispositionStatusPill
                  {...getAccountDispositionStatusParams(accountStatus, true)}
                />
              }
            />

            <AccountStatusDetails
              accountStatus={accountStatus}
              campaign={campaign}
              account={account}
            />
          </div>
        )}
      </DataStatesWrapper>
    </AccountDetailsV2SidebarSection>
  );
};
