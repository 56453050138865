import { ColumnDef, Row, Table } from "@tanstack/react-table";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { DataTablePropsI } from ".";
import { useListsContext } from "@/modules/pipeline/lists/context";
import { PipelineListContactI } from "@/interfaces/pipeline/list";

/**
 * Custom hook to manage columns with row selection in a data table.
 * @param {DataTablePropsI} config
 * @returns object
 */
export const useColumnsWithRowSelection = <T,>({
  listId,
  data,
  isSelectable,
}: DataTablePropsI<T>) => {
  const [lastSelectedRowId, setLastSelectedRowId] = useState<string>();
  const { columns } = useListsContext();

  useEffect(() => {
    setLastSelectedRowId(undefined);
  }, [listId]);

  const columnsWithRowSelection = useMemo<
    ColumnDef<PipelineListContactI>[]
  >(() => {
    const stopPropagation = (e: MouseEvent) => {
      e.stopPropagation();
    };

    /**
     * Get the range of rows between the current and selected row.
     *
     * @param {Array<Row<T>>} rows - Array of rows.
     * @param {number} currentID - ID of the current row.
     * @param {number} selectedID - ID of the selected row.
     * @returns {Array<Row<T>>} - Array of rows in the range.
     */
    const getRowRange = (
      rows: Row<T>[],
      currentID: number,
      selectedID: number
    ): Row<T>[] => {
      const rangeStart = selectedID > currentID ? currentID : selectedID;
      const rangeEnd = rangeStart === currentID ? selectedID : currentID;
      return rows.slice(rangeStart, rangeEnd + 1);
    };

    /**
     * Handle multi-select on click event.
     *
     * @param {Table<T>} table - The table instance.
     * @param {Row<T>} row - The row instance.
     * @returns {Function} - Event handler function for the click event.
     */
    const handleMultiSelectOnClick =
      (table: Table<T>, row: Row<T>) =>
      (e: MouseEvent<HTMLInputElement>): void => {
        if (e.shiftKey) {
          const { rows, rowsById } = table.getRowModel();
          const rowsToToggle = getRowRange(
            rows,
            Number(row.id),
            Number(lastSelectedRowId)
          );
          const isCellSelected = rowsById[row.id].getIsSelected();
          rowsToToggle.forEach((_row) => _row.toggleSelected(!isCellSelected));
        } else {
          row.toggleSelected();
        }

        setLastSelectedRowId(row.id);
      };

    return isSelectable && data.length > 0
      ? [
          {
            id: "select-column",
            header: ({ table }) => (
              <label className="p-4">
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </label>
            ),
            cell: ({ row, table }) => (
              <label className="p-4" onMouseDown={stopPropagation}>
                <input
                  type="checkbox"
                  className={clsxMerge("transition-all duration-300", {
                    "scale-0 opacity-0": !row.getIsSelected(),
                    "group-hover:scale-100 group-hover:opacity-100":
                      !row.getIsSelected(),
                  })}
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onClick={handleMultiSelectOnClick(
                    table as unknown as Table<T>,
                    row as unknown as Row<T>
                  )}
                  onChange={row.getToggleSelectedHandler()}
                />
              </label>
            ),
          },
          ...columns,
        ]
      : columns;
  }, [columns, isSelectable, data, lastSelectedRowId]);

  return {
    columnsWithRowSelection,
  };
};
