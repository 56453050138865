import { FC, PropsWithChildren } from "react";

import { Container } from "@/components/base/container";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

export const LEADS_PAGE_HEADER_CONTAINER_ID = "leads-page-header-container";

export const LeadsPageHeader: FC<PropsWithClassNameI & PropsWithChildren> = ({
  className,
  children,
}) => (
  <Container
    id={LEADS_PAGE_HEADER_CONTAINER_ID}
    className={clsxMerge("min-h-[45px] justify-between px-0 py-5", className)}
  >
    <div className="flex items-center justify-between">
      <div className="ae-typography-h2">
        Leads{" "}
        <span className="ml-3 text-[#666] typography-body-5">
          Select the list you want to view
        </span>
      </div>

      {children}
    </div>

    {/* Underline */}
    <div className="mt-1 h-[1px] w-[60px] bg-[#666]" />
  </Container>
);
