import { ListTableSortingStateI } from "@/modules/pipeline/lists/list/workspace/table/interfaces";
import { GetListsRequestFilterParamsI } from "@/api/routes/list";

export const LISTS_QUERY_KEY = "lists";

export const SEARCH_CONTACTS_QUERY_KEY = "contacts";

export const getListDetailsQueryKey = (listId: string) => [
  LISTS_QUERY_KEY,
  listId,
];

export interface GetListContactsQueryKeyConfigI {
  sortParams?: ListTableSortingStateI;
  filters?: GetListsRequestFilterParamsI;
}

export const getListContactsQueryKey = (
  listId: string,
  config?: GetListContactsQueryKeyConfigI
) => {
  const { sortParams, filters } = config || {};

  const baseKey: any[] = [
    LISTS_QUERY_KEY,
    listId,
    "contacts",
    sortParams,
    filters,
  ];

  return baseKey;
};
