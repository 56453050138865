import { DialingContactStatusPill } from "@/components/base/pills/dialing-contact-status-pill";
import { PipelineListContactI } from "@/interfaces/pipeline/list";
import { Row } from "@tanstack/react-table";

import { clsxMerge } from "shared/lib/helpers";

export const DialerStatus = ({ row }: { row: Row<PipelineListContactI> }) => {
  return row.original.status ? (
    <DialingContactStatusPill
      status={row.original.status}
      className={clsxMerge("min-w-20 duration-1000 animate-in fade-in zoom-in")}
    />
  ) : null;
};
